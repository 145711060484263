import { KpiApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { Kpi } from '@/domain/kpi'
import { KpiMapper } from '@/domain/kpiMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new KpiApi(undefined, BASE_PATH, axios)

class KpiRepository {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 1000 }) {
    try {
      const response = await api.getKpiCollection(page, itemsPerPage)
      return <SearchResult<Kpi>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => KpiMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new KpiRepository()
