



































































































































import { defineComponent, computed, inject, onMounted, ref } from '@vue/composition-api'
import AppBar from '@/components/AppBar.vue'
import Addin from '@/components/Addin.vue'
import Notifications from '@/components/Notifications.vue'
import TrackingListsSidebar from '@/components/TrackingListsSidebar.vue'
import ButtonClose from '@/components/ButtonClose.vue'
import AgendaSidebar from '@/components/AgendaSidebar.vue'
import { useUser } from '@/composables/useUser'
import HelpFab from '@/components/HelpFab.vue'
import { sidebarMachineSendKey, sidebarMachineStateKey } from '@/router/views/AppOutlet.vue'

import { mdiAccountLock, mdiRefresh, mdiLockClock } from '@mdi/js'
import { authMachineSendKey } from '@/components/Auth.vue'

export default defineComponent({
  name: 'App',
  components: {
    AppBar,
    Addin,
    ButtonClose,
    Notifications,
    TrackingListsSidebar,
    AgendaSidebar,
    HelpFab
  },
  setup (props, { root }) {
    // MACHINES
    const send = inject(sidebarMachineSendKey)
    const state = inject(sidebarMachineStateKey)
    const authMachineSend = inject(authMachineSendKey)

    const isDebugAuth = process.env.VUE_APP_DEBUG_AUTH

    const widths = {
      xs: '100%',
      sm: '500px',
      md: 'calc((100% - 900px)/2 + 270px)',
      lg: 'calc((100% - 1185px)/2 + 350px)',
      xl: 'calc((100% - 1785px)/2 + 535px)'
    }
    // COMPUTED
    const width = computed(() => widths[root.$vuetify.breakpoint.name])

    // USER IMPERSONATION
    const { impersonated, me } = useUser()
    const showBookmarks = computed(() => me.value.isAdmin)
    const impersonationBar = ref<any>(null)

    onMounted(() => {
      // When using keyboard navigation, the following focuses on the impersonation banner to make it clear they are in impersonation mode
      if (impersonated) {
        setTimeout(() => impersonationBar.value.focus(), 100)
      }
    })

    return {
      authMachineSend,
      state,
      send,
      isDebugAuth,
      mdiAccountLock,
      mdiRefresh,
      mdiLockClock,
      width,
      impersonated,
      me,
      showBookmarks,
      impersonationBar
    }
  }
})
