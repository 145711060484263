






import { defineComponent } from '@vue/composition-api'
import FormTrackingListCreate from '@/components/forms/FormTrackingListCreate.vue'
import DetailPage from '@/components/DetailPage.vue'

export default defineComponent({
  components: {
    FormTrackingListCreate,
    DetailPage
  }
})
