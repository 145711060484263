import store from '@/store'
import { computed } from '@vue/composition-api'
import { UserSettings } from '@/domain/userSetting'
import repository from '@/domain/userSettingRepository'

export function useUserSettings () {
  const findForDetail = id => (
    repository.findById(id).then(x => {
      store.commit('userSettings/setForDetail', x)
    })
  )
  const detail = computed<UserSettings>(() => store.state.userSettings.detail)
  return {
    findForDetail,
    detail
  }
}
