<template>
  <div>
    <GroupHeading :title="`${title} Deadline`" />
    <div v-if="dueDate">
      <BaseIconText>
        <template v-slot:icon>
          <BaseIcon iconName="mdiClock" :color="statusColor(status)" />
        </template>
        <template v-slot:text>
          <span>{{ dueDate | formatDateTime }}</span>
        </template>
      </BaseIconText>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { BaseIcon, BaseIconText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import GroupHeading from '@/components/GroupHeading'
import statusColor from '@/utils/helpers/statusColor'

export default defineComponent({
  props: {
    title: String,
    dueDate: Date,
    status: String
  },
  components: {
    BaseIcon,
    BaseIconText,
    GroupHeading
  },
  setup () {
    return { statusColor }
  }
})
</script>

<style scoped>

</style>
