























import { computed, defineComponent, inject, watch } from '@vue/composition-api'
import AddinAgendaMinute from '@/components/AddinAgendaMinute.vue'
import AddinAgendaDecision from '@/components/AddinAgendaDecision.vue'
import AddinWorkflow from '@/components/AddinWorkflow.vue'
import repositoryDocument from '@/domain/documentRepository'
import { sidebarMachineSendKey, sidebarMachineStateKey } from '@/router/views/AppOutlet.vue'

export default defineComponent({
  components: {
    AddinWorkflow,
    AddinAgendaMinute,
    AddinAgendaDecision
  },
  setup (props, { root }) {
    const send = inject(sidebarMachineSendKey)
    const state = inject(sidebarMachineStateKey)
    // DOCUMENT FOR SIDEBAR
    const store = root.$store
    const document = computed(() => store.getters['documents/getDocumentForAddin'])

    watch(() => state?.value.context.documentId, () => {
      repositoryDocument.findById(state?.value.context.documentId).then(x => {
        store.commit('documents/addDocumentToCache', x)
        store.commit('documents/setDocumentIdForAddin', x)
      })
    }, { immediate: true })

    return {
      document,
      state,
      send
    }
  }
})
