import { AgendaType } from '@/domain/agendaType'

export interface ModuleState {
  detail?: AgendaType;
  select?: AgendaType[];
}

// state
const state = () => <ModuleState>({
  detail: undefined,
  select: undefined
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setForDetail (state, value) {
    state.detail = value
  },
  setForSelect (state, value) {
    state.select = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
