import { TrackingListApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { TrackingListMapper } from '@/domain/trackingListMapper'
import { TrackingList } from '@/domain/trackingList'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new TrackingListApi(undefined, BASE_PATH, axios)

class TrackingListRepository implements IFindData<TrackingList>, ICrudData<TrackingList>, ISearchData<TrackingList> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getTrackingListItem(id)
      return TrackingListMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: TrackingList) {
    try {
      const params = TrackingListMapper.serializePost(obj)
      const response = await api.postTrackingListCollection(params)
      return TrackingListMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: TrackingList) {
    try {
      const params = TrackingListMapper.serializePatch(obj)
      const response = await api.patchTrackingListItem(String(obj.id), params)
      return TrackingListMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 20, q }) {
    try {
      const response = await api.getTrackingListCollection(
        page,
        itemsPerPage,
        undefined,
        q
      )
      return <SearchResult<TrackingList>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => TrackingListMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteTrackingListItem(id)
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new TrackingListRepository()
