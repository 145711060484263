


















import { defineComponent } from '@vue/composition-api'
import SvgGuyCard from '@/components/SvgGuyCard.vue'

export default defineComponent({
  components: {
    SvgGuyCard
  }
})
