import { BookmarkApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { BookmarkMapper } from '@/domain/bookmarkMapper'
import { Bookmark } from '@/domain/bookmark'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new BookmarkApi(undefined, BASE_PATH, axios)

class BookmarkRepository implements IFindData<Bookmark>, ICrudData<Bookmark>, ISearchData<Bookmark> {
  async findById (id) {
    try {
      const response = await api.getBookmarkItem(String(id))
      return BookmarkMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 100 }) {
    try {
      const response = await api.getBookmarkCollection(
        page,
        itemsPerPage,
        undefined
      )
      return <SearchResult<Bookmark>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => BookmarkMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (entity: Bookmark) {
    try {
      const response = await api.postBookmarkCollection(BookmarkMapper.serializePost(entity))
      return BookmarkMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id: string | number) {
    try {
      await api.deleteBookmarkItem(String(id))
      return id
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new BookmarkRepository()
