const statusColor = (status, required = false) => {
  if (required) {
    switch (status) {
      case 'collaboration-in-progress':
        return 'secondaryBlueDark'
      case 'approval-in-progress':
        return 'secondaryRedDark'
      default:
        return 'secondaryGreyDark'
    }
  }
  switch (status) {
    case 'draft':
      return 'secondaryOrange'
    case 'collaboration-moving':
      return 'secondaryGrey'
    case 'collaboration-in-progress':
      return 'secondaryBlue'
    case 'approval-moving':
      return 'secondaryGrey'
    case 'approval-in-progress':
      return 'secondaryRed'
    case 'approved':
      return 'secondaryGreen'
    case 'archived':
      return 'secondaryGrey'
    case 'legacy-completed':
      return 'secondaryGrey'
    default:
      return 'secondaryGrey'
  }
}

export default statusColor
