import { UserSettingsJsonldUserSettingsRead, UserSettingsUserSettingsWrite } from '@zucommunications/gsk-docshare-web-api'
import { UserMapper } from '@/domain/userMapper'
import { UserSettings } from '@/domain/userSetting'

export const UserSettingsMapper = ({
  deserialize: (json: UserSettingsJsonldUserSettingsRead): UserSettings => {
    return <UserSettings>{
      id: Number(json['@id'].replace('/api/user_settings/', '')),
      userDelegate: json.delegateUser ? UserMapper.deserialize(json.delegateUser) : undefined,
      user: json.user ? UserMapper.deserialize(json.user) : undefined,
      isDelegateEnabled: json.isDelegateEnabled,
      showSupportMessages: json.showSupportMessages,
      showAssistanceTooltips: json.showAssistanceTooltips,
      phoneNotificationsEnabled: json.phoneNotificationsEnabled,
      notificationPhoneNumber: json.notificationPhoneNumber,
      enabledDashboardNotifications: json.enabledDashboardNotifications,
      enabledEmailNotifications: json.enabledEmailNotifications,
      preferredDashboardFilter: json.preferredDashboardFilter
    }
  },
  serializeIri: (obj: UserSettings): string => {
    return `/api/user_settings/${obj.id}`
  },
  serializePatch: (obj: UserSettings) : UserSettingsUserSettingsWrite => {
    return {
      delegateUser: obj.userDelegate ? UserMapper.serializeIri(obj.userDelegate) : null,
      isDelegateEnabled: obj.isDelegateEnabled,
      showSupportMessages: obj.showSupportMessages,
      showAssistanceTooltips: obj.showAssistanceTooltips,
      phoneNotificationsEnabled: obj.phoneNotificationsEnabled,
      notificationPhoneNumber: obj.notificationPhoneNumber,
      enabledDashboardNotifications: obj.enabledDashboardNotifications,
      enabledEmailNotifications: obj.enabledEmailNotifications,
      preferredDashboardFilter: obj.preferredDashboardFilter
    }
  }
})
