






















import { defineComponent } from '@vue/composition-api'
import { BaseIcon, BaseIconText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  inheritAttrs: false,
  props: ['iconName', 'text'],
  components: {
    BaseIcon,
    BaseIconText
  }
})
