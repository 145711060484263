<template>
  <div>
    <GroupHeading title="Document Owner" />
    <div v-if="document.documentOwner">
      <ChipBlock color="tertiaryGrey" text-color="secondaryGrey" class="mb-1">
        <template v-slot:text>
          <ChipUser :user="document.documentOwner" />
        </template>
      </ChipBlock>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'

export default defineComponent({
  props: {
    document: Object
  },
  components: {
    GroupHeading,
    ChipBlock,
    ChipUser
  }
})
</script>

<style scoped>

</style>
