











































































































import { defineComponent, computed, ref, watch, onMounted } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import CommentCard from '@/components/CommentCard.vue'
import DocumentCommentBlock from '@/components/DocumentCommentBlock.vue'
import OverlayLoading from '@/components/OverlayLoading.vue'
import {
  BaseAlert,
  BaseButton,
  BasePagination
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { commentMachine } from '@/machines/commentMachine'

export default defineComponent({
  props: {
    document: {
      type: Object,
      required: true
    },
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    GroupHeading,
    BaseAlert,
    BaseButton,
    BasePagination,
    CommentCard,
    DocumentCommentBlock,
    OverlayLoading
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const documentId = computed(() => store.state.documents.documentIdForAddin)
    const comments = computed(() => store.state.comments.commentsForAddin)
    const { state, send } = useMachine(commentMachine)
    const showCommentInput = ref(false)

    const commentCount = computed(() => {
      return props.document.commentCount
    })
    const commentInputValues = computed(() => {
      if (state.value.context.editComment) {
        return {
          label: 'Edit Comment',
          placeholder: 'Edit a comment',
          btnText: 'Edit'
        }
      } else if (state.value.context.commentId) {
        return {
          label: `Reply to ${state.value.context.replyTo}`,
          placeholder: 'Add a reply',
          btnText: 'Reply'
        }
      } else {
        return {
          label: 'New Comment',
          placeholder: 'Add a comment',
          btnText: 'Add'
        }
      }
    })

    watch(documentId, async () => {
      if (documentId.value) {
        showCommentInput.value = false
        send({ type: 'UPDATE_DOCUMENT', payload: `/api/documents/${documentId.value}` })
        store.dispatch('comments/updateDocumentCommentsAsSeen', documentId.value)
        const { total } = await store.dispatch('comments/findAddinComments', {
          id: documentId.value,
          page: state.value.context.page,
          itemsPerPage: state.value.context.itemsPerPage
        })
        send({ type: 'UPDATE_RESULTS_TOTAL', payload: total })
      }
    }, { immediate: true })

    const content = ref<HTMLInputElement | null>(null)
    const focusCommentInput = (event) => {
      showCommentInput.value = true
      setTimeout(() => content?.value?.focus(), 100)
      send({ type: 'UPDATE_COMMENT_ID', payload: event })
    }

    const submitCommentInput = () => {
      showCommentInput.value = false
      send({ type: 'SUBMIT' })
    }

    const closeCommentInput = () => {
      showCommentInput.value = false
      send({ type: 'CANCEL' })
    }

    const changeCommentsPage = async (page) => {
      send({ type: 'CHANGE_PAGE', payload: page })
      const { total } = await store.dispatch('comments/findAddinComments', {
        id: documentId.value,
        page,
        itemsPerPage: state.value.context.itemsPerPage
      })
      send({ type: 'UPDATE_RESULTS_TOTAL', payload: total })
    }

    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the comment tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 1) {
        focusOnContent()
      }
    })

    onMounted(() => {
      // When using keyboard navigation, the following takes the focus to the comment tab content when it loads so there isn't
      // a need to tab through the rest of the tabs to reach the tab content after opening it.
      focusOnContent()
    })

    return {
      state,
      send,
      showCommentInput,
      commentInputValues,
      comments,
      commentCount,
      content,
      focusCommentInput,
      submitCommentInput,
      closeCommentInput,
      changeCommentsPage,
      tabContent
    }
  }
})
