import { Machine, assign } from 'xstate'
import { CommentJsonldCommentWrite } from '@zucommunications/gsk-docshare-web-api'
import store from '@/store'

export const commentMachine = Machine<any>({
  id: 'commentMachine',
  initial: 'viewing',
  context: {
    content: undefined,
    document: undefined,
    commentId: undefined,
    commentHasReply: undefined,
    editComment: undefined,
    deleteComment: undefined,
    resolveComment: undefined,
    unresolveComment: undefined,
    replyTo: undefined,
    commentCount: 0,
    resultsTotal: 0,
    pageCount: 1,
    page: 1,
    itemsPerPage: 20
  },
  states: {
    viewing: {
      id: 'viewing',
      on: {
        UPDATE_CONTENT: {
          actions: ['log', 'updateContent']
        },
        UPDATE_DOCUMENT: {
          actions: ['log', 'updateDocument', 'clearContext', 'resetPagination']
        },
        UPDATE_COMMENT_ID: {
          actions: ['log', 'updateCommentId']
        },
        UPDATE_RESULTS_TOTAL: {
          actions: ['log', 'updateResultsTotal']
        },
        CHANGE_PAGE: {
          actions: 'setPage'
        },
        MODIFY_COMMENT: {
          actions: ['log', 'modifyComment'],
          target: 'invoking'
        },
        SUBMIT: {
          actions: 'log',
          target: 'invoking'
        },
        CANCEL: {
          actions: ['log', 'clearContext']
        }
      }
    },
    invoking: {
      initial: 'init',
      states: {
        init: {
          always: [{
            target: '#invoking.createComment',
            cond: 'isCommenting'
          }, {
            target: '#invoking.createReply',
            cond: 'isReplying'
          }, {
            target: '#invoking.editComment',
            cond: 'isEditing'
          }, {
            target: '#invoking.deleteComment',
            cond: 'isDeleting'
          }, {
            target: '#invoking.resolveComment',
            cond: 'isResolving'
          }, {
            target: '#invoking.unresolveComment',
            cond: 'isUnresolving'
          }]
        },
        createComment: {
          entry: ['log'],
          id: 'invoking.createComment',
          invoke: {
            src: 'createComment',
            onDone: {
              target: '#viewing',
              actions: ['clearContext', 'increaseCommentCount']
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        createReply: {
          entry: ['log'],
          id: 'invoking.createReply',
          invoke: {
            src: 'createReply',
            onDone: {
              target: '#viewing',
              actions: ['clearContext', 'increaseCommentCount']
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        editComment: {
          entry: ['log'],
          id: 'invoking.editComment',
          invoke: {
            src: 'editComment',
            onDone: {
              target: '#viewing',
              actions: 'clearContext'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        deleteComment: {
          entry: ['log'],
          id: 'invoking.deleteComment',
          invoke: {
            src: 'deleteComment',
            onDone: {
              target: '#viewing',
              actions: ['clearContext', 'decreaseCommentCount']
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        resolveComment: {
          entry: ['log'],
          id: 'invoking.resolveComment',
          invoke: {
            src: 'resolveComment',
            onDone: {
              target: '#viewing',
              actions: 'clearContext'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        unresolveComment: {
          entry: ['log'],
          id: 'invoking.unresolveComment',
          invoke: {
            src: 'unresolveComment',
            onDone: {
              target: '#viewing',
              actions: 'clearContext'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        }
      }
    }
  }
}, {
  services: {
    createComment: async (context, event) => {
      const comment = await store.dispatch('comments/create', <CommentJsonldCommentWrite>{
        content: context.content,
        document: context.document
      })
      store.commit('documents/increaseDocumentCommentCount')
      return comment
    },
    createReply: async (context, event) => {
      const comment = await store.dispatch('comments/createReply', {
        id: context.commentId,
        content: {
          content: context.content,
          document: context.document
        }
      })
      store.commit('documents/increaseDocumentCommentCount')
      return comment
    },
    editComment: async (context, event) => {
      return store.dispatch('comments/update', {
        id: context.commentId,
        content: {
          content: context.content,
          document: context.document
        }
      })
    },
    deleteComment: async (context, event) => {
      const comment = await store.dispatch('comments/delete', { id: context.commentId, hasReply: context.commentHasReply })
      if (!context.commentHasReply) {
        store.commit('documents/decreaseDocumentCommentCount')
      }
      return comment
    },
    resolveComment: async (context, event) => {
      return store.dispatch('comments/updateResolve', context.commentId)
    },
    unresolveComment: async (context, event) => {
      return store.dispatch('comments/updateUnresolve', context.commentId)
    }
  },
  actions: {
    updateContent: assign({
      content: (context, event: any) => event.payload
    }),
    updateDocument: assign({
      document: (context, event: any) => event.payload,
      commentCount: 0
    }),
    updateCommentId: assign({
      commentId: (context, event: any) => event.payload.commentId,
      replyTo: (context, event: any) => event.payload.replyTo,
      editComment: (context, event: any) => event.payload.editComment,
      content: (context, event: any) => event.payload.content
    }),
    updateResultsTotal: assign({
      resultsTotal: (context, event: any) => event.payload,
      pageCount: (context, event: any) => event.payload ? Math.ceil(event.payload / context.itemsPerPage) : 1
    }),
    modifyComment: assign({
      commentId: (context, event: any) => event.payload.commentId,
      commentHasReply: (context, event: any) => event.payload.commentHasReply,
      deleteComment: (context, event: any) => event.payload.deleteComment,
      unresolveComment: (context, event: any) => event.payload.unresolveComment,
      resolveComment: (context, event: any) => event.payload.resolveComment
    }),
    clearContext: assign({
      content: undefined,
      commentId: undefined,
      commentHasReply: undefined,
      editComment: undefined,
      deleteComment: undefined,
      resolveComment: undefined,
      unresolveComment: undefined,
      replyTo: undefined
    }),
    resetPagination: assign({
      resultsTotal: 0,
      pageCount: 0,
      page: 1
    }),
    increaseCommentCount: assign({
      commentCount: (context) => context.commentCount + 1
    }),
    decreaseCommentCount: assign({
      commentCount: (context) => context.commentCount - 1
    }),
    setPage: assign({
      page: (context, event: any) => event.payload
    }),
    setError: assign((context, event: any) => {
      return {
        error: event.data
      }
    }),
    log: (context, event) => {
      console.log('commentMachine', event.type, event.payload)
    }
  },
  guards: {
    isReplying: (context) => !!context.commentId && (!context.editComment && !context.deleteComment && !context.resolveComment && !context.unresolveComment),
    isCommenting: (context) => !context.commentId,
    isEditing: (context) => !!context.editComment,
    isDeleting: (context) => !!context.deleteComment,
    isResolving: (context) => !!context.resolveComment,
    isUnresolving: (context) => !!context.unresolveComment
  }
})
