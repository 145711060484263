import Vue from 'vue'

const state = () => ({
  documents: {},
  /**
   * Documents displayed on the dashboard
   */
  documentIdsForDashboard: [],
  // documentsForDashboard: [],
  /**
   * Documents displayed on the search
   */
  documentIdsForSearch: [],
  // documentsForSearch: [],
  /**
   * Current addin document
   */
  documentIdForAddin: undefined,
  /**
   */
  urlForCurrentSharepointDocument: undefined
})

// getters
const getters = {
  getDocumentsForDashboard: (state) => {
    return state.documentIdsForDashboard.map(id => {
      if (state.documents[id]) {
        return state.documents[id]
      }
      return []
    })
  },
  getDocumentsForSearch: (state) => {
    return state.documentIdsForSearch.map(id => {
      if (state.documents[id]) {
        return state.documents[id]
      }
      return []
    })
  },
  getDocumentForAddin: (state) => {
    if (state.documents[state.documentIdForAddin]) {
      return state.documents[state.documentIdForAddin]
    }
    return {}
  },
  getById: (state) => (id) => {
    if (state.documents[id]) {
      return state.documents[id]
    }
    return {}
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  setDocumentIdsForDashboard (state, value) {
    state.documentIdsForDashboard = value.map(x => x.id)
  },
  setDocumentIdsForSearch (state, value) {
    state.documentIdsForSearch = value.map(x => x.id)
  },
  setDocumentIdForAddin (state, value) {
    state.documentIdForAddin = value.id
  },
  addDocumentsToCache (state, value) {
    value.forEach(document => {
      Vue.set(state.documents, document.id, document)
    })
  },
  updateDocumentInCache (state, value) {
    state.documents[state.documentIdForAddin] = value
  },
  increaseDocumentCommentCount (state, value) {
    const document = state.documents[state.documentIdForAddin]
    document.commentCount = document.commentCount + 1
    state.documents[state.documentIdForAddin] = document
  },
  decreaseDocumentCommentCount (state, value) {
    const document = state.documents[state.documentIdForAddin]
    document.commentCount = document.commentCount - 1
    state.documents[state.documentIdForAddin] = document
  },
  removeDocumentFromCache (state, value) {
    delete state.documents[value]
  },
  addDocumentToCache (state, value) {
    Vue.set(state.documents, value.id, value)
  },
  setUrlForCurrentSharepointDocument (state, value) {
    state.urlForCurrentSharepointDocument = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
