import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from './i18n'
import { VuetifyOptions } from '@zucommunications/gsk-docshare-web-design-system'

Vue.use(Vuetify)

export default new Vuetify({
  ...VuetifyOptions,
  // use i18n instance defined in this application
  // @see https://vuetifyjs.com/en/features/internationalization/#vue-i18n
  lang: {
    t: (key, ...params) => i18n.t(key, params) as any
  }
})
