import { DocumentAttachmentDocumentAttachmentWrite } from '@zucommunications/gsk-docshare-web-api'
import { DocumentAttachment } from '@/domain/documentAttachment'
import { DocumentMapper } from '@/domain/documentMapper'
import { MediaMapper } from '@/domain/mediaMapper'

export const DocumentAttachmentMapper = ({
  deserialize: (json): DocumentAttachment => {
    return <DocumentAttachment>{
      id: Number(json['@id'].replace('/api/document_attachments/', '')),
      document: json.document,
      attachment: json.attachment,
      position: json.position
    }
  },
  serialize: (obj: DocumentAttachment): DocumentAttachmentDocumentAttachmentWrite => {
    return {
      document: obj.document ? DocumentMapper.serializeIri(obj.document) : '',
      attachment: obj.attachment ? MediaMapper.serializeIri(obj.attachment) : '',
      position: obj.position
    }
  }
})
