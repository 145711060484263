import { UserGroupTypeApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { UserGroupType } from '@/domain/userGroupType'
import { UserGroupTypeMapper } from '@/domain/userGroupTypeMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new UserGroupTypeApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, q?: string, options?: any
}

class UserGroupTypeRepository implements IFindData<UserGroupType>, ICrudData<UserGroupType>, ISearchData<UserGroupType> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getUserGroupTypeItem(id)
      return UserGroupTypeMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 10, q }: SearchParams) {
    try {
      const response = await api.getUserGroupTypeCollection(page, itemsPerPage, q)
      return <SearchResult<UserGroupType>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => UserGroupTypeMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: UserGroupType) {
    try {
      const params = UserGroupTypeMapper.serializePatch(obj)
      const response = await api.postUserGroupTypeCollection(params)
      return UserGroupTypeMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: UserGroupType) {
    try {
      const response = await api.patchUserGroupTypeItem(String(obj.id), UserGroupTypeMapper.serializePatch(obj))
      return UserGroupTypeMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteUserGroupTypeItem(id)
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new UserGroupTypeRepository()
