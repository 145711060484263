












































import { defineComponent, computed, ref, watch, onBeforeUnmount } from '@vue/composition-api'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMedia } from '@/composables/useMedia'

export default defineComponent({
  name: 'attachment-card',
  components: {
    BaseButton,
    BaseIcon
  },
  props: {
    attachment: {
      type: Object,
      required: true
    },
    documentAttachmentId: {
      type: Number,
      required: true
    },
    canRemove: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const urlCreator = window.URL || window.webkitURL
    const docType = props.attachment.extension.substring(1)

    const buttonText = computed(() => {
      switch (props.attachment.mimeType) {
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'Download'
        case 'application/pdf':
        case 'image/jpeg':
        case 'image/png':
        default:
          return 'View'
      }
    })

    const mediaUrl = ref<string | null>(null)
    const { mediaBlob, removeMediaBlob } = useMedia()

    watch(mediaBlob, () => {
      if (mediaUrl.value) {
        urlCreator.revokeObjectURL(mediaUrl.value)
      }

      if (mediaBlob.value) {
        mediaUrl.value = urlCreator.createObjectURL(mediaBlob.value.blob)

        window.open(mediaUrl.value, '_blank')
        // Removed the blob to ensure it won't trigger multiple windows from opening.
        removeMediaBlob()
      }
    })

    onBeforeUnmount(() => {
      if (mediaUrl.value) {
        urlCreator.revokeObjectURL(mediaUrl.value)
        removeMediaBlob()
      }
    })

    return {
      buttonText,
      docType
    }
  }
})
