import { Machine, assign } from 'xstate'

export const promiseMachine = Machine<any>({
  id: 'promiseMachine',
  initial: 'idle',
  context: {
    loading: false,
    error: undefined,
    needsConfirmation: false,
    event: undefined
  },
  on: {},
  states: {
    idle: {
      on: {
        INVOKE: [{
          target: 'confirming',
          cond: 'needsConfirmation'
        }, {
          target: 'invoking'
        }]
      },
      exit: ['setEvent']
    },
    confirming: {
      on: {
        YES: {
          target: 'invoking'
        },
        NO: {
          target: 'idle'
        }
      }
    },
    invoking: {
      entry: ['onInvokeEnter', 'clearEvent'],
      exit: ['onInvokeExit'],
      invoke: {
        src: 'invoke',
        onDone: {
          target: 'done'
        },
        onError: {
          target: 'error'
        }
      }
    },
    done: {
      entry: 'onDoneEnter',
      on: {
        IDLE: {
          target: 'idle'
        }
      },
      after: {
        SUCCESS_DELAY: 'idle'
      }
    },
    error: {
      entry: 'onErrorEnter',
      exit: ['onErrorExit', 'setEvent'],
      on: {
        INVOKE: [{
          target: 'confirming',
          cond: 'needsConfirmation'
        }, {
          target: 'invoking'
        }],
        IDLE: {
          target: 'idle'
        }
      }
    }
  }
}, {
  services: {
    invoke: async (context, event) => {
      return Promise.reject(new Error('promiseMachine requires invokable service'))
    }
  },
  actions: {
    onInvokeEnter: assign({
      loading: true
    }),
    onInvokeExit: assign({
      loading: false
    }),
    onErrorEnter: assign({
      error: (context, event: any) => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        return event.data.message
      }
    }),
    onDoneEnter: (context, event) => {
      console.log(context, event)
    },
    onErrorExit: assign({
      error: undefined
    }),
    setEvent: assign({
      event: (context, event: any) => {
        return event.value
      }
    }),
    clearEvent: assign({
      event: undefined
    })
  },
  delays: {
    SUCCESS_DELAY: 3000
  },
  guards: {
    needsConfirmation: (context, event) => {
      return context.needsConfirmation
    }
  }
})
