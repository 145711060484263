





























































































































































import { computed, defineComponent, inject, watch, ref } from '@vue/composition-api'
import { documentCreateMachine } from '@/machines/documentCreateMachine'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import {
  BaseInputText,
  BaseButton,
  BaseInputDatePicker,
  BaseInputTimePicker,
  BaseInputSelect
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import SvgDocumentCreated from '@/assets/documentCreated.svg'
import ButtonExternal from '@/components/ButtonExternal.vue'
import { useMachine } from 'xstate-vue2'
import { isObject } from '@/validations'
import repository from '@/domain/userGroupRepository'
import documentTemplateRepository from '@/domain/documentTemplateRepository'
import { sidebarMachineSendKey } from '@/router/views/AppOutlet.vue'
import { UserGroup } from '@/domain/userGroup'

export default defineComponent({
  props: ['id', 'title'],
  components: {
    BaseInputText,
    BaseButton,
    BaseInputDatePicker,
    BaseInputTimePicker,
    BaseInputSelect,
    SvgDocumentCreated,
    BaseCombobox,
    ButtonExternal
  },
  setup (props, { root }: any) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const { state, send } = useMachine(documentCreateMachine)
    // Load my groups
    const myGroups = computed(() => {
      const groups = store.getters['groups/myGroups']
      return groups.filter((userGroup: UserGroup) => {
        return userGroup.isActive
      })
    })

    const documentTemplateSidebar = ref<any>(null)

    // Load template
    const template = computed(() => store.state.documentTemplates.template)
    const fetchData = () => {
      send('SET_LOADING')
      documentTemplateRepository.findById(props.id).then(x => {
        store.commit('documentTemplates/setTemplate', x)
        send({ type: 'SET_TEMPLATE' })
      })
    }

    const updateAria = () => {
      const element = documentTemplateSidebar.value
      if (element) {
        element.setAttribute('aria-label', `${props.title} template form.`)
      }
    }

    const focusOnSidebar = () => {
      if (documentTemplateSidebar.value) {
        documentTemplateSidebar.value.focus()
      }
    }

    // INJECT
    const sendSidebar = inject(sidebarMachineSendKey)

    // WATCH
    watch(() => props.id, () => {
      fetchData()
      updateAria()
      focusOnSidebar()
    }, { immediate: true })
    watch(() => documentTemplateSidebar.value, () => {
      updateAria()
      focusOnSidebar()
    })

    const disablePastDates = (val: string) => val >= new Date().toISOString().substr(0, 10)

    // JOINT GROUPS
    const searchPromise = q => repository.findByCriteria({
      isActive: true,
      q: q
    })
    const itemText = x => x.title

    return {
      template,
      state,
      send,
      sendSidebar,
      disablePastDates,
      myGroups,
      searchPromise,
      itemText,
      isObject,
      documentTemplateSidebar
    }
  }
})
