


















































import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { useTrackingListsMachine } from '@/machines/tableMachine'
import repository from '@/domain/trackingListRepository'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { promiseMachine } from '@/machines/promiseMachine'

export default defineComponent({
  components: {
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle,
    DialogConfirm,
    BaseIcon
  },
  setup () {
    const deleteAction = async (id) => {
      await repository.delete(id)
      send({ type: 'SUBMIT' })
    }

    const { state, send } = useTrackingListsMachine()

    const { state: deleteState, send: deleteSend } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: (context, event: any) => deleteAction(event.value)
      },
      actions: {
        onDoneEnter: () => {
        }
      }
    })

    return {
      state,
      send,
      deleteState,
      deleteSend
    }
  }
})
