



























































































































import { computed, defineComponent, reactive, watch } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { viewEditConfirmMachine } from '@/machines/viewEditConfirmMachine'
import { Document } from '@/domain/document'
import GroupHeading from '@/components/GroupHeading.vue'
import {
  BaseInputText,
  BaseIcon,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import OverlayLoading from '@/components/OverlayLoading.vue'
import repositoryDocument from '@/domain/documentRepository'
import DocumentSharedUsers from '@/components/DocumentSharedUsers.vue'
import DocumentTitle from '@/components/DocumentTitle.vue'
import DocumentJointMinistries from '@/components/DocumentJointMinistries.vue'
import AlertSuccess from '@/components/AlertSuccess.vue'
import AlertError from '@/components/AlertError.vue'
import { User, UserWithAccess } from '@/domain/user'

export default defineComponent({
  components: {
    GroupHeading,
    BaseInputText,
    DocumentStatus,
    BaseIcon,
    BaseButton,
    DialogConfirm,
    OverlayLoading,
    DocumentSharedUsers,
    DocumentJointMinistries,
    DocumentTitle,
    AlertSuccess,
    AlertError
  },
  setup (props, { root }) {
    const store = root.$store
    const document = computed(() => store.getters['documents/getDocumentForAddin'])
    const form = reactive<Document>({
      id: undefined,
      title: '',
      documentSharedUsers: [],
      jointUserGroups: []
    })
    watch(document, () => {
      form.id = document.value.id
    }, {
      immediate: true
    })
    const { state, send } = useMachine(viewEditConfirmMachine, {
      services: {
        completing: async (context: any, event: any) => {
          const document = await repositoryDocument.decisionGenerate(form as Document)
          store.commit('documents/updateDocumentInCache', document)
          return document
        },
        saving: async (context: any, event) => {
          const document = await repositoryDocument.update(form as Document)
          store.commit('documents/updateDocumentInCache', document)
          return document
        }
      }
    })
    const userWithAccessRemove = (user: User) => {
      if (form.documentSharedUsers?.length) {
        form.documentSharedUsers = form.documentSharedUsers.filter((userWithAccess: UserWithAccess) => {
          return userWithAccess.user?.id !== user.id
        })
      }
    }
    const userWithAccessAdd = (entity: UserWithAccess) => {
      if (form.documentSharedUsers?.length) {
        form.documentSharedUsers = [...form.documentSharedUsers, JSON.parse(JSON.stringify(entity))]
      } else {
        form.documentSharedUsers = [JSON.parse(JSON.stringify(entity))]
      }
    }
    const documentSync = () => {
      repositoryDocument.findById(document.value.id).then(d => {
        store.commit('documents/addDocumentToCache', d)
      })
    }
    return {
      document,
      documentSync,
      state,
      send,
      form,
      userWithAccessRemove,
      userWithAccessAdd
    }
  }
})
