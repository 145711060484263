
















import { defineComponent } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import {
  BaseIcon,
  BaseIconText
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: {
    leadUserGroup: Object
  },
  components: {
    GroupHeading,
    BaseIcon,
    BaseIconText
  }
})
