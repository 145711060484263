

































import { defineComponent, ref } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { helpMachine } from '@/machines/helpMachine'
import { mdiHelp as icon } from '@mdi/js'
import HelpLogoGreen from '@/assets/helpLogoGreen.svg'
import HelpLogoWhite from '@/assets/helpLogoWhite.svg'
import HelpWindow from '@/components/HelpWindow.vue'

export default defineComponent({
  components: {
    HelpWindow,
    HelpLogoGreen,
    HelpLogoWhite
  },
  setup () {
    const fab = ref<boolean>(false)
    const { state, send } = useMachine(helpMachine)
    return {
      state, send, icon, fab
    }
  }
})
