































import { defineComponent } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import ButtonExternal from '@/components/ButtonExternal.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DocumentOwner from '@/components/DocumentOwner.vue'
import SvgGuyCard from '@/components/SvgGuyCard.vue'

export default defineComponent({
  props: {
    document: Object,
    documentUrl: String
  },
  components: {
    GroupHeading,
    DocumentStatusChip,
    SvgGuyCard,
    DocumentOwner,
    DocumentStatus,
    ButtonExternal
  }
})
