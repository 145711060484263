import { render, staticRenderFns } from "./AppBarListGroupPages.vue?vue&type=template&id=5efd1ab2&scoped=true&"
import script from "./AppBarListGroupPages.vue?vue&type=script&lang=ts&"
export * from "./AppBarListGroupPages.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5efd1ab2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VDivider,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VSubheader})
