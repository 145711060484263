


















































































import { computed, defineComponent, inject, toRefs, watch } from '@vue/composition-api'
import { useManagePackagesMachine } from '@/machines/tableMachine'
import { BaseInputSelect, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useAgendas } from '@/composables/useAgendas'
import AgendaStatus from '@/components/AgendaStatus.vue'
import { sidebarMachineSendKey } from '@/router/views/AppOutlet.vue'
import ListingTableMeta from '@/components/ListingTableMeta.vue'
import ListingTable from '@/components/ListingTable.vue'
import ListingPagination from '@/components/ListingPagination.vue'
import ListingFormSearch from '@/components/ListingFormSearch.vue'
import ListingTitle from '@/components/ListingTitle.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'

export default defineComponent({
  props: {
    packageType: {
      type: String,
      required: true
    }
  },
  components: {
    BaseInputSelect,
    BaseIcon,
    AgendaStatus,
    ListingTableMeta,
    ListingTable,
    ListingPagination,
    ListingFormSearch,
    ListingTitle,
    ChipBoolean
  },
  setup (props) {
    const { packageType } = toRefs(props)
    const { agendaDeleted, setAgendaDeleted } = useAgendas()
    const packageTypeName = computed(() => packageType.value === 'document-bundle' ? 'Document Bundle' : 'Agenda')
    // TODO: load agenda types for current package type
    const agendaTypes = [
      'Cabinet',
      'Committee',
      'Event Material',
      'Weekly Briefing Material'
    ]
    const agendaStatuses = [
      'Draft',
      'Preview',
      'Released'
    ]
    const sendSidebar = inject(sidebarMachineSendKey)

    const { state, send } = useManagePackagesMachine()
    send({ type: 'CHANGE_PROPERTY', key: 'packageType', value: packageType.value })

    watch(packageType, (newValue) => {
      send({ type: 'CHANGE_PROPERTY', key: 'packageType', value: packageType.value })
    })
    watch(agendaDeleted, () => {
      if (agendaDeleted.value) {
        send({ type: 'SUBMIT' })
        setAgendaDeleted(false)
      }
    })
    const handleRowClick = ({ id }) => {
      if (sendSidebar) {
        sendSidebar({ type: 'AGENDA_VIEW', payload: { id } })
      }
    }
    const handleStatusSelect = (event) => {
      // If wanting to filter by released or preview, also include releasePreview agendas
      if ((event.includes('Preview') || event.includes('Released')) && !event.includes('releasePreview')) {
        event.push('releasePreview')
      } else if (!(event.includes('Preview') || event.includes('Released'))) {
        const index = event.indexOf('releasePreview')
        if (index > -1) {
          event.splice(index, 1)
        }
      }
      send({ type: 'CHANGE_PROPERTY', key: 'searchStatuses', value: event })
    }
    return {
      agendaStatuses,
      agendaTypes,
      handleRowClick,
      handleStatusSelect,
      state,
      send,
      packageTypeName
    }
  }
})
