import { assign, Machine } from 'xstate'
import { format } from 'date-fns'
import store from '@/store'
import { WorkflowStep } from '@/domain/workflowGroup'
import { Document } from '@/domain/document'
import repositoryWorkflowGroup from '@/domain/workflowGroupRepository'
import repositoryDocument from '@/domain/documentRepository'

export type DocumentEvent = {
  type: string;
  value: any;
  key?: string;
}

interface DocumentSchema {
  states: {
    viewing: {};
    editing: {
      states: {
        init: {};
        loadWorkflows: {};
        idle: {};
      }
    };
    saving: {
      states: {
        draft: {};
        collaborationMoving: {};
        collaborationInProgress: {};
        approvalMoving: {};
        approvalInProgress: {};
      }
    };
    confirming: {};
    progressing: {
      states: {
        init: {};
        progressDocument: {};
        approveDocument: {};
      }
    };
  };
}

export interface DocumentContext {
  referenceDocument: any;
  title: any;
  jointUserGroups: any;
  documentManager: any;
  dueDateDate: any;
  dueDateTime: any;
  documentSharedUsers: any;
  workflowStepsRequired: WorkflowStep[];
  workflowStepsAdditional: any;
  collaborationDate: any;
  collaborationTime: any;
  approvalDate: any;
  approvalTime: any;
  error: any;
  customEmailNotificationContent: string | undefined;
}

export const documentMachine = Machine<DocumentContext, DocumentSchema, DocumentEvent>({
  id: 'documentMachine',
  initial: 'viewing',
  context: {
    referenceDocument: undefined,
    title: undefined,
    jointUserGroups: undefined,
    documentManager: undefined,
    dueDateDate: undefined,
    dueDateTime: undefined,
    documentSharedUsers: [],
    workflowStepsAdditional: [],
    workflowStepsRequired: [],
    collaborationDate: undefined,
    collaborationTime: undefined,
    approvalDate: undefined,
    approvalTime: undefined,
    error: undefined,
    customEmailNotificationContent: undefined
  },
  on: {
    SET_PROPERTY: {
      actions: ['setProperty', 'log', 'clearError']
    },
    SET_REFERENCE_DOCUMENT: {
      actions: ['setReferenceDocument', 'setContextFromReferenceDocument', 'log', 'clearError']
    }
  },
  states: {
    viewing: {
      id: 'viewing',
      on: {
        TOGGLE_MODE: {
          target: 'editing'
        },
        CONFIRM: {
          target: 'confirming',
          actions: ['log']
        },
        PROGRESSING: {
          target: 'progressing',
          actions: ['log']
        }
      }
    },
    editing: {
      initial: 'init',
      entry: ['setContextFromReferenceDocument', 'log'],
      states: {
        init: {
          always: [
            { target: 'loadWorkflows', cond: 'workflowsRequired' },
            { target: 'idle' }
          ]
        },
        loadWorkflows: {
          invoke: {
            src: 'loadWorkflows',
            onDone: {
              target: 'idle',
              actions: 'setWorkflows'
            },
            onError: {
              target: 'idle',
              actions: 'setError'
            }
          }
        },
        idle: {}
      },
      on: {
        TOGGLE_MODE: {
          target: 'viewing'
        },
        MANAGER_OTHER: {
          actions: ['managerClear', 'log']
        },
        MANAGER_CONTENT: {
          actions: ['managerContent', 'log']
        },
        MANAGER_USER: {
          actions: ['managerUser', 'log']
        },
        SHARED_USER_ADD: {
          actions: ['documentSharedUserAdd', 'log']
        },
        SHARED_USER_REMOVE: {
          actions: ['documentSharedUserRemove', 'log']
        },
        WORKFLOW_STEP_ADDITIONAL_ADD: {
          actions: ['workflowStepsAdditionalAdd', 'log']
        },
        WORKFLOW_STEP_ADDITIONAL_REMOVE: {
          actions: ['workflowStepsAdditionalRemove', 'log']
        },
        SAVE: [{
          target: '#saving.draft',
          cond: 'isDraft'
        }, {
          target: '#saving.collaborationMoving',
          cond: 'isCollaborationMoving'
        }, {
          target: '#saving.collaborationInProgress',
          cond: 'isCollaborationInProgress'
        }, {
          target: '#saving.approvalMoving',
          cond: 'isApprovalMoving'
        }, {
          target: '#saving.approvalInProgress',
          cond: 'isApprovalInProgress'
        }]
      }
    },
    saving: {
      states: {
        draft: {
          id: 'saving.draft',
          invoke: {
            src: 'savingDraft',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        collaborationMoving: {
          id: 'saving.collaborationMoving',
          invoke: {
            src: 'savingCollaborationMoving',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        collaborationInProgress: {
          id: 'saving.collaborationInProgress',
          invoke: {
            src: 'savingCollaborationInProgress',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        approvalMoving: {
          id: 'saving.approvalMoving',
          invoke: {
            src: 'savingApprovalMoving',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        approvalInProgress: {
          id: 'saving.approvalInProgress',
          invoke: {
            src: 'savingApprovalInProgress',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        }
      }
    },
    confirming: {
      on: {
        YES: 'progressing',
        NO: 'viewing'
      }
    },
    progressing: {
      initial: 'init',
      states: {
        init: {
          always: [{
            target: '#progressing.approveDocument',
            cond: 'isCompleting'
          }, {
            target: '#progressing.progressDocument',
            cond: 'isDraft'
          }, {
            target: '#progressing.progressDocument',
            cond: 'isCollaborationMoving'
          }, {
            target: '#progressing.progressDocument',
            cond: 'isCollaborationInProgress'
          }, {
            target: '#progressing.progressDocument',
            cond: 'isApprovalMoving'
          }]
        },
        progressDocument: {
          entry: ['log'],
          id: 'progressing.progressDocument',
          invoke: {
            src: 'progressDocument',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        },
        approveDocument: {
          entry: ['log'],
          id: 'progressing.approveDocument',
          invoke: {
            src: 'approveDocument',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing',
              actions: 'setError'
            }
          }
        }
      }
    }
  }
}, {
  actions: {
    setReferenceDocument: assign<DocumentContext, DocumentEvent>({
      referenceDocument: (context, event) => event.value
    }),
    /**
     * Set form context values from reference document when entering edit
     */
    setContextFromReferenceDocument: assign<DocumentContext, DocumentEvent>((context, event) => {
      let workflowStepsAdditional
      let workflowStepsRequired
      if (context.referenceDocument.status && context.referenceDocument.status.includes('approval')) {
        workflowStepsAdditional = context.referenceDocument?.approval?.workflowStepsAdditional || []
        workflowStepsRequired = context.referenceDocument?.approval?.workflowStepsRequired || []
      } else {
        workflowStepsAdditional = context.referenceDocument?.collaboration?.workflowStepsAdditional || []
        workflowStepsRequired = context.referenceDocument?.collaboration?.workflowStepsRequired || []
      }
      return {
        title: context.referenceDocument.title,
        jointUserGroups: context.referenceDocument.jointUserGroups ? context.referenceDocument.jointUserGroups : undefined,
        documentManager: context.referenceDocument.documentManager ? context.referenceDocument.documentManager : undefined,
        dueDateDate: context.referenceDocument.dueDate ? format(context.referenceDocument.dueDate, 'yyyy-MM-dd') : undefined,
        dueDateTime: context.referenceDocument.dueDate ? format(context.referenceDocument.dueDate, 'HH:mm') : undefined,
        collaborationDate: context.referenceDocument?.collaboration?.dueDate ? format(context.referenceDocument.collaboration.dueDate, 'yyyy-MM-dd') : undefined,
        collaborationTime: context.referenceDocument?.collaboration?.dueDate ? format(context.referenceDocument.collaboration.dueDate, 'HH:mm') : undefined,
        approvalDate: context.referenceDocument?.approval?.dueDate ? format(context.referenceDocument.approval.dueDate, 'yyyy-MM-dd') : undefined,
        approvalTime: context.referenceDocument?.approval?.dueDate ? format(context.referenceDocument.approval.dueDate, 'HH:mm') : undefined,
        documentSharedUsers: context.referenceDocument?.documentSharedUsers || [],
        workflowStepsAdditional,
        workflowStepsRequired,
        customEmailNotificationContent: context.referenceDocument?.customEmailNotificationContent || undefined
      }
    }),
    documentSharedUserAdd: assign<DocumentContext, DocumentEvent>((context, event) => {
      return {
        documentSharedUsers: [...context.documentSharedUsers, event.value]
      }
    }),
    documentSharedUserRemove: assign<DocumentContext, DocumentEvent>((context, event) => {
      return {
        documentSharedUsers: context.documentSharedUsers.filter(x => x.user.id !== event.value)
      }
    }),
    workflowStepsAdditionalAdd: assign<DocumentContext, DocumentEvent>((context, event) => {
      return {
        workflowStepsAdditional: [...context.workflowStepsAdditional, event.value]
      }
    }),
    workflowStepsAdditionalRemove: assign<DocumentContext, DocumentEvent>((context, event) => {
      return {
        workflowStepsAdditional: context.workflowStepsAdditional.filter(x => x.id !== event.value)
      }
    }),
    setProperty: assign<DocumentContext, DocumentEvent>((context, event) => {
      if (event.key) {
        return {
          [event.key]: event.value
        }
      }
      return {}
    }),
    setError: assign((context, event: any) => {
      return {
        error: event.data
      }
    }),
    clearError: assign((context, _) => {
      return { error: undefined }
    }),
    managerUser: assign((context, event: any) => {
      return {
        documentManager: event.data
      }
    }),
    managerClear: assign((context, event: any) => {
      return {
        documentManager: undefined
      }
    }),
    managerContent: assign((context, event: any) => {
      return {
        customEmailNotificationContent: event.data
      }
    }),
    setWorkflows: assign((context, event: any) => {
      let usersSelectedForCollaboration = []
      if (context.referenceDocument.status.includes('collaboration')) {
        usersSelectedForCollaboration = context.referenceDocument.collaboration?.workflowStepsRequired?.reduce((acc, val) => {
          acc[val.position] = val.user
          return acc
        }, {})
      } else if (context.referenceDocument.status.includes('approval')) {
        usersSelectedForCollaboration = context.referenceDocument.approval?.workflowStepsRequired?.reduce((acc, val) => {
          acc[val.position] = val.user
          return acc
        }, {})
      }
      return {
        workflowStepsRequired: event.data.map((workflowStep: WorkflowStep) => {
          if (workflowStep.position !== undefined && usersSelectedForCollaboration[workflowStep.position]) {
            workflowStep.user = usersSelectedForCollaboration[workflowStep.position]
          } else if (workflowStep.users?.length) {
            workflowStep.user = workflowStep.users[0]
          }
          return workflowStep
        })
      }
    }),
    log: (context, event) => {
      console.log('documentMachine', event.type, event.value)
    }
  },
  services: {
    loadWorkflows: async (context, event) => {
      return repositoryWorkflowGroup.findByDocumentId(context.referenceDocument.id)
    },
    progressDocument: async (context, event) => {
      const document = await repositoryDocument.progress(<Document>{
        id: context.referenceDocument.id,
        status: context.referenceDocument.status
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    approveDocument: async (context, event) => {
      const document = await repositoryDocument.approve(<Document>{
        id: context.referenceDocument.id
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    savingDraft: async (context, event) => {
      const document = await repositoryDocument.update(<Document>{
        id: context.referenceDocument.id,
        title: context.title,
        documentSharedUsers: context.documentSharedUsers,
        jointUserGroups: context.jointUserGroups,
        documentManager: context.documentManager,
        customEmailNotificationContent: context.customEmailNotificationContent
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    savingCollaborationInProgress: async (context, event) => {
      const document = await repositoryDocument.update(<Document>{
        id: context.referenceDocument.id,
        title: context.title,
        collaboration: {
          workflowStepsAdditional: context.workflowStepsAdditional
        }
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    savingCollaborationMoving: async (context, event) => {
      const document = await repositoryDocument.update(<Document>{
        id: context.referenceDocument.id,
        title: context.title,
        collaboration: {
          dueDate: (new Date(`${context.collaborationDate} ${context.collaborationTime}`)),
          workflowStepsRequired: context.workflowStepsRequired,
          workflowStepsAdditional: context.workflowStepsAdditional
        }
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    savingApprovalMoving: async (context, event) => {
      const document = await repositoryDocument.update(<Document>{
        id: context.referenceDocument.id,
        title: context.title,
        approval: {
          dueDate: (new Date(`${context.approvalDate} ${context.approvalTime}`)),
          workflowStepsRequired: context.workflowStepsRequired,
          workflowStepsAdditional: context.workflowStepsAdditional
        }
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    savingApprovalInProgress: async (context, event) => {
      const document = await repositoryDocument.update(<Document>{
        id: context.referenceDocument.id,
        title: context.title,
        approval: {
          workflowStepsAdditional: context.workflowStepsAdditional
        }
      })
      store.commit('documents/updateDocumentInCache', document)
      return document
    }
  },
  guards: {
    isCompleting: (context) => context.referenceDocument.requiresMyInput,
    isDraft: (context) => context.referenceDocument.status === 'draft',
    isCollaborationMoving: (context) => context.referenceDocument.status === 'collaboration-moving',
    isCollaborationInProgress: (context) => context.referenceDocument.status === 'collaboration-in-progress',
    isApprovalMoving: (context) => context.referenceDocument.status === 'approval-moving',
    isApprovalInProgress: (context) => context.referenceDocument.status === 'approval-in-progress',
    workflowsRequired: (context) => context.referenceDocument.status === 'collaboration-moving' || context.referenceDocument.status === 'approval-moving'
  }
})
