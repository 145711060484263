






























import { defineComponent, PropType, toRefs, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import {
  BaseInputText
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { Document } from '@/domain/document'

export default defineComponent({
  props: {
    title: String,
    isViewing: Boolean,
    isSaving: Boolean,
    isEditing: Boolean,
    field: Object as PropType<Document>,
    formValue: String,
    editable: {
      type: Boolean,
      default: true
    },
    rules: Array
  },
  components: {
    GroupHeading,
    BaseInputText
  },
  setup (props, { emit }) {
    const { isEditing, field } = toRefs(props)
    watch(() => isEditing?.value, () => {
      if (isEditing?.value && field?.value) {
        emit('formChange', field.value?.title)
      }
    }, {
      immediate: true
    })
  }
})
