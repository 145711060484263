
















import { watch, computed, defineComponent } from '@vue/composition-api'
import DocshareLogo from '@/assets/docshareWhite.svg'
import repositoryDocument from '@/domain/documentRepository'

export default defineComponent({
  name: 'Addin',
  components: {
    DocshareLogo
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const router = root.$router

    const url = window.Office?.context?.document?.url || ''
    const isAddinRoute = router.currentRoute.name === 'Addin'
    // whenever the document status changes
    const document = computed(() => store.getters['documents/getDocumentForAddin'])
    watch(() => document.value.status, () => {
      // check for redirect url and store in document module
      repositoryDocument.findByUrl(url).then(document => {
        store.commit('documents/setUrlForCurrentSharepointDocument', document.redirectUrl)
        // if on base addin route redirect to detail page
        if (isAddinRoute) {
          router.push({
            name: 'AddinDetail',
            params: {
              documentId: String(document.id)
            }
          }).catch(err => {
            console.error(err)
          })
        }
      })
    }, { immediate: true })
  }
})
