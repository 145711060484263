var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListingTitle',{attrs:{"type":"Tracking List"}}),_c('v-sheet',{staticClass:"mb-5 pa-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',[_c('ListingFormSearch',{attrs:{"state":_vm.state,"send":_vm.send}})],1)],1)],1),_c('ListingTableMeta',{attrs:{"state":_vm.state,"send":_vm.send,"route":{ name: 'TrackingListsManageCreate' },"type":"Tracking List"}}),_c('ListingTable',{attrs:{"state":_vm.state,"send":_vm.send,"handleRowClick":function (ref) {
  var id = ref.id;

  return _vm.$router.push({ name: 'TrackingListsDetail', params: { id: id } });
},"headers":[
    { text: 'Title', value: 'title' },
    { text: 'Actions', value: 'actions', width: '120px', sortable: false }
  ]},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('BaseIcon',{attrs:{"iconName":"mdiDotsVertical","dark":""}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{nativeOn:{"click":function($event){return _vm.deleteSend({ type: 'INVOKE', value: item.id })}}},[_c('v-list-item-title',{staticClass:"ui-action-menu__btn"},[_vm._v("Delete List")])],1)],1)],1)]}}])}),_c('ListingPagination',{attrs:{"state":_vm.state,"send":_vm.send}}),_c('DialogConfirm',{attrs:{"value":_vm.deleteState.matches('confirming'),"width":"500","persistent":""},on:{"confirm":function($event){return _vm.deleteSend({ type: 'YES', value: _vm.deleteState.context.event })},"cancel":function($event){return _vm.deleteSend({ type: 'NO' })}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Delete Tracking List")]},proxy:true},{key:"content",fn:function(){return [_c('p',{staticClass:"my-2"},[_vm._v("You are about to delete this tracking list.")]),_c('p',{staticClass:"my-2"},[_vm._v("Are you sure you wish to proceed?")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }