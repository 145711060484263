import { onMounted, onBeforeUnmount } from '@vue/composition-api'

export function usePoll (fn, delay) {
  let polling

  const pollData = () => {
    polling = setInterval(fn, delay)
  }

  onMounted(() => {
    pollData()
  })

  onBeforeUnmount(() => {
    clearInterval(polling)
  })
}
