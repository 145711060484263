import { Machine, assign } from 'xstate'
import store from '@/store'
import { Document } from '@/domain/document'
import repositoryDocument from '@/domain/documentRepository'

export const documentCreateMachine = Machine<any>({
  id: 'documentCreate',
  initial: 'loading',
  context: {
    title: undefined,
    dueDateDate: undefined,
    dueDateTime: undefined,
    leadUserGroup: undefined,
    jointUserGroups: [],
    documentId: undefined,
    currentSpUrl: undefined,
    template: undefined,
    error: undefined
  },
  on: {
    SET_LOADING: {
      target: 'loading'
    },
    SET_TEMPLATE: {
      target: 'idle'
    }
  },
  states: {
    loading: {},
    idle: {
      // entry: 'setFormFields',
      on: {
        UPDATE_TITLE: {
          actions: 'updateTitle'
        },
        UPDATE_DUE_DATE_DATE: {
          actions: 'updateDueDateDate'
        },
        UPDATE_DUE_DATE_TIME: {
          actions: 'updateDueDateTime'
        },
        UPDATE_LEAD_USER_GROUP: {
          actions: 'updateLeadUserGroup'
        },
        UPDATE_JOINT_USER_GROUPS: {
          actions: 'updateJointUserGroups'
        },
        CREATE: {
          target: 'creating'
        }
      }
    },
    creating: {
      invoke: {
        id: 'createDocument',
        src: 'createDocument',
        onDone: {
          target: 'success',
          actions: 'setDocument'
        },
        onError: {
          target: 'failure',
          actions: 'setError'
        }
      }
    },
    success: {
      on: {
        VIEW: {
          target: 'idle',
          actions: 'openDocument'
        }
      }
    },
    failure: {
      on: {
        RETRY: {
          target: 'creating'
        },
        BACK: {
          actions: 'clearError',
          target: 'idle'
        }
      }
    }
  }
}, {
  services: {
    createDocument: async (context, event) => {
      const templateListItemId = +event.data.templateId
      const dueDate = (new Date(`${context.dueDateDate} ${context.dueDateTime}`))
      const document = await repositoryDocument.create(<Document>{
        title: context.title,
        dueDate,
        leadUserGroup: context.leadUserGroup,
        jointUserGroups: context.jointUserGroups,
        templateListItemId
      })
      store.commit('documents/addDocumentToCache', document)
      return document
    }
  },
  actions: {
    openDocument: (context) => {
      // TODO: this is currently blocked by browsers since it is not run inside event handler, for now open on click
      const win = window.open(context.currentSpUrl, '_blank')
      if (win) {
        win.focus()
      }
    },
    updateTitle: assign({
      title: (context, event: any) => event.payload
    }),
    updateDueDateDate: assign({
      dueDateDate: (context, event: any) => event.payload
    }),
    updateDueDateTime: assign({
      dueDateTime: (context, event: any) => event.payload
    }),
    updateLeadUserGroup: assign({
      leadUserGroup: (context, event: any) => event.payload
    }),
    updateJointUserGroups: assign({
      jointUserGroups: (context, event: any) => event.payload
    }),
    setError: assign((context, event: any) => {
      return {
        error: event.data
      }
    }),
    clearError: assign({
      error: undefined
    }),
    setDocument: assign((context, event: any) => {
      return {
        documentId: event.data.id,
        currentSpUrl: event.data.currentSpUrl
      }
    })
  },
  delays: {
    WAIT_DELAY: 20000
  }
})
