






















































import { defineComponent, reactive } from '@vue/composition-api'
import draggable from 'vuedraggable'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { mdiFileOutline } from '@mdi/js'

export default defineComponent({
  name: 'AgendaItemDraggable',
  props: {
    agendaItems: {
      required: true,
      type: Array
    },
    path: {
      type: Array
    },
    depth: Number
  },
  components: {
    draggable,
    BaseButton,
    BaseIcon
  },
  setup () {
    const dragOptions = reactive({
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      draggable: '.draggable-item',
      filter: '.ui-title-field',
      preventOnFilter: false
    })
    return {
      dragOptions,
      mdiFileOutline
    }
  }
})
