




























import { defineComponent, toRefs } from '@vue/composition-api'
import CardMini from '@/components/CardMini.vue'
import DocumentChipSquare from '@/components/DocumentChipSquare.vue'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { TrackingList } from '@/domain/trackingList'
import repository from '@/domain/trackingListRepository'

export default defineComponent({
  components: {
    CardMini,
    BaseButton,
    BaseIcon,
    DocumentChipSquare
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    trackingList: {
      type: Object,
      required: true
    }
  },
  setup (props, { root }) {
    const store = root.$store
    const { document, trackingList } = toRefs(props)
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          if (trackingList.value) {
            const trackingListClone: TrackingList = JSON.parse(JSON.stringify(trackingList.value))
            trackingListClone.documents = trackingListClone.documents?.filter(x => x.id !== document.value?.id)
            return repository.update(trackingListClone)
          }
          return Promise.reject(new Error('No tracking list available'))
        }
      },
      actions: {
        onDoneEnter: () => {
          store.commit('trackingLists/setForListingRemoveDocument', document.value?.id)
        }
      }
    })
    return {
      state,
      send
    }
  }
})

