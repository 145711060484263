





































import { computed, defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import CardMiniDocument from '@/components/CardMiniDocument.vue'
import draggable from 'vuedraggable'
import { TrackingList } from '@/domain/trackingList'
import { Document } from '@/domain/document'
import { AgendaItem } from '@/domain/agenda'
import repository from '@/domain/trackingListRepository'
import { getRandomInt } from '@/utils/random'

export default defineComponent({
  components: {
    CardMiniDocument,
    draggable
  },
  setup (_, { root }) {
    const store = root.$store
    const trackingLists = computed<TrackingList[]>(() => store.state.trackingLists.listing)
    const trackingList = computed<TrackingList>(() => store.getters['trackingLists/getListingSelected'])
    const trackingListSidebar = ref<any>(null)

    onMounted(() => {
      repository.findByCriteria({}).then(x => {
        store.commit('trackingLists/setForListing', x)
        store.commit('trackingLists/setForListingSelectedFirst', x)
        // When using keyboard navigation, the following takes the focus to the sidebar when it loads so there isn't
        // a need to tab through the whole page to reach the tracking list sidebar after opening it.
        setTimeout(() => trackingListSidebar.value.focus(), 100)
      })
    })
    const trackingListSetSelectedId = x => store.commit('trackingLists/setForListingSelectedId', x)
    const cloneDocumentAsAgendaItem = (document: Document): AgendaItem => {
      const id = getRandomInt(5000, 10000)
      return {
        id,
        title: document.title,
        document,
        children: []
      }
    }
    const dragOptions = reactive({
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost'
    })
    return {
      dragOptions,
      cloneDocumentAsAgendaItem,
      trackingListSetSelectedId,
      trackingLists,
      trackingList,
      trackingListSidebar
    }
  }
})
