import { DocumentTemplate } from '@/domain/documentTemplate'

export const DocumentTemplateMapper = ({
  deserialize: (json): DocumentTemplate => {
    return <DocumentTemplate>{
      id: Number(json['@id'].replace('/api/template_files/', '')),
      description: json.description,
      documentType: json.documentType,
      driveItemId: json.driveItemId,
      listItemId: json.listItemId,
      name: json.name
    }
  }
})
