






















































import { defineComponent, ref, reactive } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import { promiseMachine } from '@/machines/promiseMachine'
import { useMachine } from 'xstate-vue2'
import {
  BaseInputDatePicker,
  BaseInputTimePicker,
  BaseInputText
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { notEmpty } from '@/validations'
import { Agenda } from '@/domain/agenda'
import repository from '@/domain/agendaRepository'

export default defineComponent({
  props: {
    agendaTemplateId: {
      type: [String, Number]
    }
  },
  components: {
    GroupHeading,
    BaseInputDatePicker,
    BaseInputTimePicker,
    BaseInputText
  },
  setup (props, { root }) {
    const router = root.$router
    const form = reactive({
      title: undefined,
      date: undefined,
      time: undefined
    })
    const disablePastDates = (val: string) => val >= new Date().toISOString().substr(0, 10)
    const valid = ref(false)
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          const params: Agenda = {
            title: form.title,
            meetingDate: (new Date(`${form.date} ${form.time}`)),
            template: {
              id: Number(props.agendaTemplateId)
            }
          }
          return repository.create(params)
        }
      },
      actions: {
        onDoneEnter: (context, event) => {
          if (event.data.template.agendaType.packageType === 'document-bundle') {
            router.push({ name: 'AgendasManageDetail', params: { id: `${event.data.id}` }, query: { packageType: 'document-bundle' } })
          } else {
            router.push({ name: 'AgendasManageDetail', params: { id: `${event.data.id}` }, query: { packageType: 'agenda' } })
          }
        }
      }
    })
    return {
      form,
      disablePastDates,
      state,
      send,
      valid,
      notEmpty
    }
  }
})
