

























import { defineComponent, ref, watch } from '@vue/composition-api'
import ChipBoolean from '@/components/ChipBoolean.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DocumentOwner from '@/components/DocumentOwner.vue'
import GroupHeading from '@/components/GroupHeading.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import DocumentLeadGroup from '@/components/DocumentLeadGroup.vue'
import DocumentJointGroupsView from '@/components/DocumentJointGroupsView.vue'
import {
  BaseIcon,
  BaseIconText,
  BaseAlert
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: {
    document: Object,
    state: Object,
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    BaseAlert,
    GroupHeading,
    DocumentStatusChip,
    BaseIcon,
    BaseIconText,
    DocumentOwner,
    DocumentStatus,
    DocumentLeadGroup,
    DocumentJointGroupsView,
    ChipBoolean,
    ChipBlock,
    ChipUser
  },
  setup (props) {
    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the details tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 0) {
        focusOnContent()
      }
    })
    return {
      tabContent
    }
  }
})
