



























































































































import { defineComponent, computed } from '@vue/composition-api'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  name: 'comment-card',
  props: {
    comment: {
      type: Object,
      required: true
    },
    document: {
      type: Object,
      required: true
    },
    isReply: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CommentCard: () => import('./CommentCard.vue'),
    BaseButton,
    BaseIcon
  },
  setup (props) {
    // Check if user can edit comment
    const { me, selectText } = useUser()

    const canEdit = computed(() => {
      let result = false
      if (me.value && props.comment && (me.value.id === props.comment.user.id || me.value.id === props.comment.user.id || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    const canResolve = computed(() => {
      let result = false
      if (me.value && props.document && ((props.document.documentManager && me.value.id === props.document.documentManager.id) || (props.document.documentOwner && me.value.id === props.document.documentOwner.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    const hasReplies = computed(() => {
      return props.comment.replies !== undefined && props.comment.replies.length > 0
    })

    return { canEdit, canResolve, selectText, hasReplies }
  }
})
