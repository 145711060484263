


















































import { defineComponent, PropType, toRefs, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import {
  BaseIcon,
  BaseIconText,
  BaseAlert,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import BaseAutocomplete from '@/components/forms/BaseAutocomplete.vue'
import repositoryUserGroup from '@/domain/userGroupRepository'
import { UserGroup } from '@/domain/userGroup'

export default defineComponent({
  props: {
    title: String,
    isViewing: Boolean,
    isSaving: Boolean,
    isEditing: Boolean,
    field: Array as PropType<UserGroup[]>,
    formValue: Array as PropType<UserGroup[]>
  },
  components: {
    GroupHeading,
    BaseIcon,
    BaseIconText,
    ChipBlock,
    ChipUser,
    BaseAlert,
    BaseButton,
    BaseAutocomplete
  },
  setup (props, { emit }) {
    const searchPromise = q => repositoryUserGroup.findByCriteria({ q })
    const { isEditing, field } = toRefs(props)
    watch(() => isEditing?.value, () => {
      if (isEditing?.value && field?.value) {
        const args = JSON.parse(JSON.stringify(field.value))
        emit('formChange', args)
      }
    }, {
      immediate: true
    })
    return {
      searchPromise
    }
  }
})
