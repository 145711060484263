















import { defineComponent, ref, inject } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { useService } from 'xstate-vue2'
import { Interpreter } from 'xstate'
import { DocumentContext } from '@/machines/documentMachine'
import { isObject } from '@/validations'
import repository from '@/domain/userGroupRepository'

export default defineComponent({
  components: {
    BaseCombobox
  },
  setup (_, { root }) {
    const radioGroup = ref(1)
    const service = inject('documentMachineService') as Interpreter<DocumentContext, any, any, any>
    const { state, send } = useService(service)
    const searchPromise = q => repository.findByCriteria({ q })
    const itemText = x => x.title

    return {
      radioGroup,
      state,
      send,
      searchPromise,
      itemText,
      isObject
    }
  }
})
