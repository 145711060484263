












































import { computed, defineComponent } from '@vue/composition-api'
import { mdiBookmarkPlus, mdiPlus } from '@mdi/js'
import ButtonAction from '@/components/ButtonAction.vue'
import FormBookmarkDelete from '@/components/forms/FormBookmarkDelete.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import repositoryBookmark from '@/domain/bookmarkRepository'
import { Bookmark } from '@/domain/bookmark'
import { titlePath } from '@/utils/title'

export default defineComponent({
  components: {
    ButtonAction,
    FormBookmarkDelete
  },
  setup (_, { root }) {
    const store = root.$store
    const router = root.$router
    // Load Bookmarks
    const loadBookmarks = () => {
      repositoryBookmark.findByCriteria({}).then(x => {
        store.commit('bookmarks/setForAppBar', x)
      })
    }
    // Create Bookmark
    const { state: stateCreate, send: sendCreate } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          const title = router.currentRoute.meta?.title ?? titlePath(router.currentRoute.fullPath)
          const url = router.currentRoute.fullPath
          return repositoryBookmark.create({
            title,
            url
          })
        }
      },
      actions: {
        onDoneEnter: (_, event) => {
          const bookmark: Bookmark = event.data
          store.commit('bookmarks/addToAppBar', bookmark)
        }
      },
      delays: {
        SUCCESS_DELAY: 500
      }
    })
    // Current Bookmarks
    const bookmarks = computed(() => store.state.bookmarks.appBar)
    // Load
    loadBookmarks()
    return {
      stateCreate,
      sendCreate,
      bookmarks,
      mdiBookmarkPlus,
      mdiPlus
    }
  }
})
