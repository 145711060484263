import { format, getDate, getMonth, getYear, set, getHours, getMinutes } from 'date-fns'

export default function formatDate (value: Date) {
  return value ? format(value, 'MMMM d, yyyy') : ''
}

export function formatDateTime (value: Date) {
  return value ? format(value, 'MMMM d - h:mm aaa') : ''
}

export function formatDateShort (value: Date) {
  return value ? format(value, 'MM/dd/yyyy') : ''
}

export function formatDatePicker (value: Date) {
  return value ? format(value, 'yyyy-MM-dd') : ''
}

export const setDatePart = (dateOrig: Date, dateChanged: Date) => {
  const year = getYear(dateChanged)
  const month = getMonth(dateChanged)
  const date = getDate(dateChanged)
  return set(dateOrig, {
    year,
    month,
    date
  })
}

export const setTimePart = (dateOrig: Date, dateChanged: Date) => {
  const hours = getHours(dateChanged)
  const minutes = getMinutes(dateChanged)
  return set(dateOrig, {
    hours,
    minutes
  })
}
