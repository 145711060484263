import { Machine, assign } from 'xstate'
import router from '@/router/index'

export type MachineEvent = {
  type: string;
  data?: any;
  payload?: any;
}

export const sidebarMachine = Machine<any>({
  id: 'sidebar',
  initial: 'boot',
  context: {
    showNotifications: false,
    showTrackingLists: false,
    showDocument: false,
    showTemplate: false,
    documentId: undefined,
    documentTab: 0,
    agendaId: undefined
  },
  states: {
    boot: {
      entry: ['log'],
      always: [
        { target: 'template', cond: 'isTemplateRoute' },
        { target: 'closed' }
      ]
    },
    closed: {
      entry: ['log']
    },
    notifications: {
      entry: ['log', 'showNotifications'],
      on: {
        TOGGLE_NOTIFICATIONS: {
          target: 'closed'
        }
      },
      exit: 'hideNotifications'
    },
    tracking_lists: {
      entry: ['log', 'showTrackingLists'],
      on: {
        TOGGLE_TRACKING_LISTS: {
          target: 'closed'
        }
      },
      exit: 'hideTrackingLists'
    },
    agenda: {
      entry: ['log', 'showAgenda'],
      exit: 'hideAgenda'
    },
    document: {
      entry: ['log', 'showDocument'],
      on: {
        LEAVE_HOME: {
          target: 'closed'
        },
        LEAVE_DOCUMENT_DETAILS: {
          target: 'closed'
        },
        TAB_CHANGE: {
          actions: ['log', 'setTab']
        }
      },
      initial: 'details',
      states: {
        details: {},
        comments: {},
        attachments: {}
      },
      exit: 'hideDocument'
    },
    template: {
      entry: ['log', 'showTemplate'],
      on: {
        TOGGLE_NOTIFICATIONS: {
          target: 'notifications', actions: 'redirectToTemplateList'
        },
        CLOSE: {
          target: 'closed', actions: 'redirectToTemplateList'
        },
        LEAVE_DETAIL: {
          target: 'closed'
        }
      },
      exit: ['hideTemplate']
    }
  },
  on: {
    CLOSE: {
      target: 'closed'
    },
    AGENDA_VIEW: {
      target: 'agenda'
    },
    DOCUMENT_VIEW: {
      target: 'document',
      actions: ['log', 'setTabDetails']
    },
    DOCUMENT_COMMENTS: {
      target: 'document.comments',
      actions: ['log', 'setTabComments']
    },
    TEMPLATE_VIEW: {
      target: 'template'
    },
    TOGGLE_TRACKING_LISTS: {
      target: 'tracking_lists',
      actions: ['log']
    },
    TOGGLE_NOTIFICATIONS: {
      target: 'notifications'
    }
  }
}, {
  guards: {
    isTemplateRoute: () => {
      if (router) {
        return router.currentRoute.name === 'DocumentTemplateDetail' || router.currentRoute.name === 'AgendaTemplateDetail'
      }
      return false
    }
  },
  actions: {
    redirectToTemplateList: () => {
      if (router.currentRoute.name === 'DocumentTemplateDetail') {
        router.push({ name: 'DocumentTemplateList' })
      } else if (router.currentRoute.name === 'AgendaTemplateDetail') {
        router.push({ name: 'AgendaTemplateList', query: router.currentRoute.query })
      }
    },
    log: (context, event) => {
      console.log('sidebarMachineEvent', event.type, event.payload)
    },
    setTab: assign({
      documentTab: (context, event) => {
        return event.payload.tabIndex
      }
    }),
    setTabComments: assign({
      documentTab: 1
    }),
    setTabDetails: assign({
      documentTab: 0
    }),
    showNotifications: assign({
      showNotifications: true
    }),
    hideNotifications: assign({
      showNotifications: false
    }),
    showTrackingLists: assign({
      showTrackingLists: true
    }),
    hideTrackingLists: assign({
      showTrackingLists: false
    }),
    showAgenda: assign({
      showAgenda: true,
      agendaId: (context, event) => (
        event.payload.id
      )
    }),
    hideAgenda: assign({
      showAgenda: false,
      agendaId: undefined
    }),
    showDocument: assign({
      showDocument: true,
      documentId: (context, event) => (
        event.payload.document.id
      )
    }),
    hideDocument: assign({
      showDocument: false,
      documentId: undefined
    }),
    showTemplate: assign({
      showTemplate: true
    }),
    hideTemplate: assign({
      showTemplate: false
    })
  }
})
