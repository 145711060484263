





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: ['state', 'send', 'route', 'type', 'addText']
})
