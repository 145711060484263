var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"fixed-header":"","headers":_vm.headers,"items":_vm.steps,"height":_vm.tableHeight,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('ChipBlock',{staticClass:"mb-1",attrs:{"color":"tertiaryGrey","text-color":"secondaryGrey"},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('ChipUser',{attrs:{"user":item.user}})]},proxy:true}],null,true)})]}},{key:"item.hasComment",fn:function(ref){
var item = ref.item;
return [_c('BaseIcon',{attrs:{"iconName":item.hasComment ? 'mdiCommentCheck' : 'mdiCommentMinus',"color":item.hasComment ? 'secondaryBlue' : '#bfbfbf',"size":"1.5rem"}})]}},{key:"item.approved",fn:function(ref){
var item = ref.item;
return [_c('BaseIcon',{attrs:{"iconName":item.approved ? 'mdiCheckboxMarked' : 'mdiMinusBox',"color":item.approved ? 'secondaryGreen' : '#bfbfbf',"size":"1.5rem"}})]}},{key:"item.dateApproved",fn:function(ref){
var item = ref.item;
return [(item.dateApproved && item.approved)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDateShort")(new Date(item.dateApproved)))+" ")]):_c('span',[_vm._v("-")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }