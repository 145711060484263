var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ListingTitle',{attrs:{"type":_vm.packageTypeName}}),_c('v-sheet',{staticClass:"mb-5 pa-5",attrs:{"elevation":"1"}},[_c('v-row',[_c('v-col',[_c('ListingFormSearch',{attrs:{"state":_vm.state,"send":_vm.send}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('BaseInputSelect',{attrs:{"label":"Status","clearable":"","dense":"","hide-details":"","multiple":"","persistentHint":false,"singleLine":false,"value":_vm.state.context.searchStatuses,"items":_vm.agendaStatuses},on:{"change":function($event){return _vm.handleStatusSelect($event)}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('BaseInputSelect',{attrs:{"label":"Type","clearable":"","dense":"","multiple":"","hide-details":"","persistentHint":false,"returnObject":false,"singleLine":false,"value":_vm.state.context.searchTypes,"items":_vm.agendaTypes},on:{"change":function($event){return _vm.send({ type: 'CHANGE_PROPERTY', key: 'searchTypes', value: $event })}}})],1)],1)],1),_c('ListingTableMeta',{attrs:{"state":_vm.state,"send":_vm.send,"route":{ name: 'AgendaTemplateList', query: { packageType: _vm.packageType }},"type":_vm.packageTypeName}}),_c('ListingTable',{attrs:{"state":_vm.state,"send":_vm.send,"handleRowClick":_vm.handleRowClick,"headers":[
    { text: 'Status', value: 'status' },
    { text: 'Title', value: 'title' },
    { text: 'Type', value: 'template.agendaType.title' },
    { text: 'Meeting Date', value: 'meetingDate' },
    { text: 'Active', value: 'isActive', align: 'center' },
    { text: 'Actions', value: 'actions', width: '120px', sortable: false }
  ]},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
  var item = ref.item;
return [_c('AgendaStatus',{staticClass:"ui-chip",attrs:{"status":item.status}})]}},{key:"item.meetingDate",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDateShort")(item.meetingDate)))])]}},{key:"item.isActive",fn:function(ref){
  var item = ref.item;
return [_c('ChipBoolean',{attrs:{"status":item.isActive}})]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('BaseIcon',{attrs:{"iconName":"mdiDotsVertical","dark":""}})],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'AgendasManageDetail', params: { id: ("" + (item.id)) }, query: { packageType: _vm.packageType } }}},[_c('v-list-item-title',[_vm._v("Edit Agenda")])],1)],1)],1),_c('BaseIcon',{staticClass:"ds-list-item__highlight-icon",attrs:{"iconName":"mdiChevronRight","dark":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }