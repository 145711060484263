


































import { defineComponent, ref, reactive } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import { promiseMachine } from '@/machines/promiseMachine'
import { useMachine } from 'xstate-vue2'
import {
  BaseInputText
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { notEmpty } from '@/validations'
import repository from '@/domain/documentRepository'

export default defineComponent({
  props: ['item'],
  components: {
    GroupHeading,
    BaseInputText
  },
  setup (props, { root }) {
    const store = root.$store
    const formElement = ref<any>(null)
    const form = reactive({
      title: ''
    })
    const valid = ref(false)
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: async () => {
          const document = await repository.decisionCreate(props.item, form.title)
          store.commit('documents/updateDocumentInCache', document)
        }
      },
      actions: {
        onDoneEnter: () => {
          form.title = ''
          formElement.value.reset()
          formElement.value.resetValidation()
        }
      }
    })
    return {
      form,
      formElement,
      state,
      send,
      valid,
      notEmpty
    }
  }
})
