import { MediaBlob } from '@/domain/media'

interface ModuleState {
  mediaBlob?: MediaBlob
}

// state
const state = () => <ModuleState>({
  mediaBlob: undefined
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  addMediaBlobToCache (state, value) {
    state.mediaBlob = value
  },
  removeMediaBlobFromCache (state) {
    state.mediaBlob = undefined
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
