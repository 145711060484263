





















































































































import { computed, watch, defineComponent, inject, ref } from '@vue/composition-api'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import { BaseIcon, BaseInputText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import repository from '@/domain/trackingListRepository'
import { Document } from '@/domain/document'
import FormTrackingListAddDocument from '@/components/forms/FormTrackingListAddDocument.vue'
import { sidebarMachineSendKey } from '@/router/views/AppOutlet.vue'
import { useMachine } from 'xstate-vue2'
import { trackingListMachine } from '@/machines/trackingListMachine'

export default defineComponent({
  props: ['id'],
  components: {
    FormTrackingListAddDocument,
    DocumentStatusChip,
    BaseIcon,
    DialogConfirm,
    BaseInputText
  },
  setup (props, { root }: any) {
    const store = root.$store
    const { state, send } = useMachine(trackingListMachine)
    const detail = computed(() => store.state.trackingLists.detail)
    watch(() => props.id, () => {
      repository.findById(props.id).then(trackingList => {
        store.commit('trackingLists/setForDetail', trackingList)
        // Initially set the tracking list in the machine
        send({ type: 'SET_LIST', value: trackingList })
      })
    }, {
      immediate: true
    })
    const sendSidebar = inject(sidebarMachineSendKey)

    const confirming = ref<boolean>(false)
    const dialogDocument = ref<boolean>(false)
    const currentlySelectedDocument = ref<Document>({ id: -1 })

    const handleDelete = (document) => {
      currentlySelectedDocument.value = document
      confirming.value = true
    }
    const handleRowClick = (document) => {
      if (sendSidebar) {
        sendSidebar({ type: 'DOCUMENT_VIEW', payload: { document } })
      }
    }

    const addDocumentToTrackingList = (document) => {
      // Verify document doesn't already exist
      if (detail.value.documents.filter(d => d.id === document.id).length === 0) {
        detail.value.documents.push(document)
        repository.update(detail.value)
        dialogDocument.value = false
      }
    }

    const handleDeleteConfirmation = () => {
      detail.value.documents = detail.value.documents.filter(d => d.id !== currentlySelectedDocument.value.id)
      repository.update(detail.value)
      confirming.value = false
    }

    return {
      handleRowClick,
      addDocumentToTrackingList,
      handleDelete,
      handleDeleteConfirmation,
      detail,
      dialogDocument,
      confirming,
      state,
      send
    }
  }
})
