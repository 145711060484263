




















































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import DocumentLeadGroup from '@/components/DocumentLeadGroup.vue'
import DocumentJointGroupsView from '@/components/DocumentJointGroupsView.vue'
import {
  BaseIcon,
  BaseIconText,
  BaseAlert,
  BaseInputDatePicker,
  BaseInputTimePicker,
  BaseInputText,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DocumentOwner from '@/components/DocumentOwner.vue'
import FormInputRequiredCollaborators from '@/components/forms/FormInputRequiredCollaborators.vue'
import { minLength } from '@/validations'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { useUser } from '@/composables/useUser'
import repository from '@/domain/userRepository'

export default defineComponent({
  props: {
    document: Object,
    state: Object,
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    ChipBlock,
    ChipUser,
    BaseAlert,
    GroupHeading,
    DocumentStatusChip,
    BaseIcon,
    DocumentOwner,
    DocumentStatus,
    BaseIconText,
    DocumentLeadGroup,
    DocumentJointGroupsView,
    BaseInputDatePicker,
    FormInputRequiredCollaborators,
    BaseInputTimePicker,
    BaseInputText,
    BaseButton,
    BaseCombobox
  },
  setup (props, { root, emit }) {
    const disablePastDates = (val: string) => val >= new Date().toISOString().substr(0, 10)
    const collaboratorAdditionalSelected = ref(null)
    const collaboratorRequiredIds = computed(() => props?.state?.context?.workflowStepsRequired.map(x => x.user.id))
    const collaboratorAdditionalIds = computed(() => props?.state?.context?.workflowStepsAdditional.map(x => x.user.id))
    const searchPromise = async c => {
      let results = await repository.findByCriteria({ c })
      // Filter required collaborators
      results = results.filter(x => !collaboratorRequiredIds.value.includes(x.id))
      // Filter additional collaborators
      results = results.filter(x => !collaboratorAdditionalIds.value.includes(x.id))
      return results
    }

    const { selectText } = useUser()
    const itemText = x => selectText(x)

    const collaboratorAdditionalAppendWorkflowStep = () => {
      const workflowStep = {
        user: collaboratorAdditionalSelected.value
      }
      emit('workflowStepAdditionalAdd', workflowStep)
      collaboratorAdditionalSelected.value = null
    }

    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the details tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 0) {
        focusOnContent()
      }
    })

    return {
      itemText,
      searchPromise,
      collaboratorAdditionalSelected,
      collaboratorAdditionalAppendWorkflowStep,
      minLength,
      disablePastDates,
      tabContent
    }
  }
})
