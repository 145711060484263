import { DocumentAttachmentApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData } from '@/domain/interfaces'
import { DocumentAttachment } from '@/domain/documentAttachment'
import { DocumentAttachmentMapper } from '@/domain/documentAttachmentMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new DocumentAttachmentApi(undefined, BASE_PATH, axios)

class DocumentAttachmentRepository implements ICrudData<DocumentAttachment>, IFindData<DocumentAttachment> {
  async findById (id) {
    try {
      const response = await api.getDocumentAttachmentItem(id)
      return DocumentAttachmentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: DocumentAttachment) {
    try {
      const params = DocumentAttachmentMapper.serialize(obj)
      const response = await api.postDocumentAttachmentCollection(params)
      return DocumentAttachmentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: DocumentAttachment) {
    try {
      const params = DocumentAttachmentMapper.serialize(obj)
      const response = await api.patchDocumentAttachmentItem(String(obj.id), params)
      return DocumentAttachmentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteDocumentAttachmentItem(String(id))
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new DocumentAttachmentRepository()
