









import { defineComponent, computed } from '@vue/composition-api'
import { BaseChipStatus } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import statusColor from '@/utils/helpers/statusColor'

export default defineComponent({
  name: 'document-status-chip',
  components: {
    BaseChipStatus
  },
  props: {
    status: {
      type: String,
      default: 'draft'
    },
    requiresMyInput: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const statusValue = computed(() => {
      if (props.requiresMyInput && props.status.includes('collaboration')) {
        return 'collaboration-required'
      } else if (props.requiresMyInput && props.status.includes('approval')) {
        return 'approval-required'
      }

      return props.status
    })

    return {
      statusColor,
      statusValue
    }
  }
})
