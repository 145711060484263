




import { defineComponent, computed, toRefs } from '@vue/composition-api'
import ChipSquare from '@/components/ChipSquare.vue'

export default defineComponent({
  name: 'document-chip-square',
  components: {
    ChipSquare
  },
  props: ['document'],
  setup (props) {
    const { document } = toRefs(props)
    const color = computed(() => {
      if (document?.value?.status.includes('approval')) {
        return 'secondaryRed'
      } else if (document?.value?.status.includes('collaboration')) {
        return 'secondaryBlue'
      } else if (document?.value?.status.includes('draft')) {
        return 'secondaryOrange'
      } else if (document?.value?.status.includes('approved')) {
        return 'secondaryGreen'
      }
      return 'secondaryGrey'
    })
    const iconName = computed(() => {
      if (['approved', 'archived', 'completed', 'legacy-completed'].includes(document?.value?.status)) {
        return 'mdiCheck'
      }
      return 'mdiMinus'
    })

    return {
      color,
      iconName
    }
  }
})
