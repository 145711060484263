import { Machine, assign } from 'xstate'
import store from '@/store'
import repositoryMedia from '@/domain/mediaRepository'
import repositoryDocument from '@/domain/documentRepository'
import repositoryDocumentAttachment from '@/domain/documentAttachmentRepository'
import { useMedia } from '@/composables/useMedia'

export const attachmentMachine = Machine<any>({
  id: 'attachmentMachine',
  initial: 'viewing',
  context: {
    currentDocument: undefined,
    currentAttachments: [],
    attachment: undefined,
    title: undefined,
    loadAttachmentId: undefined,
    removeAttachmentId: undefined,
    error: undefined
  },
  on: {
    UPDATE_CURRENT_ATTACHMENTS: {
      actions: ['log', 'updateCurrentAttachments']
    }
  },
  states: {
    viewing: {
      id: 'viewing',
      on: {
        UPDATE_CURRENT_DOCUMENT: {
          actions: ['log', 'updateCurrentDocument', 'clearContext']
        },
        UPDATE_TITLE: {
          actions: ['log', 'updateTitle', 'clearError']
        },
        UPDATE_ATTACHMENT: {
          actions: ['log', 'updateAttachment', 'clearError']
        },
        LOAD_ATTACHMENT: {
          actions: ['log', 'updateLoadAttachmentId'],
          target: 'invoking'
        },
        REMOVE_ATTACHMENT: {
          actions: ['log', 'updateRemoveAttachmentId'],
          target: 'invoking'
        },
        SUBMIT: {
          actions: 'log',
          target: 'invoking'
        }
      }
    },
    invoking: {
      initial: 'init',
      states: {
        init: {
          always: [{
            target: '#invoking.removeAttachment',
            cond: 'isRemoving'
          }, {
            target: '#invoking.loadAttachment',
            cond: 'isLoading'
          }, {
            target: '#invoking.addAttachment',
            cond: 'isAddingAttachment'
          }]
        },
        removeAttachment: {
          entry: ['log'],
          id: 'invoking.removeAttachment',
          invoke: {
            src: 'removeAttachment',
            onDone: {
              actions: 'clearContext',
              target: '#viewing'
            },
            onError: {
              actions: 'setError',
              target: '#viewing'
            }
          }
        },
        loadAttachment: {
          entry: ['log'],
          id: 'invoking.loadAttachment',
          invoke: {
            src: 'loadAttachment',
            onDone: {
              actions: 'clearContext',
              target: '#viewing'
            },
            onError: {
              actions: 'setError',
              target: '#viewing'
            }
          }
        },
        addAttachment: {
          entry: ['log'],
          id: 'invoking.addAttachment',
          invoke: {
            src: 'addAttachment',
            onDone: {
              actions: 'clearContext',
              target: '#viewing'
            },
            onError: {
              actions: 'setError',
              target: '#viewing'
            }
          }
        }
      }
    }
  }
}, {
  services: {
    addAttachment: async (context, event) => {
      const newAttachment = await repositoryMedia.create({
        file: context.attachment[0],
        title: context.title && context.title.trim().length > 0 ? context.title : undefined
      })
      const maxPosition = context.currentAttachments.reduce((acc, val) => {
        if (val.position > acc) {
          return val.position
        }
        return acc
      }, -1)
      await repositoryDocumentAttachment.create({
        document: context.currentDocument,
        attachment: newAttachment,
        position: maxPosition
      })
      // Get updated document
      const document = await repositoryDocument.findById(context.currentDocument.id)
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    removeAttachment: async (context, event) => {
      await repositoryDocumentAttachment.delete(context.removeAttachmentId)
      // Get updated document
      const document = await repositoryDocument.findById(context.currentDocument.id)
      store.commit('documents/updateDocumentInCache', document)
      return document
    },
    loadAttachment: async (_, event) => {
      const { findMediaBlob, mediaBlob } = useMedia()
      findMediaBlob(event.payload)

      return mediaBlob
    }
  },
  actions: {
    updateTitle: assign({
      title: (_, event: any) => event.payload
    }),
    updateAttachment: assign({
      attachment: (_, event: any) => {
        return event.payload
      }
    }),
    updateLoadAttachmentId: assign({
      loadAttachmentId: (_, event: any) => {
        return event.payload
      }
    }),
    updateRemoveAttachmentId: assign({
      removeAttachmentId: (_, event: any) => event.payload
    }),
    updateCurrentDocument: assign({
      currentDocument: (_, event: any) => event.payload
    }),
    updateCurrentAttachments: assign({
      currentAttachments: (_, event: any) => event.payload
    }),
    clearContext: assign({
      attachment: undefined,
      title: undefined,
      loadAttachmentId: undefined,
      removeAttachmentId: undefined
    }),
    clearError: assign({
      error: undefined
    }),
    setError: assign({
      error: (_, event: any) => event.data
    }),
    log: (_, event) => {
      console.log('attachmentMachine', event.type, event.value)
    }
  },
  guards: {
    isRemoving: (context) => !!context.removeAttachmentId,
    isLoading: (context) => !!context.loadAttachmentId,
    isAddingAttachment: (context) => !context.removeAttachmentId && context.attachment && context.attachment.length
  }
})
