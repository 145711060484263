import { assign, Machine } from 'xstate'

type MachineEvent = {
  type: string;
  value: any;
  key?: string;
}

interface MachineSchema {
  states: {
    viewing: {
      states: {
        idle: {};
        error: {};
        success: {};
        confirming: {};
        completing: {};
      }
    };
    editing: {
      states: {
        idle: {};
        saving: {};
      }
    };
  };
}

export interface MachineContext {
  form?: any;
  error?: string;
}

export const viewEditConfirmMachine = Machine<MachineContext, MachineSchema, MachineEvent>({
  id: 'viewEditConfirmMachine',
  initial: 'viewing',
  context: {
    form: undefined,
    error: undefined
  },
  on: {
    SET_FORM: {
      actions: ['setForm']
    }
  },
  states: {
    viewing: {
      id: 'viewing',
      initial: 'idle',
      on: {
        TOGGLE_MODE: {
          target: '#editing'
        },
        COMPLETE: {
          target: '#viewing.confirming'
        }
      },
      states: {
        idle: {},
        success: {
          after: {
            SUCCESS_DELAY: '#viewing'
          }
        },
        error: {
          exit: 'clearError'
        },
        confirming: {
          id: 'confirming',
          on: {
            YES: '#viewing.completing',
            NO: '#viewing'
          }
        },
        completing: {
          id: 'completing',
          invoke: {
            src: 'completing',
            onDone: {
              target: '#viewing.success'
            },
            onError: {
              target: '#viewing.error',
              actions: 'setError'
            }
          }
        }
      }
    },
    editing: {
      id: 'editing',
      initial: 'idle',
      on: {
        TOGGLE_MODE: {
          target: '#viewing'
        },
        SAVE: [{
          target: '#editing.saving'
        }]
      },
      states: {
        idle: {},
        saving: {
          invoke: {
            src: 'saving',
            onDone: {
              target: '#viewing.success'
            },
            onError: {
              target: '#viewing.error',
              actions: 'setError'
            }
          }
        }
      }
    }
  }
}, {
  actions: {
    setForm: assign<MachineContext, MachineEvent>({
      form: (context, event) => event.value
    }),
    setError: assign<MachineContext, any>((context, event) => {
      return {
        error: event.data
      }
    }),
    clearError: assign<MachineContext, any>({
      error: undefined
    })
  },
  services: {
    completing: async (context, event) => {
      return Promise.reject(new Error('No service defined'))
    },
    saving: async (context, event) => {
      return Promise.reject(new Error('No service defined'))
    }
  },
  delays: {
    SUCCESS_DELAY: 5000
  }
})
