import axios from 'axios'
import emitter from '@/utils/emitter'

// Load headers
if (window.localStorage.getItem('apiToken')) {
  axios.defaults.headers.common.Authorization = `bearer ${window.localStorage.getItem('apiToken')}`
}
if (window.localStorage.getItem('ssoUserImpersonation')) {
  axios.defaults.headers.common['HTTP-X-SWITCH-USER'] = window.localStorage.getItem('ssoUserImpersonation')
}

/**
 * Retry helper used to wait a given amount of milliseconds before continuing
 * @param milliseconds
 * @param originalRequest
 */
const waitRequest = (milliseconds, originalRequest) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(axios.request(originalRequest)), milliseconds)
  })
}

/**
 * Retry logic used to acquire JWT when requests fail.  Current logic only allows for 1 retry.
 */
axios.interceptors.response.use(response => response, async (error) => {
  const isNetworkError = error.isAxiosError && error.message === 'Network Error'
  const originalRequest = error.config
  const isUnauthorized = error.response && error.response.status === 401
  const isRestrictedUser = error.response && error.response.status === 403 && error.response.data && error.response.data.detail && error.response.data.detail === 'Your account is inactive'
  originalRequest._retryCount = originalRequest._retryCount || 0
  // matches 'Invalid JWT Token' or 'JWT Token not found'
  const isInvalidJWT = error?.response?.data?.message?.includes('JWT')
  if (!isNetworkError && isUnauthorized && isInvalidJWT) {
    if (originalRequest._retryCount < 5) {
      originalRequest._retryCount++
      try {
        emitter.emit('API_401')
        // Exponential backoff for retries, 200, 400, 800, etc.
        const retryWaitMs = Math.pow(2, originalRequest._retryCount + 1) * 100
        originalRequest.headers.Authorization = `bearer ${window.localStorage.getItem('apiToken')}`
        return waitRequest(retryWaitMs, originalRequest)
      } catch (err) {
        return Promise.reject(err)
      }
    }
  } else if (isRestrictedUser) {
    emitter.emit('API_403')
  }
  return Promise.reject(error)
})

export default axios
