




































import { defineComponent, toRefs, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import AgendaStatus from '@/components/AgendaStatus.vue'
import { useAgendas } from '@/composables/useAgendas'
import { BaseAlert, BaseChipStatus } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import FormAgendaDeleteButton from '@/components/forms/FormAgendaDeleteButton.vue'

export default defineComponent({
  props: {
    agendaId: {
      required: true,
      type: Number
    }
  },
  components: {
    GroupHeading,
    BaseAlert,
    BaseChipStatus,
    AgendaStatus,
    FormAgendaDeleteButton
  },
  setup (props) {
    const { agendaId } = toRefs(props)
    const { agendaForSidebar: agenda, findForSidebar } = useAgendas()
    watch(agendaId, () => findForSidebar(agendaId.value), {
      immediate: true
    })
    return {
      agenda
    }
  }
})
