import { UserSettingsApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData } from '@/domain/interfaces'
import { UserSettingsMapper } from '@/domain/userSettingMapper'
import { UserSettings } from '@/domain/userSetting'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new UserSettingsApi(undefined, BASE_PATH, axios)

class UserSettingRepository implements IFindData<UserSettings>, ICrudData<UserSettings> {
  async findById (id) {
    try {
      const response = await api.getUserSettingsItem(id)
      return UserSettingsMapper.deserialize(response.data)
    } catch (err) {
      if (err.response?.data) {
        throw new RepositoryError(err)
      } else {
        throw Error(err)
      }
    }
  }

  async update (obj: UserSettings) {
    try {
      const response = await api.patchUserSettingsItem(String(obj.id), UserSettingsMapper.serializePatch(obj))
      return UserSettingsMapper.deserialize(response.data)
    } catch (err) {
      if (err.response?.data) {
        throw new RepositoryError(err)
      } else {
        throw Error(err)
      }
    }
  }
}

export default new UserSettingRepository()
