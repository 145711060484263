import { render, staticRenderFns } from "./AddinDetailsCollaborationInProgress.vue?vue&type=template&id=c2ebd8ee&scoped=true&"
import script from "./AddinDetailsCollaborationInProgress.vue?vue&type=script&lang=ts&"
export * from "./AddinDetailsCollaborationInProgress.vue?vue&type=script&lang=ts&"
import style0 from "./AddinDetailsCollaborationInProgress.vue?vue&type=style&index=0&id=c2ebd8ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2ebd8ee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
