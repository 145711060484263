















































import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { BaseInputText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { useUserSettings } from '@/composables/useUserSettings'
import { useUser } from '@/composables/useUser'
import { notEmpty } from '@/validations'
import { UserSettings } from '@/domain/userSetting'
import repository from '@/domain/userSettingRepository'
import { UserSettingsUserSettingsWritePreferredDashboardFilterEnum } from '@zucommunications/gsk-docshare-web-api/api'

export default defineComponent({
  components: {
    BaseCombobox,
    BaseInputText
  },
  setup () {
    const { findByCriteria, selectText } = useUser()
    const { findForDetail, detail } = useUserSettings()
    const valid = ref(true)
    const defaultFilter = 'current'
    const radioGroup = ref(defaultFilter)
    const form = reactive<UserSettings>({
      id: undefined
    })
    watch(detail, () => {
      if (detail.value) {
        const params: UserSettings = JSON.parse(JSON.stringify(detail.value))
        radioGroup.value = params.preferredDashboardFilter ?? defaultFilter
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          form.preferredDashboardFilter = radioGroup.value as UserSettingsUserSettingsWritePreferredDashboardFilterEnum
          return repository.update(JSON.parse(JSON.stringify(form)))
        }
      },
      actions: {
        onDoneEnter: () => findForDetail('me')
      }
    })
    return {
      radioGroup,
      valid,
      selectText,
      findByCriteria,
      notEmpty,
      state,
      send,
      form
    }
  }
})
