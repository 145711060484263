

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    icon: String,
    text: String,
    loading: Boolean
  }
})
