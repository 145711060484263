import { Machine } from 'xstate'
import store from '@/store'

export const notificationMachine = Machine<any, any, any>({
  id: 'notification',
  initial: 'idle',
  states: {
    idle: {},
    archiving: {
      invoke: {
        src: 'archiveService',
        onDone: 'archived',
        onError: 'archivingFailure'
      }
    },
    archived: {
      // final state
      type: 'final'
    },
    archivingFailure: {
      // show message in snackbar or alert?
    },
    reading: {
      invoke: {
        src: 'readService',
        onDone: 'idle',
        onError: 'readingFailure'
      }
    },
    readingFailure: {
      // show message in snackbar or alert?
    }
  },
  on: {
    MARK_ARCHIVED: {
      actions: ['log'],
      target: 'archiving'
    },
    MARK_READ: {
      actions: ['log'],
      target: 'reading'
    }
  }
}, {
  services: {
    archiveService: async (context, event) => {
      return store.dispatch('notifications/archive', event.payload.id)
    },
    readService: async (context, event) => {
      return store.dispatch('notifications/read', event.payload.id)
    }
  },
  actions: {
    log: (context, event) => {
      console.log('notificationMachine', event.type, event.payload)
    }
  }
})
