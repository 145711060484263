import { assign, Machine } from 'xstate'
import { TrackingList } from '@/domain/trackingList'
import repository from '@/domain/trackingListRepository'

type MachineEvent = {
  type: string;
  value: any;
  key?: string;
}

interface MachineSchema {
  states: {
    viewing: {
      states: {
        idle: {};
        error: {};
      }
    };
    editing: {
      states: {
        idle: {};
        saving: {};
      }
    };
  };
}

export interface MachineContext {
  trackingList?: TrackingList;
  title?: string;
  error?: string;
}

export const trackingListMachine = Machine<MachineContext, MachineSchema, MachineEvent>({
  id: 'trackingListMachine',
  initial: 'viewing',
  context: {
    trackingList: undefined,
    title: undefined,
    error: undefined
  },
  on: {
    SET_TITLE: {
      actions: ['updateTitle']
    },
    SET_LIST: {
      actions: ['setTrackingList']
    }
  },
  states: {
    viewing: {
      id: 'viewing',
      initial: 'idle',
      on: {
        TOGGLE_MODE: {
          actions: ['setContextFromReferenceList'],
          target: '#editing'
        }
      },
      states: {
        idle: {},
        error: {
          exit: 'clearError'
        }
      }
    },
    editing: {
      id: 'editing',
      initial: 'idle',
      on: {
        TOGGLE_MODE: {
          target: '#viewing'
        },
        SAVE: [{
          target: '#editing.saving'
        }]
      },
      states: {
        idle: {},
        saving: {
          invoke: {
            src: 'saving',
            onDone: {
              target: '#viewing'
            },
            onError: {
              target: '#viewing.error',
              actions: 'setError'
            }
          }
        }
      }
    }
  }
}, {
  actions: {
    setTrackingList: assign<MachineContext, any>((context, event) => {
      return {
        trackingList: event.value
      }
    }),
    setContextFromReferenceList: assign<MachineContext, any>((context, event) => {
      if (context.trackingList) {
        return {
          title: context.trackingList.title
        }
      }
      return {}
    }),
    updateTitle: assign<MachineContext, any>((context, event) => {
      return {
        title: event.value
      }
    }),
    setError: assign<MachineContext, any>((context, event) => {
      return {
        error: event.data
      }
    }),
    clearError: assign<MachineContext, any>({
      error: undefined
    })
  },
  services: {
    saving: async (context, event) => {
      if (context.trackingList) {
        context.trackingList.title = context.title
        return await repository.update(context.trackingList)
      }
      return {}
    }
  }
})
