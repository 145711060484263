import { User } from '@/domain/user'

interface ModuleState {
  me?: User;
  userForUserManagement?: User;
  impersonated?: string;
}

const state = () => <ModuleState>({
  me: undefined,
  impersonated: window.localStorage.getItem('ssoUserImpersonation'),
  userForUserManagement: undefined
})

// getters
const getters = {
}

// actions

const actions = {}

// mutations
const mutations = {
  setMe (state, value) {
    state.me = value
  },
  setUserForUserManagement (state, value) {
    state.userForUserManagement = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
