import { AgendaTypeApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { AgendaTypeMapper } from '@/domain/agendaTypeMapper'
import { AgendaType } from '@/domain/agendaType'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new AgendaTypeApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, orderPackageType?: 'asc' | 'desc', orderTitle?: 'asc' | 'desc', q?: string, packageType?: string, packageType2?: Array<string>, options?: any
}

class AgendaTypeRepository implements IFindData<AgendaType>, ICrudData<AgendaType>, ISearchData<AgendaType> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getAgendaTypeItem(id)
      return AgendaTypeMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: AgendaType) {
    try {
      const params = AgendaTypeMapper.serializePost(obj)
      const response = await api.postAgendaTypeCollection(params)
      return AgendaTypeMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: AgendaType) {
    try {
      const params = AgendaTypeMapper.serializePatch(obj)
      const response = await api.patchAgendaTypeItem(String(obj.id), params)
      return AgendaTypeMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteAgendaTypeItem(id)
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 20, orderPackageType, orderTitle, q } : SearchParams) {
    try {
      const response = await api.getAgendaTypeCollection(
        page,
        itemsPerPage,
        orderPackageType,
        orderTitle,
        q,
        undefined,
        undefined,
        undefined
      )
      return <SearchResult<AgendaType>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => AgendaTypeMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new AgendaTypeRepository()
