var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutContentSidebar',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('h1',{staticClass:"text-h1 mt-8 mb-8"},[_vm._v(_vm._s(_vm.statsTitle)+" Performance Data")]),_c('div',[_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"items":_vm.statGroups,"hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" "+_vm._s(_vm.statGroups.length > 1 ? 'Data' : '')+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" "+_vm._s(_vm.statGroups.length > 1 ? 'Data' : '')+" ")]}}]),model:{value:(_vm.statGroupKey),callback:function ($$v) {_vm.statGroupKey=$$v},expression:"statGroupKey"}})],1)]),(_vm.statsPerformance.length)?_c('div',{staticClass:"ui-grid"},_vm._l((_vm.statsPerformance),function(stat,index){return _c('div',{key:index,staticClass:"ui-item"},[_c('DashboardStat',{attrs:{"stat":stat}})],1)}),0):_c('div',[_c('BaseAlert',{attrs:{"dismissible":false,"color":"secondaryGrey","icon":false}},[_vm._v("There is no performance data.")])],1),_c('h1',{staticClass:"text-h1 mt-8 mb-8"},[_vm._v(_vm._s(_vm.statsTitle)+" Status Data")]),_c('v-data-table',{staticClass:"elevation-1 mb-16",attrs:{"headers":_vm.statsDocumentStatus.headers,"items":_vm.statsDocumentStatus.rows,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('DocumentStatusChip',{staticClass:"ui-chip",attrs:{"status":item.status}})]}},{key:"item.oldest",fn:function(ref){
var item = ref.item;
return [(item.oldest)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("formatDate")(item.oldest)))]):_c('span',{staticClass:"text-no-wrap"},[_vm._v("-")])]}}])}),_c('v-sheet',{staticClass:"mb-16 pa-5 d-flex align-center",attrs:{"elevation":"1"}},[_c('BaseIcon',{staticClass:"mr-4",attrs:{"iconName":"mdiClockCheckOutline","color":"green","size":"24px","title":"Delete"}}),_c('span',[_vm._v("All data on this page was calculated at the end of the day on "+_vm._s(_vm._f("formatDate")(_vm.statsDate)))])],1)]},proxy:true},{key:"sidebar",fn:function(){return [_c('div',{staticClass:"text-center ui-sticky"},[_c('SvgGuyCard',{attrs:{"group":"kpi"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }