import { computed } from '@vue/composition-api'
import store from '@/store'
import { User } from '@/domain/user'
import repository from '@/domain/userRepository'

export function useUser () {
  const impersonated = computed(() => store.state.users.impersonated)
  const userForUserManagement = computed<User>(() => store.state.users.userForUserManagement)
  const me = computed<User>(() => store.state.users.me)
  const selectText = x => `${x.firstName} ${x.lastName}`
  const findForUserManagement = (id) => (
    repository.findById(id).then(x => {
      store.commit('users/setUserForUserManagement', x)
    })
  )
  const search = c => {
    return repository.findByCriteria({ c })
  }
  const findByCriteria = ({ page, itemsPerPage, c }) => (
    repository.findByCriteria({ page, itemsPerPage, c })
  )
  const findById = id => (
    repository.findById(id)
  )

  return {
    findForUserManagement,
    findByCriteria,
    findById,
    impersonated,
    me,
    selectText,
    search,
    userForUserManagement
  }
}
