






































































import { defineComponent, computed, watch, ref, onMounted } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import AttachmentCard from '@/components/AttachmentCard.vue'
import OverlayLoading from '@/components/OverlayLoading.vue'
import {
  BaseInputFilePicker,
  BaseInputText,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { isObject } from '@/validations'
import { useMachine } from 'xstate-vue2'
import { attachmentMachine } from '@/machines/attachmentMachine'
import { useUser } from '@/composables/useUser'
import { mdiTextBoxPlusOutline } from '@mdi/js'

export default defineComponent({
  name: 'addin-attachments',
  props: {
    document: {
      type: Object,
      required: true
    },
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    GroupHeading,
    BaseCombobox,
    AttachmentCard,
    OverlayLoading,
    BaseInputFilePicker,
    BaseInputText,
    BaseButton
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    // DOCUMENT
    const document = computed(() => store.getters['documents/getDocumentForAddin'])
    const { state, send } = useMachine(attachmentMachine)
    const fileUpload = ref<HTMLInputElement | null>(null)

    const addAttachment = () => {
      send({ type: 'SUBMIT' })
      if (fileUpload.value && fileUpload.value.files) {
        fileUpload.value.files = null
      }
    }

    watch(document, () => {
      if (document.value) {
        send({ type: 'UPDATE_CURRENT_DOCUMENT', payload: document.value })
      }
    }, { immediate: true })

    watch(() => props.document.documentAttachments, () => {
      send({
        type: 'UPDATE_CURRENT_ATTACHMENTS',
        payload: props.document.documentAttachments || []
      })
    }, { immediate: true })

    // Check if user can add/remove attachments
    const { me } = useUser()

    const allowedUser = computed(() => {
      let result = false
      if (me.value && props.document && ((props.document.documentManager && me.value.id === props.document.documentManager.id) || (props.document.documentOwner && me.value.id === props.document.documentOwner.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the attachment tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 2) {
        focusOnContent()
      }
    })

    onMounted(() => {
      // When using keyboard navigation, the following takes the focus to the attachment tab content when it loads so there isn't
      // a need to tab through the rest of the tabs to reach the tab content after opening it.
      focusOnContent()
    })

    return {
      mdiTextBoxPlusOutline,
      isObject,
      state,
      send,
      fileUpload,
      addAttachment,
      allowedUser,
      tabContent
    }
  }
})
