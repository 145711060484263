import { AgendaGroupApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { AgendaTemplateMapper } from '@/domain/agendaTemplateMapper'
import { AgendaTemplate } from '@/domain/agendaTemplate'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new AgendaGroupApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, pagination: boolean, agendaType?: string, agendaType2?: Array<string>, agendaTypePackageType?: string, agendaTypePackageType2?: Array<string>, q?: string, orderAgendaTypeTitle?: 'asc' | 'desc', orderDocumentFolderName?: 'asc' | 'desc', orderTitle?: 'asc' | 'desc', options?: any
}

class AgendaTemplateRepository implements IFindData<AgendaTemplate>, ICrudData<AgendaTemplate>, ISearchData<AgendaTemplate> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getAgendaGroupItem(id)
      return AgendaTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: AgendaTemplate) {
    try {
      const params = AgendaTemplateMapper.serializePost(obj)
      const response = await api.postAgendaGroupCollection(params)
      return AgendaTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: AgendaTemplate) {
    try {
      const params = AgendaTemplateMapper.serializePatch(obj)
      const response = await api.patchAgendaGroupItem(String(obj.id), params)
      return AgendaTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page, itemsPerPage, pagination, agendaType, agendaTypePackageType, q, orderTitle, orderAgendaTypeTitle, orderDocumentFolderName }: SearchParams) {
    try {
      const response = await api.getAgendaGroupCollection(
        page,
        itemsPerPage,
        pagination,
        agendaType,
        undefined,
        agendaTypePackageType,
        undefined,
        q,
        orderAgendaTypeTitle,
        orderDocumentFolderName,
        orderTitle,
        undefined
      )
      return <SearchResult<AgendaTemplate>> {
        pageCount: itemsPerPage !== undefined ? Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) : 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => AgendaTemplateMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new AgendaTemplateRepository()
