





































































































































































import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import AgendaItemDraggable from '@/components/AgendaItemDraggable.vue'
import draggable from 'vuedraggable'
import FormAgendaAddDocument from '@/components/forms/FormAgendaAddDocument.vue'
import FormAgendaAddAttachment from '@/components/forms/FormAgendaAddAttachment.vue'
import { useAgendas } from '@/composables/useAgendas'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import FormInputDatePickerNew from '@/components/forms/FormInputDatePickerNew.vue'
import FormInputTimePickerNew from '@/components/forms/FormInputTimePickerNew.vue'
import { setDatePart, setTimePart } from '@/utils/date'
import { Media } from '@/domain/media'
import { Document } from '@/domain/document'
import { Agenda, AgendaItem, AgendaSection } from '@/domain/agenda'
import repository from '@/domain/agendaRepository'
import { getRandomInt } from '@/utils/random'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    packageType: {
      type: String,
      required: true
    }
  },
  components: {
    AgendaItemDraggable,
    draggable,
    FormAgendaAddDocument,
    FormAgendaAddAttachment,
    BaseButton,
    BaseIcon,
    FormInputDatePickerNew,
    FormInputTimePickerNew,
    DialogConfirm
  },
  setup (props, { root }) {
    const store = root.$store
    const packageTypeName = props.packageType === 'document-bundle' ? 'Document Bundle' : 'Agenda'
    const panel = ref<number[]>([])
    const me = computed(() => store.state.users.me)
    const agenda = reactive<Agenda>({
      id: undefined,
      title: undefined,
      meetingDate: undefined,
      template: undefined,
      sections: []
    } as Agenda)
    const { findForDetail, agendaForDetail } = useAgendas()
    findForDetail(props.id)
    watch(agendaForDetail, () => {
      const params = JSON.parse(JSON.stringify(agendaForDetail.value))
      params.meetingDate = (new Date(params.meetingDate))
      Object.assign(agenda, params)
    })
    const dragOptions = reactive({
      animation: 200,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
      filter: '.ui-title-field',
      preventOnFilter: false
    })
    const cloneDocumentAsAgendaItem = (document: Document): AgendaItem => {
      const id = getRandomInt(5000, 10000)
      const children: AgendaItem[] = document.documentAttachments?.reduce?.((acc, val : any) => {
        return val && val.attachment ? [...acc, cloneAttachmentAsAgendaItem(val.attachment, true)] : acc
      }, [] as AgendaItem[]) ?? []

      return {
        id,
        title: document.title,
        document,
        children
      }
    }
    const cloneAttachmentAsAgendaItem = (attachment: Media, existingAttachment: boolean = false): AgendaItem => {
      const id = getRandomInt(5000, 10000)
      return {
        id,
        title: attachment.title,
        attachment,
        children: existingAttachment ? undefined : [],
        existingAttachment
      }
    }
    const addSection = () => {
      const id = getRandomInt(5000, 10000)
      const section: AgendaSection = {
        id,
        type: 'Unknown',
        title: '',
        position: 0,
        children: []
      }
      if (agenda.sections?.length) {
        agenda.sections = [...agenda.sections, section]
      } else {
        agenda.sections = [section]
      }
    }
    const removeSection = id => {
      agenda.sections = agenda.sections?.filter(x => x.id !== id)
    }
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.update(JSON.parse(JSON.stringify(agenda)))
      },
      actions: {
        onDoneEnter: () => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
          findForDetail(props.id)
        }
      }
    })
    const { state: publishState, send: publishSend } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: () => repository.publish({
          obj: JSON.parse(JSON.stringify(agenda)),
          forceRebuild: false
        })
      },
      actions: {
        onDoneEnter: () => {
          findForDetail(props.id)
        }
      }
    })
    const findTarget = (path, children) => {
      if (path.length === 0) {
        return children
      }
      const index = path.shift()
      return findTarget(path, children[index].children)
    }
    const removeItem = path => {
      const indexSection = path.shift()
      const indexLast = path.pop()
      if (agenda.sections?.length) {
        const children = agenda.sections[indexSection].children
        const target = findTarget(path, children)
        if (target) {
          target.splice(indexLast, 1)
        }
      }
    }
    const dialogDocument = ref(false)
    const dialogAttachment = ref(false)
    const addDocumentToSection = (document: Document) => {
      const clone = cloneDocumentAsAgendaItem(document)
      if (agenda.sections?.length) {
        const section: AgendaSection | undefined = agenda.sections.find(x => x.id === sectionEdit.value) as AgendaSection
        if (section?.children) {
          section.children = [...section.children, clone]
        }
        dialogDocument.value = false
      }
    }
    const addAttachmentToSection = (media: Media) => {
      const clone = cloneAttachmentAsAgendaItem(media)
      if (agenda.sections?.length) {
        const section: AgendaSection | undefined = agenda.sections.find(x => x.id === sectionEdit.value) as AgendaSection
        if (section?.children) {
          section.children = [...section.children, clone]
        }
        dialogAttachment.value = false
      }
    }
    const readonly = ref<boolean>(false)
    const sectionEdit = ref<number>()

    return {
      publishState,
      publishSend,
      agendaForDetail,
      setDatePart,
      setTimePart,
      sectionEdit,
      readonly,
      addAttachmentToSection,
      addDocumentToSection,
      dialogDocument,
      dialogAttachment,
      removeItem,
      panel,
      state,
      send,
      removeSection,
      addSection,
      cloneDocumentAsAgendaItem,
      agenda,
      dragOptions,
      me,
      packageTypeName
    }
  }
})
