









































import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { BaseInputText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { useUserSettings } from '@/composables/useUserSettings'
import { useUser } from '@/composables/useUser'
import { length, isDigits } from '@/validations'
import { UserSettings } from '@/domain/userSetting'
import repository from '@/domain/userSettingRepository'

export default defineComponent({
  components: {
    BaseCombobox,
    BaseInputText
  },
  setup () {
    const { findByCriteria, selectText } = useUser()
    const { findForDetail, detail } = useUserSettings()
    const valid = ref(true)
    const form = reactive<UserSettings>({
      id: undefined,
      phoneNotificationsEnabled: undefined,
      notificationPhoneNumber: undefined
    })
    watch(detail, () => {
      if (detail.value) {
        const params: UserSettings = JSON.parse(JSON.stringify(detail.value))
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repository.update(JSON.parse(JSON.stringify(form)))
      },
      actions: {
        onDoneEnter: () => findForDetail('me')
      }
    })
    return {
      length,
      isDigits,
      valid,
      selectText,
      findByCriteria,
      state,
      send,
      form
    }
  }
})
