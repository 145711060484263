var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isTablet && !_vm.isMobile)?_c('v-app-bar',{class:_vm.showBookmarks ? 'v-app-bar--show-bookmarks' : '',attrs:{"app":"","color":"white","height":"100","extension-height":_vm.showBookmarks ? 50 : 0},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"container align-center d-flex"},[(!_vm.$route.name.match('DocumentsList'))?_c('router-link',{staticClass:"ui-logo",attrs:{"aria-label":"Doc Share Logo: Click to return to the document dashboard.","to":{ name: 'DocumentsBase' }}},[_c('DocshareLogo')],1):_c('a',{staticClass:"ui-logo",attrs:{"aria-label":"Doc Share Logo: Click to return to the document dashboard.","href":_vm.$router.resolve({ name: 'DocumentsList' }).href}},[_c('DocshareLogo')],1),_c('a',{staticClass:"skip-main",attrs:{"href":"#main"}},[_vm._v("Skip to Main Content")]),_c('BaseMenuQuickLinks',{staticClass:"ml-10",attrs:{"data-test":"create-new-menu","label":"Create New","iconName":"mdiPlusCircle","items":[
          { text: 'Agenda', to: { name: 'AgendaTemplateList', query: { packageType: 'agenda' } }, testId: 'create-new-menu-agenda', visible: _vm.me.canCreateAgenda },
          { text: 'Document', to: { name: 'DocumentTemplateList' }, testId: 'create-new-menu-document', visible: true },
          { text: 'Document Bundle', to: { name: 'AgendaTemplateList', query: { packageType: 'document-bundle' } }, testId: 'create-new-menu-document-bundle', visible: _vm.me.canCreateAgenda },
          { text: 'Tracking List', to: { name: 'TrackingListsManageCreate' }, visible: _vm.me.canCreateAgenda } ],"btnAriaLabel":"Create New Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}}),(_vm.me.canManage)?_c('BaseMenuQuickLinks',{staticClass:"ml-10",attrs:{"data-test":"manage-menu","label":"Manage","iconName":"mdiViewList","items":[
          { text: 'Agendas', to: { name: 'AgendasManageList', query: { packageType: 'agenda' } }, visible: _vm.me.canManageAgendas },
          { text: 'Document Bundles', to: { name: 'AgendasManageList', query: { packageType: 'document-bundle' } }, visible: _vm.me.canManageAgendas },
          { text: 'Tracking Lists', to: { name: 'TrackingListsManage' }, visible: _vm.me.canManageTrackingLists }
        ],"btnAriaLabel":"Manage Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}}):_vm._e(),_c('v-spacer'),(_vm.me.canManageTrackingLists)?_c('v-btn',{attrs:{"input-value":_vm.stateSidebar.matches('tracking_lists'),"data-test":"tracking-lists--appbar--toggle","icon":"","aria-label":"Tracking List Button: Click to open Document Tracking Library in application sidebar."},on:{"click":function($event){return _vm.sendSidebar({ type: 'TOGGLE_TRACKING_LISTS' })}}},[_c('BaseIcon',{attrs:{"icon-name":"mdiPlaylistCheck"}})],1):_vm._e(),_vm._l((_vm.navigationItems),function(navItem){return _c('v-btn',{key:navItem.text,attrs:{"to":navItem.to,"href":navItem.href,"target":navItem.target,"icon":"","aria-label":navItem.label}},[_c('BaseIcon',{attrs:{"icon-name":navItem.iconName}})],1)}),_c('v-btn',{class:_vm.notificationIndicator ? 'ui-notifications__indicator-active' : '',attrs:{"data-test":"notifications","input-value":_vm.stateSidebar.matches('notifications'),"icon":"","aria-label":_vm.notificationAriaLabel},on:{"click":function($event){return _vm.sendSidebar({ type: 'TOGGLE_NOTIFICATIONS' })}}},[_c('BaseIcon',{attrs:{"icon-name":"mdiBell"}})],1),_c('v-menu',{attrs:{"open-on-click":"","offset-y":"","transition":"slide-y-transition","left":"","nudge-bottom":"10","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","aria-label":"User Profile Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}},'v-btn',attrs,false),on),[_c('BaseIcon',{attrs:{"icon-name":"mdiAccount"}}),_c('BaseIcon',{attrs:{"icon-name":"mdiChevronDown"}})],1)]}}],null,false,254589744)},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"250","tile":""}},[_c('v-list',{attrs:{"dense":"","color":"primary","dark":""}},[_c('AppBarListGroupUserDelegation',{attrs:{"user":_vm.me}}),_c('AppBarListGroupSettings',{attrs:{"user":_vm.me}})],1)],1)],1)],2)]},proxy:true},{key:"extension",fn:function(){return [(_vm.showBookmarks)?_c('div',{staticClass:"container align-center d-flex"},[_c('BookmarkBar')],1):_vm._e()]},proxy:true}],null,false,638665849)}):_c('v-app-bar',{attrs:{"app":"","color":"white","height":"100"}},[_c('router-link',{staticClass:"ui-logo",attrs:{"aria-label":"Doc Share Logo: Click to return to the document dashboard.","to":{ name: 'DocumentsBase' }}},[_c('DocshareLogo')],1),(!_vm.isMobile)?_c('BaseMenuQuickLinks',{staticClass:"ml-8",attrs:{"data-test":"create-new-menu","label":"Create New","iconName":"mdiPlusCircle","items":[
        { text: 'Agenda', to: { name: 'AgendaTemplateList', query: { packageType: 'agenda' } }, testId: 'create-new-menu-agenda', visible: _vm.me.canCreateAgenda },
        { text: 'Document', to: { name: 'DocumentTemplateList' }, testId: 'create-new-menu-document', visible: true },
        { text: 'Document Bundle', to: { name: 'AgendaTemplateList', query: { packageType: 'document-bundle' } }, testId: 'create-new-menu-document-bundle', visible: _vm.me.canCreateAgenda },
        { text: 'Tracking List', to: { name: 'TrackingListsManageCreate' }, visible: _vm.me.canCreateAgenda }
      ],"btnAriaLabel":"Create New Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}}):_vm._e(),(!_vm.isMobile && _vm.me.canManage)?_c('BaseMenuQuickLinks',{staticClass:"ml-8",attrs:{"data-test":"manage-menu","label":"Manage","iconName":"mdiViewList","items":[
        { text: 'Agendas', to: { name: 'AgendasManageList', query: { packageType: 'agenda' } }, visible: _vm.me.canManageAgendas },
        { text: 'Document Bundles', to: { name: 'AgendasManageList', query: { packageType: 'document-bundle' } }, visible: _vm.me.canManageAgendas },
        { text: 'Tracking Lists', to: { name: 'TrackingListsManage' }, visible: _vm.me.canManageTrackingLists }
      ],"btnAriaLabel":"Manage Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}}):_vm._e(),_c('v-spacer'),_c('v-btn',{class:_vm.notificationIndicator ? 'ui-notifications__indicator-active' : '',attrs:{"input-value":_vm.stateSidebar.matches('notifications'),"icon":"","aria-label":_vm.notificationAriaLabel},on:{"click":function($event){return _vm.sendSidebar({ type: 'TOGGLE_NOTIFICATIONS' })}}},[_c('BaseIcon',{attrs:{"icon-name":"mdiBell"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","aria-label":"Menu button: Click to open menu."}},'v-btn',attrs,false),on),[_c('v-app-bar-nav-icon')],1)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"250","tile":""}},[_c('v-list',{attrs:{"dense":""}},[_c('AppBarListGroupUserDelegation',{attrs:{"user":_vm.me}}),_c('AppBarListGroupPages',{attrs:{"navigationItems":_vm.navigationItems}}),_c('AppBarListGroupSettings',{attrs:{"user":_vm.me}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }