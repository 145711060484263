import { Notification } from '@/domain/notification'

export const NotificationMapper = ({
  deserialize: (json): Notification => {
    return <Notification>{
      id: Number(json['@id'].replace('/api/notifications/', '')),
      category: json.category,
      subCategory: json.subCategory,
      actionMetaData: json.actionMetaData,
      dateCreated: new Date(json.dateCreated),
      content: json.content,
      seen: json.seen
    }
  }
})
