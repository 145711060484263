import { UserMapper } from '@/domain/userMapper'
import { Comment } from '@/domain/comment'

export const CommentMapper = ({
  deserialize: (json): Comment => {
    return <Comment>{
      id: Number(json['@id'].replace('/api/comments/', '')),
      content: json.content,
      user: UserMapper.deserialize(json.user),
      seen: json.seen,
      resolved: json.resolved,
      replies: json.replies && json.replies.length ? json.replies.map(x => CommentMapper.deserialize(x)) : undefined,
      dateCreated: new Date(json.dateCreated)
    }
  }
})
