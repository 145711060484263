










import { computed, watch, defineComponent } from '@vue/composition-api'
import FormAgendaCreate from '@/components/forms/FormAgendaCreate.vue'
import repository from '@/domain/agendaTemplateRepository'

export default defineComponent({
  props: ['id'],
  components: {
    FormAgendaCreate
  },
  setup (props, { root }: any) {
    const store = root.$store
    const detail = computed(() => store.state.agendaTemplates.detail)
    watch(() => props.id, () => {
      repository.findById(props.id).then(x => {
        store.commit('agendaTemplates/setForDetail', x)
      })
    }, {
      immediate: true
    })
    return {
      detail
    }
  }
})
