import { UserMapper } from '@/domain/userMapper'
import { UserGroupMapper } from '@/domain/userGroupMapper'
import { UserGroupTypeMapper } from '@/domain/userGroupTypeMapper'
import { WorkflowGroupMapper } from '@/domain/workflowGroupMapper'
import { WorkflowTemplateWorkflowTemplateWrite } from '@zucommunications/gsk-docshare-web-api'
import { WorkflowTemplate } from '@/domain/workflowTemplate'

export const WorkflowTemplateMapper = ({
  deserialize: (json): WorkflowTemplate => {
    return <WorkflowTemplate>{
      id: Number(json['@id'].replace('/api/workflow_templates/', '')),
      userGroup: json.userGroup ? UserGroupMapper.deserialize(json.userGroup) : undefined,
      userGroupType: json.userGroupType ? UserGroupTypeMapper.deserialize(json.userGroupType) : undefined,
      workflowType: json.workflowType,
      isDefault: json.isDefault,
      nonCriticalWorkflowGroups: json.workflowTemplateWorkflowGroups.reduce((acc, val) => {
        if (!val.workflowGroup.critical) {
          acc.push({
            position: val.position,
            isEnabled: val.isEnabled,
            workflowGroup: WorkflowGroupMapper.deserialize(val.workflowGroup),
            templateUsers: val.WorkflowTemplateUserInclusion.map(x => UserMapper.deserialize(x))
          })
        }
        return acc
      }, []),
      criticalWorkflowGroups: json.workflowTemplateWorkflowGroups.reduce((acc, val) => {
        if (val.workflowGroup.critical) {
          acc.push({
            position: val.position,
            isEnabled: val.isEnabled,
            workflowGroup: WorkflowGroupMapper.deserialize(val.workflowGroup),
            templateUsers: val.WorkflowTemplateUserInclusion.map(x => UserMapper.deserialize(x))
          })
        }
        return acc
      }, []),
      workflowTemplateDocuments: json.workflowTemplateDocuments
    }
  },
  serializeIri: (workflowTemplate: WorkflowTemplate): string => {
    return `/api/workflow_templates/${workflowTemplate.id}`
  },
  serialize: (workflowTemplate: WorkflowTemplate): WorkflowTemplateWorkflowTemplateWrite => {
    return {
      userGroup: workflowTemplate.userGroup ? UserGroupMapper.serializeIri(workflowTemplate.userGroup) : undefined,
      userGroupType: workflowTemplate.userGroupType ? UserGroupTypeMapper.serializeIri(workflowTemplate.userGroupType) : undefined,
      workflowType: workflowTemplate.workflowType,
      isStandardList: workflowTemplate.isStandardList,
      workflowTemplateWorkflowGroups: workflowTemplate.nonCriticalWorkflowGroups || workflowTemplate.criticalWorkflowGroups ? [...(workflowTemplate.nonCriticalWorkflowGroups || []), ...(workflowTemplate.criticalWorkflowGroups || [])].map((workflowGroup, index) => {
        return {
          position: index,
          isEnabled: workflowGroup.isEnabled,
          workflowGroup: WorkflowGroupMapper.serializeIri(workflowGroup.workflowGroup),
          WorkflowTemplateUserInclusion: workflowGroup.templateUsers ? workflowGroup.templateUsers.map(y => UserMapper.serializeIri(y)) : []
        }
      }) : undefined,
      workflowTemplateDocuments: workflowTemplate.workflowTemplateDocuments
    }
  }
})
