


























import { defineComponent, inject } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import agendaRepository from '@/domain/agendaRepository'
import { useAgendas } from '@/composables/useAgendas'
import { sidebarMachineSendKey } from '@/router/views/AppOutlet.vue'

export default defineComponent({
  components: {
    DialogConfirm,
    BaseIcon,
    BaseButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup () {
    const { setAgendaDeleted } = useAgendas()
    const sendSidebar = inject(sidebarMachineSendKey)

    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: async (context, event: any) => {
          await agendaRepository.delete(event.value)
          setAgendaDeleted(true)
        }
      },
      actions: {
        onDoneEnter: () => {
          if (sendSidebar) {
            sendSidebar('CLOSE')
          }
        }
      }
    })
    return {
      state, send
    }
  }
})
