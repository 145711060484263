

























import { defineComponent } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  name: 'group-heading',
  components: {
    BaseIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    }
  }
})
