





























import { defineComponent, ref, reactive } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import { promiseMachine } from '@/machines/promiseMachine'
import { useMachine } from 'xstate-vue2'
import {
  BaseInputText
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { notEmpty } from '@/validations'
import repositoryTrackingList from '@/domain/trackingListRepository'
import { TrackingList } from '@/domain/trackingList'
import AlertSuccess from '@/components/AlertSuccess.vue'
import AlertError from '@/components/AlertError.vue'

export default defineComponent({
  props: ['item'],
  components: {
    GroupHeading,
    BaseInputText,
    AlertSuccess,
    AlertError
  },
  setup () {
    const formElement = ref<any>(null)
    const form = reactive<TrackingList>({
      title: ''
    })
    const valid = ref(false)
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => repositoryTrackingList.create(form as TrackingList)
      },
      actions: {
        onDoneEnter: () => {
          form.title = ''
          formElement.value.reset()
          formElement.value.resetValidation()
        }
      }
    })
    return {
      form,
      formElement,
      state,
      send,
      valid,
      notEmpty
    }
  }
})
