

















import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    status: {
      type: Boolean,
      default: true
    }
  }
})
