





































































import { computed, defineComponent, ref } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import DashboardStat from '@/components/DashboardStat.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import repositoryKpi from '@/domain/kpiRepository'
import { BaseAlert, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { Kpi } from '@/domain/kpi'
import SvgGuyCard from '@/components/SvgGuyCard.vue'

export default defineComponent({
  components: {
    LayoutContentSidebar,
    DashboardStat,
    DocumentStatusChip,
    BaseAlert,
    BaseIcon,
    SvgGuyCard
  },
  setup (_, { root }) {
    const store = root.$store
    const statGroupKey = ref<number>(0)

    repositoryKpi.findByCriteria({}).then(x => {
      store.commit('kpis/setForDashboard', x)
    })

    const statsData = computed<Kpi[]>(() => store.state.kpis.dashboard)

    const statGroups = computed(() => {
      return statsData.value?.map(({ title: text }, value) => {
        return {
          text,
          value
        }
      }) || [{
        text: 'No Categories',
        value: 0
      }]
    })
    const statsTitle = computed(() => {
      const x = statsData.value
      const y = statGroupKey.value
      return x && x[y] ? x[y].title : undefined
    })
    const statsDate = computed(() => {
      const x = statsData.value
      const y = statGroupKey.value
      return x && x[y] ? x[y].dateCreated : undefined
    })
    const statsPerformance = computed(() => {
      const x = statsData.value
      const y = statGroupKey.value
      return x && x[y] ? x[y].performanceMetrics : []
    })
    const statsDocumentStatus = computed(() => {
      const x = statsData.value
      const y = statGroupKey.value
      return x && x[y] ? x[y].documentStatusMetrics : {}
    })

    return {
      statsTitle,
      statsDate,
      statsPerformance,
      statsDocumentStatus,
      statGroupKey,
      statGroups,
      statsData
    }
  }
})
