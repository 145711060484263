import { UserGroup } from '@/domain/userGroup'

interface ModuleState {
  /** Object shown on the detail page */
  detail?: UserGroup;
}

// state
const state = () => <ModuleState>({
  detail: undefined
})

// getters
const getters = {
  myGroups (state, getters, rootState) {
    return rootState.users.me?.groupMembership.map(x => x.group)
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  setForDetail (state, value) {
    state.detail = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
