import { WorkflowGroupApi, DocumentApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { WorkflowGroup } from '@/domain/workflowGroup'
import { WorkflowGroupMapper } from '@/domain/workflowGroupMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new WorkflowGroupApi(undefined, BASE_PATH, axios)
const apiDocument = new DocumentApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, userGroup?: string, userGroup2?: Array<string>, userGroupType?: string, userGroupType2?: Array<string>, title?: string, workflowType?: string, workflowType2?: Array<string>, critical?: boolean, isStandardList?: boolean, orderTitle?: 'asc' | 'desc', orderCritical?: 'asc' | 'desc', orderIsStandardList?: 'asc' | 'desc', options?: any
}

class WorkflowGroupRepository implements IFindData<WorkflowGroup>, ICrudData<WorkflowGroup>, ISearchData<WorkflowGroup> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getWorkflowGroupItem(id)
      return WorkflowGroupMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: WorkflowGroup) {
    try {
      const params = WorkflowGroupMapper.serialize(obj)
      const response = await api.postWorkflowGroupCollection(params)
      return WorkflowGroupMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: WorkflowGroup) {
    try {
      const response = await api.patchWorkflowGroupItem(String(obj.id), WorkflowGroupMapper.serialize(obj))
      return WorkflowGroupMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteWorkflowGroupItem(id)
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 5, userGroup, userGroup2, userGroupType, userGroupType2, title, workflowType, workflowType2, critical, orderTitle, orderCritical }: SearchParams) {
    try {
      const response = await api.getWorkflowGroupCollection(
        page,
        itemsPerPage,
        userGroup,
        userGroup2,
        userGroupType,
        userGroupType2,
        title,
        workflowType,
        workflowType2,
        critical,
        undefined,
        orderTitle,
        orderCritical,
        undefined
      )
      return <SearchResult<WorkflowGroup>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => WorkflowGroupMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findByDocumentId (documentId: string) {
    try {
      const response = await apiDocument.getWorkflowForDocumentDocumentItem(documentId)
      return response.data['hydra:member'].map(x => WorkflowGroupMapper.deserializeForTemplateGroup(x))
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new WorkflowGroupRepository()
