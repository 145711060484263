<template>
  <div>
    <GroupHeading title="Status" />
    <DocumentStatusChip
      :status="document.status"
      :requiresMyInput="document.requiresMyInput"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'

export default defineComponent({
  props: {
    document: Object
  },
  components: {
    GroupHeading,
    DocumentStatusChip
  }
})
</script>

<style scoped>

</style>
