import { DocumentAttachmentDocumentPatch } from '@zucommunications/gsk-docshare-web-api'
import { Media, MediaGroup } from '@/domain/media'

export const MediaGroupMapper = ({
  deserialize: (json): MediaGroup => {
    return <MediaGroup>{
      id: Number(json['@id'].replace('/api/document_attachments/', '')),
      attachment: json.attachment ? MediaMapper.deserialize(json.attachment) : undefined,
      position: json.position
    }
  },
  serializePatch: (obj: MediaGroup): DocumentAttachmentDocumentPatch => {
    return {
      position: obj.position,
      attachment: obj.attachment ? MediaMapper.serializeIri(obj.attachment) : undefined
    }
  }
})

export const MediaMapper = ({
  deserialize: (json): Media => {
    return <Media>{
      id: Number(json['@id'].replace('/api/media/', '')),
      title: json.title,
      mimeType: json.mimeType,
      extension: json.extension
    }
  },
  serializeIri: (obj: Media): string => {
    return `/api/media/${obj.id}`
  }
})
