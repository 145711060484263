


























import { defineComponent } from '@vue/composition-api'
import { BaseIcon, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import statusColor from '@/utils/helpers/statusColor'

export default defineComponent({
  name: 'document-comment-block',
  props: {
    commentCount: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: 'draft'
    },
    commentLink: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseIcon,
    BaseButton
  },
  setup () {
    return { statusColor }
  }
})
