import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AgendaTemplateList from '@/router/views/AgendaTemplateList.vue'
import AgendaTemplateDetail from '@/router/views/AgendaTemplateDetail.vue'
import DocumentTemplateList from '@/router/views/DocumentTemplateList.vue'
import DocumentTemplateDetail from '@/router/views/DocumentTemplateDetail.vue'
import { routesAdmin } from '@/router/admin'
import AgendasManage from '@/router/views/AgendasManage.vue'
import AgendasManageDetail from '@/router/views/AgendasManageDetail.vue'
import AgendasManagePreview from '@/router/views/AgendasManagePreview.vue'
import AgendasManageList from '@/router/views/AgendasManageList.vue'
import UserProfile from '@/router/views/UserProfile.vue'
import Callback from '@/router/views/Callback.vue'
import DocumentsBase from '@/router/views/DocumentsBase.vue'
import DocumentsList from '@/router/views/DocumentsList.vue'
import DocumentsDetail from '@/router/views/DocumentsDetail.vue'
import Addin from '@/router/views/Addin.vue'
import AddinDetail from '@/router/views/AddinDetail.vue'
import App from '@/router/views/App.vue'
import Error401 from '@/router/views/Error401.vue'
import LoggedOut from '@/router/views/LoggedOut.vue'
import Dashboard from '@/router/views/Dashboard.vue'
import store from '@/store'
import TrackingListsDetail from '@/router/views/TrackingListsDetail.vue'
import TrackingListsManage from '@/router/views/TrackingListsManage.vue'
import TrackingListsManageList from '@/router/views/TrackingListsManageList.vue'
import TrackingListsManageCreate from '@/router/views/TrackingListsManageCreate.vue'
import repositoryUser from '@/domain/userRepository'
import { titlePath } from '@/utils/title'
import emitter from '@/utils/emitter'

Vue.use(VueRouter)

const routesDocuments: RouteConfig = {
  name: 'DocumentsBase',
  path: 'documents',
  component: DocumentsBase,
  redirect: { name: 'DocumentsList' },
  children: [{
    name: 'DocumentsList',
    path: 'list',
    component: DocumentsList
  }, {
    name: 'DocumentsDetail',
    path: ':id',
    props: true,
    component: DocumentsDetail
  }]
}

const routesAgendaTemplate: RouteConfig = {
  name: 'AgendaTemplateList',
  path: 'agenda/template',
  component: AgendaTemplateList,
  props: route => ({
    packageType: route.query.packageType === 'document-bundle' ? 'document-bundle' : 'agenda'
  }),
  children: [{
    name: 'AgendaTemplateDetail',
    path: ':id',
    props: true,
    component: AgendaTemplateDetail
  }]
}

const routesAgendas: RouteConfig = {
  name: 'AgendasManage',
  path: 'agendas',
  component: AgendasManage,
  redirect: { name: 'AgendasManageList' },
  children: [{
    name: 'AgendasManageList',
    path: 'list',
    component: AgendasManageList,
    props: route => ({
      packageType: route.query.packageType === 'document-bundle' ? 'document-bundle' : 'agenda'
    })
  }, {
    name: 'AgendasManageDetail',
    path: ':id',
    props: route => ({
      id: route.params.id,
      packageType: route.query.packageType === 'document-bundle' ? 'document-bundle' : 'agenda'
    }),
    component: AgendasManageDetail
  }, {
    name: 'AgendasManagePreview',
    path: ':id/preview',
    props: true,
    component: AgendasManagePreview
  }]
}

const routesTrackingLists: RouteConfig = {
  name: 'TrackingListsManage',
  path: 'tracking-lists',
  redirect: { name: 'TrackingListsManageList' },
  component: TrackingListsManage,
  children: [{
    name: 'TrackingListsManageList',
    path: 'list',
    component: TrackingListsManageList
  }, {
    name: 'TrackingListsManageCreate',
    path: 'create',
    component: TrackingListsManageCreate
  }, {
    name: 'TrackingListsDetail',
    path: ':id',
    props: true,
    component: TrackingListsDetail
  }]
}

const routesDocumentTemplate: RouteConfig = {
  name: 'DocumentTemplateList',
  path: 'document/template',
  component: DocumentTemplateList,
  children: [{
    name: 'DocumentTemplateDetail',
    path: ':id',
    component: DocumentTemplateDetail,
    props: true
  }]
}

const routesUserProfile: RouteConfig = {
  name: 'UserProfile',
  path: 'user/profile',
  component: UserProfile,
  meta: {
    pageHasSettings: true
  }
}

const routesDashboard: RouteConfig = {
  name: 'Dashboard',
  path: 'dashboard',
  component: Dashboard
}

const routes: Array<RouteConfig> = [
  {
    name: 'Root',
    path: '/',
    redirect: { name: 'DocumentsList' }
  },
  {
    name: 'App',
    path: '/app',
    component: App,
    children: [
      routesDocuments,
      routesTrackingLists,
      routesAgendas,
      routesAgendaTemplate,
      routesDocumentTemplate,
      routesUserProfile,
      routesDashboard,
      routesAdmin,
      {
        path: 'search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '@/router/views/Search.vue'),
        props: true
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: () => import(/* webpackChunkName: "calendar" */ '@/router/views/Calendar.vue')
      },
      {
        path: 'help',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '@/router/views/Help.vue')
      }, {
        path: 'error/401',
        name: 'Error401',
        component: Error401
      }]
  }, {
    path: '/addin',
    name: 'Addin',
    component: Addin,
    children: [{
      name: 'AddinDetail',
      path: ':documentId',
      component: AddinDetail,
      props: true
    }]
  }, {
    path: '/callback',
    name: 'Callback',
    component: Callback
  }, {
    path: '/logged-out',
    name: 'LoggedOut',
    component: LoggedOut
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // Wait for user before proceeding to next route
  if (!store.state.users.me) {
    store.commit('users/setMe', await repositoryUser.findById('me').catch(console.error))
  }
  // Gather information related to route
  const pageRequiresAdmin = to.matched.some(record => record.meta.pageRequiresAdmin)
  const pageRequiresGroupAdmin = to.matched.some(record => record.meta.pageRequiresGroupAdmin)
  const pageHasSettings = to.matched.some(record => record.meta.pageHasSettings)
  const user = store.state.users.me
  const impersonated = store.state.users.impersonated
  const isEnteringDetail = to.name === 'DocumentTemplateDetail' || to.name === 'AgendaTemplateDetail'
  const isLeavingDocumentTemplateDetail = from.name === 'DocumentTemplateDetail' && !(to.name === 'DocumentTemplateDetail')
  const isLeavingAgendaDetail = from.name === 'AgendaTemplateDetail' && !(to.name === 'AgendaTemplateDetail')
  const isLeavingTemplateDetail = isLeavingDocumentTemplateDetail || isLeavingAgendaDetail
  const isLeavingDocumentDetail = from.name === 'DocumentsDetail' && !(to.name === 'DocumentsDetail')
  const isEnteringAdmin = to.name === 'AdminIndex'
  // Update browser title
  document.title = `Docshare - ${titlePath(to.fullPath)}`
  if (impersonated && (pageRequiresAdmin || pageHasSettings)) {
    next({ name: 'Error401' })
  } else if (pageRequiresGroupAdmin && !(user.isAdmin || user.isGroupAdmin)) {
    next({ name: 'Error401' })
  } else if (pageRequiresAdmin && !pageRequiresGroupAdmin && !user.isAdmin) {
    next({ name: 'Error401' })
  } else if (isEnteringAdmin) {
    if (user.isAdmin) {
      next({ name: 'AdminAgendaTypesManage' })
    } else {
      next({ name: 'AdminWorkflowGroupsManage' })
    }
  } else {
    // Emit sidebar machine actions
    if (isEnteringDetail) {
      emitter.emit('TEMPLATE_VIEW')
    }
    if (isLeavingTemplateDetail) {
      emitter.emit('LEAVE_DETAIL')
    }
    if (from.name === 'Home') {
      emitter.emit('LEAVE_HOME')
    }
    if (isLeavingDocumentDetail) {
      emitter.emit('LEAVE_DOCUMENT_DETAILS')
    }
    next()
  }
})

export default router
