import { TrackingListJsonldTrackingListRead } from '@zucommunications/gsk-docshare-web-api'
import { DocumentMapper } from '@/domain/documentMapper'
import { TrackingList } from '@/domain/trackingList'

export const TrackingListMapper = ({
  deserialize: (json: TrackingListJsonldTrackingListRead): TrackingList => {
    return <TrackingList>{
      id: Number(json['@id'].replace('/api/tracking_lists/', '')),
      title: json.title,
      documents: json.documents?.length ? json.documents.map(DocumentMapper.deserializeFromTrackingList) : []
    }
  },
  serializeIri: (obj: TrackingList): string => {
    return `/api/tracking_lists/${obj.id}`
  },
  serializePost: (obj: TrackingList): Object => {
    return {
      title: obj.title || '',
      documents: obj.documents?.length ? obj.documents.map(DocumentMapper.serializeIri) : []
    }
  },
  serializePatch: (obj: TrackingList): Object => {
    return {
      title: obj.title || '',
      documents: obj.documents?.length ? obj.documents.map(DocumentMapper.serializeIri) : []
    }
  }
})
