import Vue from 'vue'
import Vuex from 'vuex'
import documents from '@/store/modules/documents'
import documentTemplates from '@/store/modules/documentTemplates'
import media from '@/store/modules/media'
import notifications from '@/store/modules/notifications'
import users from '@/store/modules/users'
import trackingLists from '@/store/modules/trackingLists'
import agendas from '@/store/modules/agendas'
import agendaTemplates from '@/store/modules/agendaTemplates'
import agendaTypes from '@/store/modules/agendaTypes'
import userSettings from '@/store/modules/userSettings'
import comments from '@/store/modules/comments'
import logEvents from '@/store/modules/logEvents'
import kpis from '@/store/modules/kpis'
import groups from '@/store/modules/groups'
import bookmarks from '@/store/modules/bookmarks'
import groupTypes from '@/store/modules/groupTypes'
import workflowGroups from '@/store/modules/workflowGroups'
import workflowTemplates from '@/store/modules/workflowTemplates'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    documents,
    agendas,
    agendaTypes,
    agendaTemplates,
    bookmarks,
    media,
    notifications,
    documentTemplates,
    users,
    trackingLists,
    userSettings,
    comments,
    kpis,
    logEvents,
    groups,
    groupTypes,
    workflowGroups,
    workflowTemplates
  }
})

// store.subscribeAction({
//   before: (action) => {
//     console.log(`before action ${action.type}`)
//   },
//   after: (action) => {
//     console.log(`after action ${action.type}`)
//   }
// })

export default store
