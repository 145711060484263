



















































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import ChipBoolean from '@/components/ChipBoolean.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DocumentDeadline from '@/components/DocumentDeadline.vue'
import DocumentOwner from '@/components/DocumentOwner.vue'
import GroupHeading from '@/components/GroupHeading.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import DocumentLeadGroup from '@/components/DocumentLeadGroup.vue'
import DocumentJointGroupsView from '@/components/DocumentJointGroupsView.vue'
import {
  BaseIcon,
  BaseIconText,
  BaseAlert,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import repository from '@/domain/userRepository'
import { useUser } from '@/composables/useUser'
import { minLength } from '@/validations'

export default defineComponent({
  props: {
    document: Object,
    state: Object,
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    BaseAlert,
    GroupHeading,
    DocumentStatusChip,
    BaseIcon,
    BaseIconText,
    DocumentOwner,
    DocumentStatus,
    DocumentDeadline,
    DocumentLeadGroup,
    DocumentJointGroupsView,
    ChipBoolean,
    ChipBlock,
    ChipUser,
    BaseButton,
    BaseCombobox
  },
  setup (props, { emit }) {
    // Check if user can progress document
    const { me } = useUser()

    const allowedUser = computed(() => {
      let result = false
      if (me.value && props.document && ((props.document.documentManager && me.value.id === props.document.documentManager.id) || (props.document.documentOwner && me.value.id === props.document.documentOwner.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    const collaboratorAdditionalSelected = ref(null)
    const collaboratorRequiredIds = computed(() => props?.state?.context?.workflowStepsRequired.map(x => x.user.id))
    const collaboratorAdditionalIds = computed(() => props?.state?.context?.workflowStepsAdditional.map(x => x.user.id))
    const searchPromise = async q => {
      let results = await repository.findByCriteria({ q })
      // Filter required collaborators
      results = results.filter(x => !collaboratorRequiredIds.value.includes(x.id))
      // Filter additional collaborators
      results = results.filter(x => !collaboratorAdditionalIds.value.includes(x.id))
      return results
    }

    const { selectText } = useUser()
    const itemText = x => selectText(x)

    const collaboratorAdditionalAppendWorkflowStep = () => {
      const workflowStep = {
        user: collaboratorAdditionalSelected.value,
        approved: false
      }
      emit('workflowStepAdditionalAdd', workflowStep)
      collaboratorAdditionalSelected.value = null
    }

    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the details tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 0) {
        focusOnContent()
      }
    })

    return {
      itemText,
      searchPromise,
      collaboratorAdditionalSelected,
      collaboratorAdditionalAppendWorkflowStep,
      minLength,
      allowedUser,
      tabContent
    }
  }
})
