interface ModuleState {
  templates: any;
  template: any;
}

const state = () => <ModuleState>({
  templates: [],
  template: {}
})

// getters
const getters = {
  getTemplatesByType: state => {
    return Array.from(state.templates.reduce((acc, val) => {
      return acc.set(val.documentType, [...acc.get(val.documentType) || [], val])
    }, new Map()))
  },
  getTemplatesByTypeOrdered: state => {
    function alpha (a, b) {
      if (a < b) return -1
      if (a > b) return 1

      return 0
    }
    function alphaName (a, b) {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1

      return 0
    }
    const templateSets: any = Array.from(state.templates.reduce((acc, val) => {
      return acc.set(val.documentType, [...acc.get(val.documentType) || [], val])
    }, new Map()))
    for (const val in templateSets) {
      templateSets[val][1] = templateSets[val][1].sort(alphaName)
    }
    return templateSets.sort(alpha)
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  setTemplates (state, value) {
    state.templates = value
  },
  setTemplate (state, value) {
    state.template = value
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
