







































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import DocumentTemplateGroup from '@/components/DocumentTemplateGroup.vue'
import TableRowWithHover from '@/components/TableRowWithHover.vue'
import { BaseIcon, BaseIconText, BaseExpansionPanel } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import repository from '@/domain/agendaTemplateRepository'

export default defineComponent({
  components: {
    LayoutContentSidebar,
    DocumentTemplateGroup,
    BaseExpansionPanel,
    BaseIcon,
    BaseIconText,
    TableRowWithHover
  },
  props: {
    packageType: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const listing = computed(() => store.state.agendaTemplates.listing)
    const loading = ref(true)

    watch(() => props.packageType, async () => {
      loading.value = true
      await repository.findByCriteria({ pagination: false, agendaTypePackageType: props.packageType }).then(x => {
        store.commit('agendaTemplates/setForListing', x)
      })
      loading.value = false
    }, { immediate: true })

    return {
      listing,
      loading
    }
  }
})
