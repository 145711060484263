import { assign, Machine } from 'xstate'
import store from '@/store'

export const notificationsMachine = Machine<any, any, any>({
  id: 'notifications',
  initial: 'loading',
  context: {
    page: 1,
    itemsPerPage: 5,
    orderDateCreated: 'desc'
  },
  states: {
    idle: {
      after: {
        30000: 'loading'
      },
      on: {
        CHANGE_PAGE: {
          target: 'loading',
          actions: 'setPage'
        }
      }
    },
    loading: {
      invoke: {
        src: 'load',
        onDone: {
          target: 'idle',
          actions: 'setResults'
        },
        onError: 'idle'
      }
    },
    archiving: {
      invoke: [{
        src: 'clearStore'
      }, {
        src: 'archive',
        onDone: {
          target: 'idle'
        },
        onError: {
          actions: ['showMessage'],
          target: 'loading'
        }
      }]
    },
    archivingFailure: {
      // show message in snackbar or alert?
    },
    reading: {
      invoke: {
        src: 'read',
        onDone: 'idle',
        onError: 'readingFailure'
      }
    },
    readingFailure: {
      // show message in snackbar or alert?
    }
  },
  on: {
    MARK_READ_ALL: {
      target: 'reading'
    },
    MARK_ARCHIVED_ALL: {
      target: 'archiving'
    }
  }
}, {
  services: {
    load: async (context, event) => {
      return store.dispatch('notifications/findAll', {
        itemsPerPage: context.itemsPerPage,
        page: context.page,
        orderDateCreated: context.orderDateCreated
      })
    },
    archive: async (context, event) => {
      return store.dispatch('notifications/archiveAll')
    },
    clearStore: async (context, event) => {
      return store.dispatch('notifications/clearStore')
    },
    read: async (context, event) => {
      return store.dispatch('notifications/readAll')
    }
  },
  actions: {
    showMessage: () => {},
    setResults: assign({
      resultsTotal: (context, event: any) => event.data && event.data.data ? +event.data.data['hydra:totalItems'] : 0,
      pageCount: (context, event: any) => event.data && event.data.data ? Math.ceil(+event.data.data['hydra:totalItems'] / context.itemsPerPage) : 1
    }),
    setPage: assign({
      page: (context, event: any) => event.payload
    })
  }
})
