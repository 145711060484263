import { WorkflowTemplateApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { WorkflowTemplate } from '@/domain/workflowTemplate'
import { WorkflowTemplateMapper } from '@/domain/workflowTemplateMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new WorkflowTemplateApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, userGroup?: string, userGroup2?: Array<string>, workflowType?: string, workflowType2?: Array<string>, workflowTemplateDocumentsTemplateSpId?: number, workflowTemplateDocumentsTemplateSpId2?: Array<number>, userGroupType?: string, userGroupType2?: Array<string>, isDefault?: boolean, isStandardList?: boolean, options?: any
}

class WorkflowTemplateRepository implements IFindData<WorkflowTemplate>, ICrudData<WorkflowTemplate>, ISearchData<WorkflowTemplate> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getWorkflowTemplateItem(id)
      return WorkflowTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (entity: WorkflowTemplate) {
    try {
      const params = WorkflowTemplateMapper.serialize(entity)
      const response = await api.postWorkflowTemplateCollection(params)
      return WorkflowTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (entity: WorkflowTemplate) {
    try {
      const response = await api.patchWorkflowTemplateItem(String(entity.id), WorkflowTemplateMapper.serialize(entity))
      return WorkflowTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteWorkflowTemplateItem(id)
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 5, userGroup, userGroup2, workflowType, workflowType2, userGroupType, userGroupType2, isDefault }: SearchParams) {
    try {
      const response = await api.getWorkflowTemplateCollection(
        page,
        itemsPerPage,
        userGroup,
        userGroup2,
        workflowType,
        workflowType2,
        undefined,
        undefined,
        userGroupType,
        userGroupType2,
        isDefault,
        undefined,
        undefined
      )
      return <SearchResult<WorkflowTemplate>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => WorkflowTemplateMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new WorkflowTemplateRepository()
