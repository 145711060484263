

























































































































































































import { defineComponent, computed, ref } from '@vue/composition-api'
import { BaseIconText, BaseIcon, BaseMenuContent, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import statusColor from '@/utils/helpers/statusColor'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  name: 'document-summary-card',
  components: {
    BaseMenuContent,
    BaseButton,
    BaseIcon,
    BaseIconText,
    DocumentStatusChip
  },
  props: {
    documentId: {
      type: Number
    },
    status: {
      type: String,
      required: true
    },
    requiresMyInput: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    documentNumber: Number,
    commentCount: {
      type: Number,
      default: 0
    },
    templateType: String,
    dueDate: Date,
    documentOwner: Object,
    leadUserGroup: Object,
    jointUserGroups: Array,
    currentSpUrl: {
      type: String,
      required: true
    },
    created: Date
  },
  setup (props, { root }) {
    const cardExpanded = ref(false)
    const iconSizes = {
      xs: '2.125rem',
      sm: '2.125rem',
      md: '1.25rem',
      lg: '1.25rem',
      xl: '1.25rem'
    }

    const toggleContents = () => (
      cardExpanded.value = !cardExpanded.value
    )

    const actionIconSize = computed(() => iconSizes[root.$vuetify.breakpoint.name])

    const userGroups = computed(() => {
      const groups = props.leadUserGroup ? [props.leadUserGroup.formattedTitle] : []
      if (props.jointUserGroups && props.jointUserGroups.length) {
        groups.push(...props.jointUserGroups.map((jointGroup: any) => jointGroup.formattedTitle))
      }

      return groups
    })

    const { selectText } = useUser()

    return {
      statusColor,
      toggleContents,
      cardExpanded,
      actionIconSize,
      userGroups,
      selectText
    }
  }
})
