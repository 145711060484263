export const titlePath = (fullPath: string) => {
  // Remove url parameters
  const refinedFullPath = fullPath.split('?')[0]
  return refinedFullPath
    .replace('/app/', '')
    .split('/')
    .map(x => {
      const parts = x.split('-')
      return parts.map(y => y.charAt(0).toUpperCase() + y.slice(1)).join(' ')
    })
    .join(' - ')
}
