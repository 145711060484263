



























import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  components: {
    DialogConfirm,
    BaseIcon,
    BaseButton
  },
  props: {
    invoke: {
      type: Function,
      required: true
    },
    done: {
      type: Function,
      required: true
    },
    confirmTitle: {
      type: String,
      required: true
    },
    confirmMessage: {
      type: String,
      required: true
    }
  },
  setup (props: any) {
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: async () => props.invoke()
      },
      actions: {
        onDoneEnter: () => props.done()
      }
    })
    return {
      state, send
    }
  }
})
