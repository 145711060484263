import { computed } from '@vue/composition-api'
import store from '@/store'
import { MediaBlob } from '@/domain/media'
import repository from '@/domain/mediaRepository'

export function useMedia () {
  const findMediaBlob = id => (
    repository.findMediaBlobById(id).then(x => {
      store.commit('media/addMediaBlobToCache', x)
    })
  )
  const removeMediaBlob = () => {
    store.commit('media/removeMediaBlobFromCache')
  }
  const mediaBlob = computed<MediaBlob>(() => store.state.media.mediaBlob)
  return {
    findMediaBlob,
    removeMediaBlob,
    mediaBlob
  }
}
