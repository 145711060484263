




























import { defineComponent, inject } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { useService } from 'xstate-vue2'
import { Interpreter } from 'xstate'
import { DocumentContext } from '@/machines/documentMachine'
import { BaseAlert } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  components: {
    BaseCombobox,
    BaseAlert
  },
  setup (_, { root }) {
    const { selectText } = useUser()
    const itemText = x => selectText(x)
    const service = inject('documentMachineService') as Interpreter<DocumentContext, any, any, any>
    const { state, send } = useService(service)
    return {
      state,
      send,
      itemText
    }
  }
})
