




































































import { defineComponent, InjectionKey, provide, Ref } from '@vue/composition-api'
import { useUser } from '@/composables/useUser'
import DocshareIcon from '@/assets/docshareIcon.svg'
import Auth from '@/components/Auth.vue'
import { EventData, State } from 'xstate'
import { Event } from 'xstate/lib/types'
import { MachineEvent, sidebarMachine } from '@/machines/sidebarMachine'
import { useMachine } from 'xstate-vue2'
import emitter from '@/utils/emitter'

export const sidebarMachineStateKey: InjectionKey<Ref<State<any, any>>> = Symbol('sidebarMachineState')
export const sidebarMachineSendKey: InjectionKey<(event: Event<MachineEvent>, payload?: EventData) => State<any>> = Symbol('sidebarMachineSend')

export default defineComponent({
  name: 'AppOutlet',
  components: {
    DocshareIcon,
    Auth
  },
  setup (_, { root }) {
    const router = root.$router
    const { me } = useUser()
    const { state, send } = useMachine(sidebarMachine)

    provide(sidebarMachineStateKey, state)
    provide(sidebarMachineSendKey, send)

    emitter.on('TEMPLATE_VIEW', () => send('TEMPLATE_VIEW'))
    emitter.on('LEAVE_DETAIL', () => send('LEAVE_DETAIL'))
    emitter.on('LEAVE_HOME', () => send('LEAVE_HOME'))
    emitter.on('LEAVE_DOCUMENT_DETAILS', () => send('LEAVE_DOCUMENT_DETAILS'))

    const reloadPage = () => {
      window.localStorage.clear()
      router.go(0)
    }

    return {
      me,
      reloadPage
    }
  }
})
