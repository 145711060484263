












































































































































































































import { defineComponent, computed, inject } from '@vue/composition-api'
import { usePoll } from '@/composables/usePoll'
import { BaseIcon, BaseMenuQuickLinks } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import DocshareLogo from '@/assets/docshare.svg'
import { useUser } from '@/composables/useUser'
import AppBarListGroupUserDelegation from '@/components/AppBarListGroupUserDelegation.vue'
import AppBarListGroupPages from '@/components/AppBarListGroupPages.vue'
import AppBarListGroupSettings from '@/components/AppBarListGroupSettings.vue'
import BookmarkBar from '@/components/BookmarkBar.vue'
import { sidebarMachineSendKey, sidebarMachineStateKey } from '@/router/views/AppOutlet.vue'

export default defineComponent({
  name: 'app-bar',
  components: {
    BaseIcon,
    BaseMenuQuickLinks,
    DocshareLogo,
    AppBarListGroupUserDelegation,
    AppBarListGroupPages,
    AppBarListGroupSettings,
    BookmarkBar
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store

    const navigationItems = [
      { to: { name: 'Search' }, text: 'Search', iconName: 'mdiMagnify', label: 'Search button: Click to open Search.' },
      { href: 'https://skgov.sharepoint.com/sites/docshare-trnassetsSP/SitePages/DatesCalendars.aspx', target: '_blank', text: 'Calendar', iconName: 'mdiCalendar', label: 'Calendar button: Click to open calendar.' },
      { to: { name: 'Dashboard' }, text: 'Dashbaord', iconName: 'mdiChartBar', label: 'Dashboard button: Click to open Ministry Performance data.' },
      { href: 'https://skgov.sharepoint.com/sites/docshare-trnassetsSP/SitePages/FAQ.aspx', target: '_blank', text: 'Help', iconName: 'mdiHelp', label: 'Help button.' }
    ]

    const isMobile = computed(() => root.$vuetify.breakpoint.xs)
    const isTablet = computed(() => root.$vuetify.breakpoint.sm)

    usePoll(() => store.dispatch('notifications/notificationStatus'), 30000)

    const { me } = useUser()

    const notificationIndicator = computed(() => store.state.notifications.notificationSeenStatus)
    const notificationAriaLabel = computed(() => {
      const label = 'Notification Button: Click to open Notifications in application sidebar.'
      const unread = notificationIndicator.value === true ? ' You have unread notifications.' : ''

      return label + unread
    })

    const sendSidebar = inject(sidebarMachineSendKey)
    const stateSidebar = inject(sidebarMachineStateKey)

    const showBookmarks = computed(() => me.value.isAdmin)

    return {
      stateSidebar,
      sendSidebar,
      navigationItems,
      isMobile,
      isTablet,
      notificationIndicator,
      notificationAriaLabel,
      me,
      showBookmarks
    }
  }
})
