import { RouteConfig } from 'vue-router'

const routesAdminIndex: RouteConfig = {
  name: 'AdminIndex',
  path: '',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminIndex.vue'),
  meta: {
    pageRequiresGroupAdmin: true
  }
}
const routesAdminGroups: RouteConfig = {
  name: 'AdminGroupsManage',
  path: 'ministries/manage',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupsManage.vue'),
  redirect: { name: 'AdminGroupsManageList' },
  children: [{
    name: 'AdminGroupsManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupsManageList.vue')
  }, {
    name: 'AdminGroupsManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupsManageDetail.vue')
  }, {
    name: 'AdminGroupsManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupsManageCreate.vue')
  }]
}
const routesAdminGroupTypes: RouteConfig = {
  name: 'AdminGroupTypesManage',
  path: 'ministries/types',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupTypesManage.vue'),
  redirect: { name: 'AdminGroupTypesManageList' },
  children: [{
    name: 'AdminGroupTypesManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupTypesManageList.vue')
  }, {
    name: 'AdminGroupTypesManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupTypesManageCreate.vue')
  }, {
    name: 'AdminGroupTypesManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminGroupTypesManageDetail.vue')
  }]
}
const routesAdminAgendaTypes: RouteConfig = {
  name: 'AdminAgendaTypesManage',
  path: 'agendas/types',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/Router.vue'),
  redirect: { name: 'AdminAgendaTypesManageList' },
  children: [{
    name: 'AdminAgendaTypesManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminAgendaTypesManageList.vue')
  }, {
    name: 'AdminAgendaTypesManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminAgendaTypesManageDetail.vue')
  }, {
    name: 'AdminAgendaTypesManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminAgendaTypesManageDetail.vue')
  }]
}
const routesAdminAgendaGroups: RouteConfig = {
  name: 'AdminAgendaGroupsManage',
  path: 'agendas/groups',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/Router.vue'),
  redirect: { name: 'AdminAgendaGroupsManageList' },
  children: [{
    name: 'AdminAgendaGroupsManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminAgendaGroupsManageList.vue')
  }, {
    name: 'AdminAgendaGroupsManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminAgendaGroupsManageDetail.vue')
  }, {
    name: 'AdminAgendaGroupsManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminAgendaGroupsManageDetail.vue')
  }]
}
const routesAdminUsers: RouteConfig = {
  name: 'AdminUsersManage',
  path: 'users/manage',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminUsersManage.vue'),
  redirect: { name: 'AdminUsersManageList' },
  children: [{
    name: 'AdminUsersManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminUsersManageList.vue')
  }, {
    name: 'AdminUsersManageInvite',
    path: 'invite',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminUsersManageInvite.vue')
  }, {
    name: 'AdminUsersManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminUsersManageDetail.vue')
  }]
}
const routesAdminUsersImpersonate: RouteConfig = {
  name: 'AdminUsersImpersonate',
  path: 'users/impersonate',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminUsersImpersonate.vue')
}
const routesAdminLogEvent: RouteConfig = {
  name: 'AdminLogEventsManage',
  path: 'troubleshooting/logs',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminLogEventsManage.vue'),
  redirect: { name: 'AdminLogEventsManageList' },
  children: [{
    name: 'AdminLogEventsManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminLogEventsManageList.vue'),
    meta: {
      title: 'Event Logs'
    }
  }, {
    name: 'AdminLogEventsManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminLogEventsManageDetail.vue')
  }]
}
const routesAdminWorkflowGroups: RouteConfig = {
  name: 'AdminWorkflowGroupsManage',
  path: 'workflows/groups',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowGroupsManage.vue'),
  meta: {
    pageRequiresGroupAdmin: true
  },
  redirect: { name: 'AdminWorkflowGroupsManageList' },
  children: [{
    name: 'AdminWorkflowGroupsManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowGroupsManageList.vue')
  }, {
    name: 'AdminWorkflowGroupsManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowGroupsManageCreate.vue')
  }, {
    name: 'AdminWorkflowGroupsManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowGroupsManageDetail.vue')
  }]
}
const routesAdminWorkflowTemplates: RouteConfig = {
  name: 'AdminWorkflowTemplatesManage',
  path: 'workflows/templates',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowTemplatesManage.vue'),
  meta: {
    pageRequiresGroupAdmin: true
  },
  redirect: { name: 'AdminWorkflowTemplatesManageList' },
  children: [{
    name: 'AdminWorkflowTemplatesManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowTemplatesManageList.vue')
  }, {
    name: 'AdminWorkflowTemplatesManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowTemplatesManageCreate.vue')
  }, {
    name: 'AdminWorkflowTemplatesManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminWorkflowTemplatesManageDetail.vue')
  }]
}

const routesAdminPackages: RouteConfig = {
  name: 'AdminPackagesManage',
  path: 'troubleshooting/packages',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/Router.vue'),
  redirect: { name: 'AdminPackagesManageList' },
  children: [{
    name: 'AdminPackagesManageList',
    path: 'list',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminPackagesManageList.vue')
  }, {
    name: 'AdminPackagesManageCreate',
    path: 'create',
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminPackagesManageDetail.vue')
  }, {
    name: 'AdminPackagesManageDetail',
    path: ':id',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminPackagesManageDetail.vue')
  }]
}

export const routesAdmin: RouteConfig = {
  path: 'admin',
  component: () => import(/* webpackChunkName: "admin" */ '@/router/views/AdminBase.vue'),
  meta: {
    pageRequiresAdmin: true
  },
  children: [
    routesAdminIndex,
    routesAdminAgendaTypes,
    routesAdminAgendaGroups,
    routesAdminGroups,
    routesAdminGroupTypes,
    routesAdminUsers,
    routesAdminUsersImpersonate,
    routesAdminLogEvent,
    routesAdminPackages,
    routesAdminWorkflowGroups,
    routesAdminWorkflowTemplates
  ]
}
