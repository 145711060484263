


























import { defineComponent } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import FormUserSettingsDelegate from '@/components/forms/FormUserSettingsDelegate.vue'
import FormUserSettingsNotificationsDocshare from '@/components/forms/FormUserSettingsNotificationsDocshare.vue'
import FormUserSettingsNotificationsEmail from '@/components/forms/FormUserSettingsNotificationsEmail.vue'
import FormUserSettingsNotificationsTextSms from '@/components/forms/FormUserSettingsNotificationsTextSms.vue'
import { useUserSettings } from '@/composables/useUserSettings'
import FormUserSettingsDashboardFilter from '@/components/forms/FormUserSettingsDashboardFilter.vue'

export default defineComponent({
  components: {
    FormUserSettingsDashboardFilter,
    LayoutContentSidebar,
    FormUserSettingsDelegate,
    FormUserSettingsNotificationsDocshare,
    FormUserSettingsNotificationsEmail,
    FormUserSettingsNotificationsTextSms
  },
  setup () {
    const { findForDetail } = useUserSettings()
    findForDetail('me')
  }
})
