









































import { defineComponent, computed } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'

export default defineComponent({
  name: 'document-extended-content-table',
  components: {
    BaseIcon,
    ChipBlock,
    ChipUser
  },
  props: {
    status: {
      type: String,
      required: true
    },
    collaboration: Object,
    approval: Object
  },
  setup (props) {
    const userHeader = computed(() => {
      switch (props.status) {
        case 'collaboration-in-progress':
          return 'Collaborators'
        case 'approval-in-progress':
          return 'Approvers'
        default:
          return 'Unknown'
      }
    })

    const headers = computed(() => ([
      { text: userHeader.value, value: 'user' },
      { text: 'Commented', sortable: false, value: 'hasComment' },
      { text: 'Completed', sortable: false, value: 'approved' },
      { text: 'Date Completed', sortable: false, value: 'dateApproved' }
    ]))

    const steps = computed(() => {
      const steps: any = []
      let workflowStepsRequired = []
      let workflowStepsAdditional = []

      if (props.status === 'collaboration-in-progress' && props.collaboration) {
        ({ workflowStepsRequired, workflowStepsAdditional } = props.collaboration)
      } else if (props.status === 'approval-in-progress' && props.approval) {
        ({ workflowStepsRequired, workflowStepsAdditional } = props.approval)
      }

      steps.push(...workflowStepsRequired)
      steps.push(...workflowStepsAdditional)

      return steps
    })

    const tableHeight = computed(() => {
      return steps.value.length < 3 ? 'auto' : '10.625rem'
    })

    return {
      headers,
      steps,
      tableHeight
    }
  }
})
