import { TrackingList } from '@/domain/trackingList'

interface ModuleState {
  detail?: TrackingList
}

// state
const state = () => <ModuleState>({
  detail: undefined,
  listing: [],
  listingSelectedId: undefined
})

// getters
const getters = {
  getListingSelected: (state) => {
    return state.listing?.find(x => x.id === state.listingSelectedId)
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  setForListing (state, value: TrackingList[]) {
    state.listing = value
  },
  setForListingSelectedFirst (state, value: TrackingList[]) {
    if (value && value[0] && !state.listingSelectedId) {
      state.listingSelectedId = value[0].id
    }
  },
  setForListingSelectedId (state, value: TrackingList) {
    state.listingSelectedId = value.id
  },
  setForListingRemoveDocument (state, value: number) {
    state.listing = state.listing.map(list => {
      list.documents = list.documents.filter(x => x.id !== value)
      return list
    })
  },
  setForDetail (state, value) {
    state.detail = value
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
