import { Machine, assign, interpret } from 'xstate'
import { useService } from 'xstate-vue2'
import agendaRepository from '@/domain/agendaRepository'
import trackingListRepository from '@/domain/trackingListRepository'
import agendaTypeRepository from '@/domain/agendaTypeRepository'
import agendaTemplateRepository from '@/domain/agendaTemplateRepository'
import userGroupRepository from '@/domain/userGroupRepository'
import userGroupTypeRepository from '@/domain/userGroupTypeRepository'
import { UserGroupMapper } from '@/domain/userGroupMapper'
import userRepository from '@/domain/userRepository'
import { UserGroup } from '@/domain/userGroup'
import workflowGroupRepository from '@/domain/workflowGroupRepository'
import { computed } from '@vue/composition-api'
import { User } from '@/domain/user'
import store from '@/store'
import workflowTemplateRepository from '@/domain/workflowTemplateRepository'
import logEventRepository from '@/domain/logEventRepository'

export const sortMapBuild = (sort: string[], order: boolean[]): Object => {
  return sort.reduce((acc, val, index) => {
    acc[val] = order[index]
    return acc
  }, {})
}
export const sortMapExtract = (sortMap: Object, prop: string): 'asc' | 'desc' | undefined => {
  return prop in sortMap ? (sortMap[prop] ? 'desc' : 'asc') : undefined
}

export const tableMachine = Machine<any>({
  initial: 'idle',
  context: {
    results: [],
    resultsTotal: 0,
    pageCount: 0,
    page: 1,
    itemsPerPage: 10,
    searchQuery: undefined,
    sort: [],
    order: [],
    error: ''
  },
  states: {
    idle: {
      on: {
        SUBMIT: {
          target: 'searching',
          actions: ['resetPage']
        },
        CHANGE_PAGE: {
          target: 'searching',
          actions: ['setPage']
        },
        CHANGE_SEARCH_QUERY: {
          target: 'searching',
          actions: ['setSearch', 'resetPage']
        },
        CHANGE_ITEMS_PER_PAGE: {
          target: 'searching',
          actions: ['setItemsPerPage', 'resetPage']
        },
        CHANGE_OPTIONS: {
          target: 'searching',
          actions: ['setOptions']
        },
        CHANGE_PROPERTY: {
          target: 'searching',
          actions: ['setProperty', 'resetPage']
        }
      }
    },
    searching: {
      invoke: {
        src: 'search',
        onDone: {
          target: 'idle',
          actions: ['setResults', 'setResultsMeta']
        },
        onError: {
          target: 'idle',
          actions: ['setErrors']
        }
      }
    }
  }
}, {
  services: {
    search: async () => []
  },
  actions: {
    log: (context, event) => {
      console.log(context, event)
    },
    setResultsMeta: assign({
      resultsTotal: (context, event: any) => event.data.resultsTotal,
      pageCount: (context, event: any) => event.data.pageCount
    }),
    setResults: assign({
      results: (context, event) => event.data.results
    }),
    setPage: assign({
      page: (context, event: any) => event.payload
    }),
    setItemsPerPage: assign({
      itemsPerPage: (context, event: any) => event.payload
    }),
    setSearch: assign({
      searchQuery: (context, event: any) => event.payload || undefined
    }),
    setOptions: assign({
      sort: (context, event: any) => event.payload.sortBy,
      order: (context, event: any) => event.payload.sortDesc
    }),
    setProperty: assign((context, event) => {
      if (event.key) {
        if (typeof event.value !== 'boolean') {
          return {
            [event.key]: event.value || undefined
          }
        }
        return {
          [event.key]: event.value
        }
      }
      return {}
    }),
    resetPage: assign({
      page: 1
    })
  }
})

// MACHINE INSTANCES

// For each necessary instance, we create a service using the machine. Then, create a custom service hook for this machine,
// that takes the service previously created. This way you don't have to import on every component the useService hook and the service created.

// Manage Agendas/Document Bundles
const managePackagesConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      const sortMap = sortMapBuild(context.sort, context.order)

      return await agendaRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        orderStatus: sortMapExtract(sortMap, 'status'),
        orderTitle: sortMapExtract(sortMap, 'title'),
        orderAgendaGroupAgendaTypeTitle: sortMapExtract(sortMap, 'template.agendaType.title'),
        orderMeetingDate: sortMapExtract(sortMap, 'meetingDate'),
        orderIsActive: sortMapExtract(sortMap, 'isActive'),
        q: context.searchQuery,
        status2: context.searchStatuses,
        agendaGroupAgendaType2: context.searchTypes,
        agendaGroupAgendaTypePackageType: context.packageType
      })
    }
  }
}, {
  ...tableMachine.context,
  searchStatuses: [],
  searchTypes: [],
  packageType: 'agenda'
})
const managePackagesService = interpret(managePackagesConfig)
export const useManagePackagesMachine = () => {
  return useService(managePackagesService.start())
}

// Manage Tracking Lists
const manageTrackingListsConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      return await trackingListRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        q: context.searchQuery
      })
    }
  }
})
const manageTrackingListsService = interpret(manageTrackingListsConfig)
export const useTrackingListsMachine = () => {
  return useService(manageTrackingListsService.start())
}

// Admin - Agenda Management
const manageAgendaTypesConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      const sortMap = sortMapBuild(context.sort, context.order)
      return await agendaTypeRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        q: context.searchQuery,
        orderTitle: sortMapExtract(sortMap, 'title'),
        orderPackageType: sortMapExtract(sortMap, 'packageType')
      })
    }
  }
})
const manageAgendaTypesService = interpret(manageAgendaTypesConfig)
export const useAgendaTypesMachine = () => {
  return useService(manageAgendaTypesService.start())
}

// Admin - Group Management
const manageAgendaGroupsConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      const sortMap = sortMapBuild(context.sort, context.order)
      return await agendaTemplateRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        pagination: true,
        q: context.searchQuery,
        orderTitle: sortMapExtract(sortMap, 'title'),
        orderAgendaTypeTitle: sortMapExtract(sortMap, 'agendaType.title'),
        orderDocumentFolderName: sortMapExtract(sortMap, 'documentFolderName')
      })
    }
  }
})
const manageAgendaGroupsService = interpret(manageAgendaGroupsConfig)
export const useAgendaGroupsMachine = () => {
  return useService(manageAgendaGroupsService.start())
}

// Admin - Manage Ministries
const manageMinistriesConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      return await userGroupRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        q: context.searchQuery
      })
    }
  }
})
const manageMinistriesService = interpret(manageMinistriesConfig)
export const useMinistriesMachine = () => {
  return useService(manageMinistriesService.start())
}

// Admin - Manage Ministry Types
const manageMinistryTypesConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      return await userGroupTypeRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        q: context.searchQuery
      })
    }
  }
})
const manageMinistryTypesService = interpret(manageMinistryTypesConfig)
export const useMinistryTypesMachine = () => {
  return useService(manageMinistryTypesService.start())
}

// Manage Users
const manageUsersConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      let userGroup, userGroup2
      const sortMap = sortMapBuild(context.sort, context.order)

      if (context.userGroup && context.userGroup.length) {
        if (context.userGroup.length > 1) {
          userGroup2 = context.userGroup.map(x => UserGroupMapper.serializeIri(x))
        } else {
          userGroup = UserGroupMapper.serializeIri(context.userGroup[0])
        }
      }

      return await userRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        userGroup,
        userGroup2,
        active: context.isActive,
        c: context.searchQuery,
        orderGivenName: sortMapExtract(sortMap, 'name'),
        orderSurName: sortMapExtract(sortMap, 'name'),
        orderEmail: sortMapExtract(sortMap, 'email'),
        orderActive: sortMapExtract(sortMap, 'isActive')
      })
    }
  }
}, {
  ...tableMachine.context,
  userGroup: [],
  isActive: undefined
})
const manageUsersService = interpret(manageUsersConfig)
export const useUsersMachine = () => {
  return useService(manageUsersService.start())
}

// Manage Workflow Groups
const manageWorkflowGroupsConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      let userGroup, userGroup2, userGroupType, userGroupType2, workflowType, workflowType2
      const me = computed<User>(() => store.state.users.me)
      const isGroupAdmin = computed(() => Boolean(me.value.isGroupAdmin && !me.value.isAdmin))

      if (isGroupAdmin.value) {
        if (me.value.groupMembership && me.value.groupMembership.length) {
          if (me.value.groupMembership.length > 1) {
            userGroup2 = me.value.groupMembership.map(x => UserGroupMapper.serializeIri(x.group as UserGroup))
          } else {
            userGroup = UserGroupMapper.serializeIri(me.value.groupMembership[0].group as UserGroup)
          }
        }
      } else {
        if (context.userGroup && context.userGroup.length) {
          if (context.userGroup.length > 1) {
            userGroup2 = context.userGroup.map(x => UserGroupMapper.serializeIri(x))
          } else {
            userGroup = UserGroupMapper.serializeIri(context.userGroup[0])
          }
        }
      }

      if (context.userGroupType && context.userGroupType.length) {
        if (context.userGroupType.length > 1) {
          userGroupType2 = context.userGroupType
        } else {
          userGroupType = context.userGroupType[0]
        }
      }

      if (context.workflowType && context.workflowType.length) {
        if (context.workflowType.length > 1) {
          workflowType2 = context.workflowType
        } else {
          workflowType = context.workflowType[0]
        }
      }

      const sortMap = sortMapBuild(context.sort, context.order)
      return await workflowGroupRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        userGroup,
        userGroup2,
        userGroupType,
        userGroupType2,
        title: context.searchQuery,
        critical: context.critical,
        orderTitle: sortMapExtract(sortMap, 'title'),
        orderCritical: sortMapExtract(sortMap, 'critical'),
        workflowType,
        workflowType2
      })
    }
  }
}, {
  ...tableMachine.context,
  critical: undefined,
  workflowType: undefined,
  userGroup: [],
  userGroupType: []
})
const manageWorkflowGroupsService = interpret(manageWorkflowGroupsConfig)
export const useWorkflowGroupsMachine = () => {
  return useService(manageWorkflowGroupsService.start())
}

// Manage Workflow Templates
const manageWorkflowTemplatesConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      let userGroup, userGroup2, userGroupType, userGroupType2, workflowType, workflowType2
      const me = computed<User>(() => store.state.users.me)
      const isGroupAdmin = computed(() => Boolean(me.value.isGroupAdmin && !me.value.isAdmin))

      if (isGroupAdmin.value) {
        if (me.value.groupMembership && me.value.groupMembership.length) {
          if (me.value.groupMembership.length > 1) {
            userGroup2 = me.value.groupMembership.map(x => UserGroupMapper.serializeIri(x.group as UserGroup))
          } else {
            userGroup = UserGroupMapper.serializeIri(me.value.groupMembership[0].group as UserGroup)
          }
        }
      } else {
        if (context.userGroup && context.userGroup.length) {
          if (context.userGroup.length > 1) {
            userGroup2 = context.userGroup.map(x => UserGroupMapper.serializeIri(x))
          } else {
            userGroup = UserGroupMapper.serializeIri(context.userGroup[0])
          }
        }
      }

      if (context.userGroupType && context.userGroupType.length) {
        if (context.userGroupType.length > 1) {
          userGroupType2 = context.userGroupType
        } else {
          userGroupType = context.userGroupType[0]
        }
      }

      if (context.workflowType && context.workflowType.length) {
        if (context.workflowType.length > 1) {
          workflowType2 = context.workflowType
        } else {
          workflowType = context.workflowType[0]
        }
      }

      return await workflowTemplateRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        userGroup,
        userGroup2,
        userGroupType,
        userGroupType2,
        workflowType,
        workflowType2,
        isDefault: context.isDefault
      })
    }
  }
}, {
  ...tableMachine.context,
  userGroup: [],
  userGroupType: [],
  workflowType: [],
  isDefault: undefined
})
const manageWorkflowTemplatesService = interpret(manageWorkflowTemplatesConfig)
export const useWorkflowTemplatesMachine = () => {
  return useService(manageWorkflowTemplatesService.start())
}

// Logs
const logConfig = tableMachine.withConfig({
  services: {
    search: async (context: any) => {
      const sortMap = sortMapBuild(context.sort, context.order)
      return await logEventRepository.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        q: context.searchQuery,
        level2: context.logEventLevel,
        orderEvent: sortMapExtract(sortMap, 'event'),
        orderLevel: sortMapExtract(sortMap, 'level'),
        orderSubject: sortMapExtract(sortMap, 'subject')
      })
    }
  }
}, {
  ...tableMachine.context,
  logEventLevel: []
})
const logService = interpret(logConfig)
export const uselogMachine = () => {
  return useService(logService.start())
}
