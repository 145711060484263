import {
  CommentApi,
  DocumentApi,
  CommentJsonldCommentWrite,
  CommentJsonldCommentReply,
  CommentCommentWrite
} from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { CommentMapper } from '@/domain/commentMapper'
import { Comment } from '@/domain/comment'
import RepositoryError from '@/domain/repositoryError'

export interface CommentState {
  commentsForAddin?: [Comment] | []
}

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL

const api = new CommentApi(undefined, BASE_PATH, axios)
const documentApi = new DocumentApi(undefined, BASE_PATH, axios)

const state = () => <CommentState>({
  commentsForAddin: []
})

const updateComment = (arr, newComment) => {
  const index = arr.findIndex(comment => comment.id === newComment.id)

  if (index >= 0) {
    arr[index] = newComment
  } else {
    arr.forEach((comment, index) => {
      if (comment.replies && comment.replies.length) {
        arr[index].replies = updateComment(comment.replies, newComment)
      }
    })
  }

  return arr
}
const removeComment = (arr, removedCommentId) => {
  const index = arr.findIndex(comment => comment.id === removedCommentId)

  if (index >= 0) {
    arr = arr.filter(comment => comment.id !== removedCommentId)
  } else {
    arr.forEach((comment, index) => {
      if (comment.replies && comment.replies.length) {
        arr[index].replies = removeComment(comment.replies, removedCommentId)
      }
    })
  }

  return arr
}

// getters
const getters = {
}

// actions
const actions = {
  async findAddinComments ({ commit }, params) {
    try {
      // Clear comments before the next documents comments are returned
      commit('setCommentsForAddin', [])
      const response = await api.getCommentCollection(
        params.page,
        params.itemsPerPage,
        undefined,
        undefined,
        params.id,
        undefined,
        false
      )
      const comments = response.data['hydra:member'].map(x => CommentMapper.deserialize(x))
      commit('setCommentsForAddin', comments)
      return { comments, total: response.data['hydra:totalItems'] || 0 }
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async updateDocumentCommentsAsSeen ({ commit }, id) {
    try {
      const response = await documentApi.postUpdateLastSeenDocumentCommentsDocumentItem(id, {})
      return response.data['hydra:member']
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async create ({ commit }, params: CommentJsonldCommentWrite) {
    try {
      const response = await api.postCommentCollection(params)
      const comment = CommentMapper.deserialize(response.data)
      commit('addCommentForAddin', comment)
      return comment
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async createReply ({ commit }, params: { id: string, content: CommentJsonldCommentReply }) {
    const { id, content } = params
    try {
      const response = await api.postReplyCommentItem(id, content)
      const comment = CommentMapper.deserialize(response.data)
      commit('updateCommentForAddin', comment)
      return comment
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async update ({ commit }, params: { id: string, content: CommentCommentWrite }) {
    const { id, content } = params
    try {
      const response = await api.patchCommentItem(id, content)
      const comment = CommentMapper.deserialize(response.data)
      commit('updateCommentForAddin', comment)
      return comment
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async delete ({ commit }, params: { id: string, hasReply: boolean }) {
    const { id, hasReply } = params
    try {
      const response = await api.deleteCommentItem(id)
      if (!hasReply) {
        commit('removeCommentForAddin', id)
      } else {
        commit('removeParentCommentForAddin', id)
      }
      return response
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async updateResolve ({ commit }, id) {
    try {
      const response = await api.postResolveCommentCommentItem(id, {})
      const comment = CommentMapper.deserialize(response.data)
      commit('updateCommentForAddin', comment)
      return comment
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async updateUnresolve ({ commit }, id) {
    try {
      const response = await api.postUnresolveCommentCommentItem(id, {})
      const comment = CommentMapper.deserialize(response.data)
      commit('updateCommentForAddin', comment)
      return comment
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

// mutations
const mutations = {
  setCommentsForAddin (state, value) {
    state.commentsForAddin = value
  },
  addCommentForAddin (state, value) {
    state.commentsForAddin.push(value)
  },
  updateCommentForAddin (state, value) {
    state.commentsForAddin = updateComment(state.commentsForAddin, value)
  },
  removeCommentForAddin (state, value) {
    state.commentsForAddin = removeComment(state.commentsForAddin, value)
  },
  removeParentCommentForAddin (state, value) {
    const index = state.commentsForAddin.findIndex(comment => comment.id === value)
    const comment = state.commentsForAddin[index]
    comment.content = '[Removed]'
    state.commentsForAddin[index] = comment
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
