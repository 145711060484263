









import { defineComponent } from '@vue/composition-api'
import LayoutNavContent from '@/router/layouts/LayoutNavContent.vue'

export default defineComponent({
  components: {
    LayoutNavContent
  }
})
