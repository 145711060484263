


















































































































































































































































































































































import { defineComponent, computed, watch, provide, ref, onMounted, inject } from '@vue/composition-api'
import {
  BaseTabs,
  BaseButton,
  BaseIcon
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import OverlayLoading from '@/components/OverlayLoading.vue'
import AddinComments from '@/components/AddinComments.vue'
import AddinAttachments from '@/components/AddinAttachments.vue'
import AddinDetailsDraft from '@/components/AddinDetailsDraft.vue'
import AddinDetailsMoved from '@/components/AddinDetailsMoved.vue'
import AddinDetailsCollaborationMoving from '@/components/AddinDetailsCollaborationMoving.vue'
import AddinDetailsCollaborationInProgress from '@/components/AddinDetailsCollaborationInProgress.vue'
import AddinDetailsApprovalMoving from '@/components/AddinDetailsApprovalMoving.vue'
import AddinDetailsApprovalInProgress from '@/components/AddinDetailsApprovalInProgress.vue'
import AddinApproved from '@/components/AddinApproved.vue'
import { useMachine } from 'xstate-vue2'
import { documentMachine } from '@/machines/documentMachine'
import { useUser } from '@/composables/useUser'
import AddinArchived from '@/components/AddinArchived.vue'
import { TrackingList } from '@/domain/trackingList'
import repositoryTrackingList from '@/domain/trackingListRepository'
import repositoryDocument from '@/domain/documentRepository'
import { sidebarMachineSendKey, sidebarMachineStateKey } from '@/router/views/AppOutlet.vue'

export default defineComponent({
  props: {
    tab: Number,
    addin: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AddinComments,
    AddinAttachments,
    OverlayLoading,
    DialogConfirm,
    BaseTabs,
    BaseButton,
    BaseIcon,
    AddinDetailsMoved,
    AddinDetailsDraft,
    AddinDetailsCollaborationMoving,
    AddinDetailsCollaborationInProgress,
    AddinDetailsApprovalMoving,
    AddinDetailsApprovalInProgress,
    AddinApproved,
    AddinArchived
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const sendSidebar = inject(sidebarMachineSendKey)
    const stateSidebar = inject(sidebarMachineStateKey)
    // TABS
    const tabTitles = ['Details', 'Comments', 'Attachments']
    // DOCUMENT URL
    const documentUrl = computed(() => store.state.documents.urlForCurrentSharepointDocument)
    // DOCUMENT
    const document = computed(() => store.getters['documents/getDocumentForAddin'])
    const { state, send, service } = useMachine(documentMachine)
    provide('documentMachineService', service)

    // Check if user can progress document
    const { me } = useUser()

    const documentHasAction = computed(() => {
      const userOnDocument = userIsCollaborator.value || userIsApprover.value
      const validDocumentStatus = document.value.status !== 'approved' && document.value.status !== 'archived' && document.value.status !== 'legacy-completed'
      return (userOnDocument || (allowedUser && document.value.status !== 'approval-in-progress')) && validDocumentStatus
    })

    const allowedUser = computed(() => {
      let result = false
      if (me.value && document.value && ((document.value.documentManager && me.value.id === document.value.documentManager.id) || (document.value.documentOwner && me.value.id === document.value.documentOwner.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    // Check if current user is a collaborator
    const userIsCollaborator = computed(() => {
      let result = false
      const required = document.value.collaboration ? document.value.collaboration.workflowStepsRequired.find(c => c.user.id === me.value.id) : false
      const additional = document.value.collaboration ? document.value.collaboration.workflowStepsAdditional.find(c => c.user.id === me.value.id) : false
      if (required || additional) {
        result = true
      }
      return result
    })

    // Check if current user is an approver
    const userIsApprover = computed(() => {
      let result = false
      const required = document.value.approval ? document.value.approval.workflowStepsRequired.find(c => c.user.id === me.value.id) : false
      const additional = document.value.approval ? document.value.approval.workflowStepsAdditional.find(c => c.user.id === me.value.id) : false
      if (required || additional) {
        result = true
      }
      return result
    })

    const currentAttachedLists = ref<number[]>([])
    // Set machine context whenever computed document changes
    watch(() => document.value, () => {
      send({ type: 'SET_REFERENCE_DOCUMENT', value: document.value })
      // If document reference is not empty
      if (Object.keys(document.value).length > 0 && document.value.trackingLists) {
        currentAttachedLists.value = document.value.trackingLists.map(id => parseInt(id))
      }
    }, { immediate: true })
    // Change footer colour based on state
    const footerColor = computed(() => {
      if (state.value.matches('editing')) {
        return 'primaryDark'
      }
      return 'primary'
    })
    const trackingListModalVisible = ref<boolean>(false)
    const trackingLists = computed<TrackingList>(() => store.state.trackingLists.listing)
    const tempAttachedLists = ref<number[]>([])
    onMounted(() => {
      if (me.value && me.value.isAdmin) {
        repositoryTrackingList.findByCriteria({}).then(list => {
          store.commit('trackingLists/setForListing', list)
          tempAttachedLists.value = [...currentAttachedLists.value]
        })
      }
    })

    const openModal = () => {
      trackingListModalVisible.value = true
      tempAttachedLists.value = [...currentAttachedLists.value]
    }

    const closeModal = () => {
      tempAttachedLists.value = [...currentAttachedLists.value]
      trackingListModalVisible.value = false
    }

    const handleSaveTrackingList = () => {
      trackingListModalVisible.value = false
      currentAttachedLists.value = [...tempAttachedLists.value]
      saveDocument({
        id: document.value.id,
        title: document.value.title,
        trackingLists: tempAttachedLists.value.map(id => `${id}`)
      })
    }

    const saveDocument = async (document) => {
      const documentSaved = await repositoryDocument.update(document)
      store.commit('documents/updateDocumentInCache', documentSaved)
      return documentSaved
    }

    const trackingListSelected = (isAttached, trackingList) => {
      if (isAttached) {
        tempAttachedLists.value.push(trackingList.id)
      } else {
        tempAttachedLists.value = [...tempAttachedLists.value.filter(id => id !== trackingList.id)]
      }
    }

    const collaborationIncomplete = computed(() => {
      return document.value.collaboration ? document.value.collaboration.workflowStepsRequired.find(c => c.approved === false) : false
    })
    const approvalIncomplete = computed(() => {
      return document.value.approval ? document.value.approval.workflowStepsRequired.find(a => a.approved === false) : false
    })

    // DISABLE PROGRESSION
    const disableProgression = computed(() => {
      let disable = true

      if (document.value.status && allowedUser.value) {
        switch (document.value.status) {
          case 'draft': {
            disable = !(
              typeof document.value.documentManager !== 'undefined' &&
              document.value.title &&
              document.value.title.trim().length >= 5
            )
            break
          }
          case 'collaboration-moving': {
            disable = !(
              document.value.title &&
              document.value.title.trim().length >= 5 &&
              document.value.collaboration.dueDate &&
              new Date(document.value.collaboration.dueDate).toISOString().substr(0, 10) >= new Date().toISOString().substr(0, 10)
            )
            break
          }
          case 'collaboration-in-progress': {
            disable = (collaborationIncomplete.value != null || (collaborationIncomplete.value == null && userIsCollaborator.value && !allowedUser.value))
            break
          }
          case 'approval-moving': {
            disable = !(
              document.value.approval.dueDate &&
              new Date(document.value.approval.dueDate).toISOString().substr(0, 10) >= new Date().toISOString().substr(0, 10)
            )
            break
          }
          case 'approval-in-progress': {
            disable = (approvalIncomplete.value != null || (approvalIncomplete.value == null && userIsApprover.value && !allowedUser.value))
            break
          }
          default: {
            disable = true
            break
          }
        }
      }

      return disable
    })

    const documentSync = () => {
      repositoryDocument.findById(document.value.id).then(d => {
        store.commit('documents/addDocumentToCache', d)
      })
    }

    const actionButtonDisabled = computed(() => {
      return disableProgression.value && !document.value.requiresMyInput
    })

    // This is handled manually since we are using aria-disabled rather than disabled for this button. This allows the button to be tab-able when disabled.
    const submitActionButtonClick = () => {
      if (!actionButtonDisabled.value) {
        send('CONFIRM')
      }
    }

    const tabSelected = computed(() => {
      if (stateSidebar) {
        return stateSidebar.value.context.documentTab
      }
    })

    return {
      openModal,
      closeModal,
      handleSaveTrackingList,
      trackingListSelected,
      trackingListModalVisible,
      documentUrl,
      tempAttachedLists,
      currentAttachedLists,
      footerColor,
      trackingLists,
      state,
      stateSidebar,
      send,
      sendSidebar,
      document,
      tabTitles,
      allowedUser,
      userIsApprover,
      userIsCollaborator,
      approvalIncomplete,
      collaborationIncomplete,
      disableProgression,
      me,
      documentSync,
      submitActionButtonClick,
      actionButtonDisabled,
      tabSelected,
      documentHasAction
    }
  }
})
