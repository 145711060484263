












import { mdiClose } from '@mdi/js'
import { defineComponent } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import repositoryBookmark from '@/domain/bookmarkRepository'

export default defineComponent({
  components: {
    DialogConfirm,
    BaseIcon,
    BaseButton
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  setup (_, { root }) {
    const store = root.$store
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: async (_, event: any) => repositoryBookmark.delete(event.value)
      },
      actions: {
        onDoneEnter: (_, event) => store.commit('bookmarks/removeFromAppBar', event.data)
      }
    })
    return {
      state, send, mdiClose
    }
  }
})
