


































import { defineComponent } from '@vue/composition-api'
import { BaseIcon, BaseIconText, BaseExpansionPanel } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { VList } from 'vuetify/lib'

export default defineComponent({
  name: 'document-template-group',
  components: {
    BaseExpansionPanel,
    BaseIconText,
    BaseIcon,
    VList
  },
  props: {
    title: String,
    templates: Array
  }
})
