

























































































import { defineComponent, ref } from '@vue/composition-api'
import { useUser } from '@/composables/useUser'
import {
  BaseIcon,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import VueMarkdown from 'vue-markdown'
import HelpLogoGreen from '@/assets/helpLogoGreen.svg'

export default defineComponent({
  props: ['state', 'send'],
  components: {
    BaseIcon,
    BaseButton,
    VueMarkdown,
    HelpLogoGreen
  },
  setup (props) {
    const textarea = ref<string>('')
    const form = ref<any>(null)
    const { me } = useUser()
    const submit = () => {
      props.send({ type: 'ASK', question: textarea.value })
      textarea.value = ''
    }
    return {
      textarea,
      submit,
      form,
      me
    }
  }
})
