import { HelpResource } from '@/domain/helpResource'

export const HelpResourceMapper = ({
  deserialize: (json): HelpResource => {
    return <HelpResource>{
      id: Number(json['@id'].replace('/api/knowledge_bases/', '')),
      answer: json.answer,
      linkUrl: json.relatedResource,
      linkText: json.relatedResourceTitle
    }
  }
})
