


















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: ['state', 'send', 'handleRowClick', 'headers']
})
