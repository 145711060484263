import { AgendaTemplate } from '@/domain/agendaTemplate'

export interface ModuleState {
  listing?: AgendaTemplate[];
  detail?: AgendaTemplate;
  agendaTypes?:AgendaTemplate[];
}

// state
const state = () => <ModuleState>({
  listing: [],
  detail: undefined,
  agendaTypes: []
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setForListing (state, value) {
    state.listing = value
  },
  setForDetail (state, value) {
    state.detail = value
  },
  setForAgendaTypes (state, value) {
    state.agendaTypes = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
