

















































































































































































































import { defineComponent, computed, ref, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DocumentOwner from '@/components/DocumentOwner.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import DocumentLeadGroup from '@/components/DocumentLeadGroup.vue'
import FormInputDocumentManager from '@/components/forms/FormInputDocumentManager.vue'
import FormInputJointUserGroups from '@/components/forms/FormInputJointUserGroups.vue'
import { minLength } from '@/validations'
import {
  BaseInputText,
  BaseIconText,
  BaseAlert,
  BaseIcon,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useUser } from '@/composables/useUser'
import repository from '@/domain/userRepository'
import DocumentTitle from '@/components/DocumentTitle.vue'

export default defineComponent({
  props: {
    document: Object,
    state: Object,
    send: Function,
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    BaseInputText,
    BaseIconText,
    GroupHeading,
    DocumentStatusChip,
    DocumentLeadGroup,
    DocumentOwner,
    DocumentStatus,
    BaseAlert,
    ChipBlock,
    ChipUser,
    BaseCombobox,
    BaseIcon,
    BaseButton,
    FormInputDocumentManager,
    FormInputJointUserGroups,
    DocumentTitle
  },
  setup (props, { emit }) {
    const documentSharedUserSelected = ref(null)
    const documentSharedUserSelectedCanEdit = ref(true)
    const documentSharedUserIds = computed(() => props?.state?.context?.documentSharedUsers.map(x => x.user.id))
    const searchPromise = async (c) => {
      let results = await repository.findByCriteria({ c })
      // Filter additional collaborators
      results = results.filter(x => !documentSharedUserIds.value.includes(x.id))
      return results
    }
    const { selectText } = useUser()
    const itemText = x => selectText(x)
    const documentSharedUserAdd = () => {
      const sharedUser: any = {
        user: documentSharedUserSelected.value
      }
      if (documentSharedUserSelectedCanEdit.value) {
        sharedUser.accessLevel = 'write'
      }
      emit('documentSharedUserAdd', sharedUser)
      documentSharedUserSelected.value = null
    }

    const documentHasSharedUsersWithError = computed(() => {
      return props?.document?.documentSharedUsers?.some(sharedUser => sharedUser.wasSuccessful === false)
    })

    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the details tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 0) {
        focusOnContent()
      }
    })

    return {
      minLength,
      documentSharedUserSelected,
      documentSharedUserSelectedCanEdit,
      documentHasSharedUsersWithError,
      searchPromise,
      itemText,
      documentSharedUserAdd,
      tabContent
    }
  }
})
