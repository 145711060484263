import { AgendaApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { AgendaMapper } from '@/domain/agendaMapper'
import { Agenda } from '@/domain/agenda'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new AgendaApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, pagination?: boolean, orderStatus?: 'asc' | 'desc', orderTitle?: 'asc' | 'desc', orderMeetingDate?: 'asc' | 'desc', orderIsActive?: 'asc' | 'desc', orderAgendaGroupAgendaTypeTitle?: 'asc' | 'desc', orderDateCreated?: 'asc' | 'desc', generationDateBefore?: string, generationDateStrictlyBefore?: string, generationDateAfter?: string, generationDateStrictlyAfter?: string, meetingDateBefore?: string, meetingDateStrictlyBefore?: string, meetingDateAfter?: string, meetingDateStrictlyAfter?: string, status?: string, status2?: Array<string>, agendaGroupAgendaType?: string, agendaGroupAgendaType2?: Array<string>, agendaGroupAgendaTypePackageType?: string, agendaGroupAgendaTypePackageType2?: Array<string>, groups?: Array<string>, q?: string, options?: any
}

class AgendaRepository implements IFindData<Agenda>, ICrudData<Agenda>, ISearchData<Agenda> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getAgendaItem(id)
      return AgendaMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: Agenda) {
    try {
      const params = AgendaMapper.serializePost(obj)
      const response = await api.postAgendaCollection(params)
      return AgendaMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: Agenda) {
    try {
      const params = AgendaMapper.serializePatch(obj)
      const response = await api.patchAgendaItem(String(obj.id), params)
      return AgendaMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id: string | number) {
    try {
      const response = await api.deleteAgendaItem(String(id))
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async publish ({ obj, forceRebuild }: { obj: Agenda, forceRebuild: boolean }) {
    try {
      const params = AgendaMapper.serializeRelease(obj)
      const response = await api.postReleaseAgendaItem(String(obj.id), params, forceRebuild)
      return AgendaMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async preview ({ obj, forceRebuild }: { obj: Agenda, forceRebuild: boolean }) {
    try {
      const params = AgendaMapper.serializePreview(obj)
      const response = await api.postPreviewAgendaItem(String(obj.id), params, forceRebuild)
      return AgendaMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({
    page = 1,
    itemsPerPage = 20,
    pagination = true,
    orderStatus,
    orderTitle,
    orderMeetingDate,
    orderIsActive,
    orderAgendaGroupAgendaTypeTitle,
    orderDateCreated = 'desc',
    meetingDateAfter,
    status,
    status2,
    agendaGroupAgendaType,
    agendaGroupAgendaType2,
    agendaGroupAgendaTypePackageType,
    agendaGroupAgendaTypePackageType2,
    groups,
    q
  } : SearchParams) {
    try {
      const response = await api.getAgendaCollection(
        page,
        itemsPerPage,
        pagination,
        orderStatus,
        orderTitle,
        orderMeetingDate,
        orderIsActive,
        orderAgendaGroupAgendaTypeTitle,
        orderDateCreated,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        meetingDateAfter,
        undefined,
        status,
        status2,
        agendaGroupAgendaType,
        agendaGroupAgendaType2,
        agendaGroupAgendaTypePackageType,
        agendaGroupAgendaTypePackageType2,
        groups,
        q,
        undefined
      )
      return <SearchResult<Agenda>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => AgendaMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new AgendaRepository()
