import { UserGroupType } from '@/domain/userGroupType'

export interface ModuleState {
  /** Object shown on the detail page */
  detail?: UserGroupType;
  types?: UserGroupType[];
}

// state
const state = () => <ModuleState>({
  detail: undefined,
  types: []
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setTypes (state, value) {
    state.types = value
  },
  setForDetail (state, value) {
    state.detail = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
