






























































































import { computed, defineComponent, PropType, reactive, toRefs, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import {
  BaseIcon,
  BaseIconText,
  BaseAlert,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import BaseAutocomplete from '@/components/forms/BaseAutocomplete.vue'
import { User, UserWithAccess } from '@/domain/user'
import repositoryUser from '@/domain/userRepository'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  props: {
    title: String,
    isViewing: Boolean,
    isSaving: Boolean,
    isEditing: Boolean,
    field: Array as PropType<UserWithAccess[]>,
    formValue: Array as PropType<UserWithAccess[]>
  },
  components: {
    GroupHeading,
    BaseIcon,
    BaseIconText,
    ChipBlock,
    ChipUser,
    BaseAlert,
    BaseButton,
    BaseAutocomplete
  },
  setup (props, { emit }) {
    const { selectText } = useUser()
    const { isEditing, field, formValue } = toRefs(props)
    const formValueIds = computed(() => formValue?.value?.map(x => x.user?.id))
    const userWithAccessForm = reactive<UserWithAccess>({
      accessLevel: undefined,
      user: undefined
    })
    const userWithAccessFormReset = () => {
      userWithAccessForm.user = undefined
    }
    const userWithAccessFormToggleAccess = () => {
      userWithAccessForm.accessLevel = userWithAccessForm.accessLevel === 'write' ? 'read' : 'write'
    }
    const searchPromise = async (c) => {
      const users = await repositoryUser.findByCriteria({ c })
      return users.filter((u: User) => !formValueIds.value?.includes(u.id))
    }
    watch(() => isEditing?.value, () => {
      if (isEditing?.value && field?.value) {
        const args = JSON.parse(JSON.stringify(field.value))
        emit('formChange', args)
      }
    }, {
      immediate: true
    })
    return {
      userWithAccessForm,
      userWithAccessFormReset,
      userWithAccessFormToggleAccess,
      searchPromise,
      selectText
    }
  }
})
