import { LogEventApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { LogEventMapper } from '@/domain/logEventMapper'
import { LogEvent } from '@/domain/logEvent'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new LogEventApi(undefined, BASE_PATH, axios)

// These params are copied directly from the api client collection query used in `async search` below
interface SearchParams {
  page?: number, itemsPerPage?: number, orSearch?: Array<string>, q?: string, level?: string, level2?: Array<string>, subject?: string, subject2?: Array<string>, source?: string, source2?: Array<string>, user?: string, user2?: Array<string>, orderEvent?: 'asc' | 'desc', orderLevel?: 'asc' | 'desc', orderSubject?: 'asc' | 'desc', options?: any
}

class LogEventRepository implements IFindData<LogEvent>, ICrudData<LogEvent>, ISearchData<LogEvent> {
  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getLogEventItem(id)
      return LogEventMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async search ({ page = 1, itemsPerPage = 5, q, level2, orderEvent, orderLevel, orderSubject }: SearchParams) {
    try {
      const response = await api.getLogEventCollection(
        page,
        itemsPerPage,
        undefined,
        q,
        undefined,
        level2,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        orderEvent,
        orderLevel,
        orderSubject,
        undefined
      )
      return <SearchResult<LogEvent>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => LogEventMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: LogEvent) {
    try {
      const params = LogEventMapper.serializePost(obj)
      const response = await api.postLogEventCollection(params)
      return LogEventMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new LogEventRepository()
