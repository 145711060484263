







import { computed, defineComponent, toRefs } from '@vue/composition-api'
import { BaseChipStatus } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: {
    status: {
      required: true,
      type: String
    }
  },
  components: {
    BaseChipStatus
  },
  setup (props) {
    const { status } = toRefs(props)
    const color = computed(() => {
      switch (status.value) {
        case 'draft': return 'secondaryOrange'
        case 'preview': return 'secondaryBlue'
        case 'released': return 'secondaryGreen'
        case 'releasePreview': return 'secondaryGreen'
        default: return 'secondaryGrey'
      }
    })
    const text = computed(() => {
      if (status.value === 'releasePreview') {
        return 'Released / Preview'
      } else {
        return `${status.value[0].toUpperCase()}${status.value.slice(1).toLowerCase()}`
      }
    })
    return {
      color,
      text
    }
  }
})
