


































import { defineComponent, ref, inject, watch } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { useUser } from '@/composables/useUser'
import { useService } from 'xstate-vue2'
import { Interpreter } from 'xstate'
import { DocumentContext } from '@/machines/documentMachine'
import { isObject } from '@/validations'

export default defineComponent({
  components: {
    BaseCombobox
  },
  setup (_, { root }) {
    const radioGroup = ref(1)
    const { me, selectText: itemText, search: searchPromise } = useUser()
    const service = inject('documentMachineService') as Interpreter<DocumentContext, any, any, any>
    const { state, send } = useService(service)
    watch(() => state.value.context.documentManager, () => {
      if (state.value.context.documentManager?.id === me.value.id) {
        radioGroup.value = 1
      } else {
        radioGroup.value = 2
      }
    }, {
      immediate: true
    })
    return {
      radioGroup,
      state,
      send,
      searchPromise,
      me,
      itemText,
      isObject
    }
  }
})
