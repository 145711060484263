








import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'chip-block',
  props: {
    label: {
      type: Boolean,
      default: true
    }
  }
})
