












import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'chip-square',
  components: {
    BaseIcon
  },
  props: ['color', 'iconName']
})
