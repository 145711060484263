import { render, staticRenderFns } from "./DocumentDeadline.vue?vue&type=template&id=a8b25076&scoped=true&"
import script from "./DocumentDeadline.vue?vue&type=script&lang=js&"
export * from "./DocumentDeadline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8b25076",
  null
  
)

export default component.exports