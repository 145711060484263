










import { defineComponent } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'

export default defineComponent({
  components: {
    LayoutContentSidebar
  }
})
