


















































































import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import { BaseInputText } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { useUserSettings } from '@/composables/useUserSettings'
import { useUser } from '@/composables/useUser'
import { notEmpty } from '@/validations'
import { UserSettings } from '@/domain/userSetting'
import repository from '@/domain/userSettingRepository'

export default defineComponent({
  components: {
    BaseCombobox,
    BaseInputText
  },
  setup () {
    const { findByCriteria, selectText } = useUser()
    const { findForDetail, detail } = useUserSettings()
    const valid = ref(true)
    const selectedNotifications = ref<string[]>([])
    const form = reactive<UserSettings>({
      id: undefined,
      showAssistanceTooltips: undefined,
      showSupportMessages: undefined
    })
    const checkboxMap = {
      document: 'document',
      comment: 'comment',
      reminder: 'reminder',
      'action-required': 'action-required,user'
    }

    watch(detail, () => {
      if (detail.value) {
        const params: UserSettings = JSON.parse(JSON.stringify(detail.value))
        const enabledNotifcations = params.enabledDashboardNotifications ? params.enabledDashboardNotifications : []

        // Map enabled notification categories to checkbox values
        const mappedNotifcations: string[] = []
        enabledNotifcations.forEach(x => {
          if (x in checkboxMap) {
            mappedNotifcations.push(checkboxMap[x])
          }
        })
        selectedNotifications.value = mappedNotifcations
        Object.assign(form, params)
      }
    })
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          // Two default notification categories.
          const defaultDashboardNotifications = ['error', 'general']
          const selections: string[] = []
          selectedNotifications.value.forEach(x => {
            // Split checkboxes with multiple categories
            const categories = x.split(',')
            selections.push(...categories)
          })
          const uniqueSelections = [...new Set([...defaultDashboardNotifications, ...selections])]

          form.enabledDashboardNotifications = uniqueSelections
          return repository.update(JSON.parse(JSON.stringify(form)))
        }
      },
      actions: {
        onDoneEnter: () => findForDetail('me')
      }
    })
    return {
      valid,
      selectText,
      findByCriteria,
      notEmpty,
      state,
      send,
      form,
      selectedNotifications
    }
  }
})
