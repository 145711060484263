import { Kpi } from '@/domain/kpi'

export interface ModuleState {
  dashboard?: Kpi[];
}

// state
const state = () => <ModuleState>({
  dashboard: undefined
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setForDashboard (state, value) {
    state.dashboard = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
