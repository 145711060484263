




























import { defineComponent, inject } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { authMachineSendKey } from './Auth.vue'

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    user: {
      type: Object
    }
  },
  setup () {
    const authMachineSend = inject(authMachineSendKey)
    return {
      authMachineSend
    }
  }
})
