







import { defineComponent } from '@vue/composition-api'
import { BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { mdiOpenInNew } from '@mdi/js'

export default defineComponent({
  inheritAttrs: false,
  components: {
    BaseButton
  },
  setup () {
    return {
      mdiOpenInNew
    }
  }
})
