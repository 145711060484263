




































import { defineComponent, ref, reactive } from '@vue/composition-api'
import BaseAutocomplete from '@/components/forms/BaseAutocomplete.vue'
import repositoryDocument from '@/domain/documentRepository'

export default defineComponent({
  components: {
    BaseAutocomplete
  },
  setup (props, { emit }) {
    const form = reactive({
      document: undefined
    })
    const valid = ref<boolean>(false)
    const searchPromise = q => repositoryDocument.findByCriteria({
      status2: ['approved', 'completed'],
      q: q
    })
    const close = () => {
      emit('close')
      form.document = undefined
    }
    const add = () => {
      emit('add', JSON.parse(JSON.stringify(form.document)))
      form.document = undefined
    }
    return {
      add,
      close,
      form,
      searchPromise,
      valid
    }
  }
})
