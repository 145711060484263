











































































import { defineComponent, computed, ref, onMounted } from '@vue/composition-api'
import { BaseIcon, BasePagination } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { VExpandTransition } from 'vuetify/lib'
import { useMachine } from 'xstate-vue2'
import { notificationsMachine } from '@/machines/notificationsMachine'
import OverlayLoading from '@/components/OverlayLoading.vue'
import CardMiniNotification from '@/components/CardMiniNotification.vue'

export default defineComponent({
  name: 'notifications',
  components: {
    VExpandTransition,
    BaseIcon,
    BasePagination,
    OverlayLoading,
    CardMiniNotification
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const notifications = computed(() => store.state.notifications.notifications)
    const notificationSidebar = ref<any>(null)
    const { state, send } = useMachine(notificationsMachine)

    onMounted(() => {
      // When using keyboard navigation, the following takes the focus to the sidebar when it loads so there isn't
      // a need to tab through the whole page to reach the notification sidebar after opening it.
      setTimeout(() => notificationSidebar.value.focus(), 100)
    })

    return {
      notifications,
      state,
      send,
      notificationSidebar
    }
  }
})
