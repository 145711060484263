


















import { defineComponent, ref, watch, toRefs } from '@vue/composition-api'

export default defineComponent({
  inheritAttrs: false,
  props: {
    searchPromise: {
      type: Function,
      required: true
    },
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    value: [Object, Array],
    itemText: Function,
    itemValue: Function,
    itemsOriginal: Array
  },
  setup (props) {
    const { itemsOriginal, searchPromise }: any = toRefs(props)
    const items = ref<any[]>([])
    const loading = ref<boolean>(false)
    const searchText = ref<string | null>(null)
    watch(itemsOriginal, (x: any) => {
      items.value = [...x]
    })
    watch(searchText, (newValue) => {
      loading.value = true
      searchPromise.value(newValue)
        .then(x => {
          items.value = x
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          loading.value = false
        })
    })
    return {
      loading,
      searchText,
      items
    }
  }
})
