import { Kpi } from '@/domain/kpi'

export const KpiMapper = ({
  deserialize: (json): Kpi => {
    return <Kpi>{
      id: Number(json['@id'].replace('/api/kpis/', '')),
      title: json.title,
      dateCreated: json.dateCreated ? new Date(json.dateCreated.replace(/-/g, '/').replace(/T.+/, '')) : undefined,
      performanceMetrics: json.performanceMetrics,
      documentStatusMetrics: {
        headers: json.documentStatusMetrics.headers,
        rows: json.documentStatusMetrics.tableRows.map(({ oldest, status, total }) => ({
          oldest: oldest ? (new Date(oldest)) : undefined,
          status,
          total: Number(total).toLocaleString()
        }))
      }
    }
  }
})
