import {
  DocumentDocumentPatch,
  DocumentJsonldDocumentRead,
  DocumentJsonldDocumentReadGetShare,
  DocumentJsonldDocumentWrite,
  DocumentJsonldTrackingListRead
} from '@zucommunications/gsk-docshare-web-api'
import { UserMapper } from '@/domain/userMapper'
import { UserGroupMapper } from '@/domain/userGroupMapper'
import { WorkflowMapper } from '@/domain/workflowMapper'
import { MediaMapper, MediaGroupMapper } from '@/domain/mediaMapper'
import { Document } from '@/domain/document'
import { AgendaItem } from '@/domain/agenda'

export const DocumentMapper = ({
  deserializeFromTrackingList: (json: DocumentJsonldTrackingListRead): Document => {
    return <Document>{
      id: Number(json['@id'].replace('/api/documents/', '')),
      title: json.title,
      status: json.status,
      number: json.number,
      documentType: json.documentType
    }
  },
  deserialize: (json: DocumentJsonldDocumentRead): Document => {
    return <Document>{
      id: Number(json['@id'].replace('/api/documents/', '')),
      documentClass: json.documentClass,
      documentOwner: json.documentOwner ? UserMapper.deserialize(json.documentOwner) : undefined,
      documentManager: json.documentManager ? UserMapper.deserialize(json.documentManager) : undefined,
      documentSharedUsers: json.documentSharedUsers?.length ? json.documentSharedUsers.map(obj => ({
        accessLevel: obj.accessLevel,
        user: UserMapper.deserialize(obj.user),
        wasSuccessful: obj.wasSuccessful
      })) : undefined,
      title: json.title,
      dueDate: json.dueDate ? new Date(json.dueDate) : undefined,
      draftUrl: json.draftUrl,
      status: json.status,
      dateCreated: json.dateCreated ? new Date(json.dateCreated) : undefined,
      number: json.number,
      documentType: json.documentType,
      documentTypeTitle: json.documentTypeTitle,
      leadUserGroup: json.leadUserGroup ? UserGroupMapper.deserialize(json.leadUserGroup) : undefined,
      jointUserGroups: json.jointUserGroups?.length ? json.jointUserGroups.map(UserGroupMapper.deserialize) : [],
      requiresMyInput: json.requiresMyInput,
      commentCount: json.commentCount,
      templateListItemId: json.templateListItemId,
      documentAttachments: json.documentAttachments?.length ? json.documentAttachments.map(MediaGroupMapper.deserialize) : [],
      spDraftDocumentId: json.spDraftDocumentId,
      driveItemId: json.driveItemId,
      trackingLists: json.trackingLists?.map(listID => Number(listID.toString().replace('/api/tracking_lists/', ''))) ?? [],
      currentSpId: json.currentSpId,
      currentSpUrl: json.currentSpUrl,
      customEmailNotificationContent: json.customEmailNotificationContent,
      collaboration: json.collaboration ? WorkflowMapper.deserialize(json.collaboration) : {
        workflowStepsRequired: [],
        workflowStepsAdditional: []
      },
      approval: json.approval ? WorkflowMapper.deserialize(json.approval) : {
        workflowStepsRequired: [],
        workflowStepsAdditional: []
      },
      agendaDecision: json.agendaDecision?.length ? json.agendaDecision.map(DocumentMapper.deserialize) : []
    }
  },
  deserializeFromUrl: (json: DocumentJsonldDocumentReadGetShare): Document => {
    return <Document>{
      id: Number(json['@id'].replace('/api/documents/', '')),
      redirectUrl: json.redirectUrl
    }
  },
  serializeIri: (obj: Document): string => {
    return `/api/documents/${obj.id}`
  },
  serializePost: (obj: Document): DocumentJsonldDocumentWrite => {
    return {
      title: obj.title || '',
      dueDate: obj.dueDate?.toISOString(),
      leadUserGroup: obj.leadUserGroup ? UserGroupMapper.serializeIri(obj.leadUserGroup) : '',
      jointUserGroups: obj.jointUserGroups?.map(UserGroupMapper.serializeIri),
      templateListItemId: obj.templateListItemId || 0
    }
  },
  serializePatch: (obj: Document): DocumentDocumentPatch => {
    return {
      title: obj.title || '',
      dueDate: obj.dueDate?.toISOString(),
      documentSharedUsers: obj.documentSharedUsers?.length ? obj.documentSharedUsers.map(({ accessLevel, user }) => ({
        // TODO: check these params
        accessLevel,
        user: user ? UserMapper.serializeIri(user) : undefined
      })) : [],
      jointUserGroups: obj.jointUserGroups?.map(UserGroupMapper.serializeIri),
      documentManager: obj.documentManager ? UserMapper.serializeIri(obj.documentManager) : undefined,
      customEmailNotificationContent: obj.customEmailNotificationContent,
      collaboration: obj.collaboration ? WorkflowMapper.serialize(obj.collaboration) : undefined,
      approval: obj.approval ? WorkflowMapper.serialize(obj.approval) : undefined,
      templateListItemId: obj.templateListItemId,
      documentAttachments: obj.documentAttachments?.map(MediaGroupMapper.serializePatch),
      trackingLists: obj.trackingLists?.map(id => `/api/tracking_lists/${id}`) ?? []
    }
  }
})

export const DocumentAttachmentMapper = ({
  deserialize: (json): AgendaItem => {
    return <AgendaItem>{
      id: Number(json['@id'].replace('/api/document_attachments/', '')),
      type: json.type,
      title: json.attachment ? json.attachment.title : '',
      position: json.position,
      attachment: json.attachment ? MediaMapper.deserialize(json.attachment) : undefined,
      existingAttachment: true
    }
  }
})
