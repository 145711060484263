









import { defineComponent, ref } from '@vue/composition-api'
import AddinWorkflow from '@/components/AddinWorkflow.vue'
import repositoryDocument from '@/domain/documentRepository'

export default defineComponent({
  name: 'AddinDetail',
  props: ['documentId'],
  components: {
    AddinWorkflow
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const tab = ref(0)
    repositoryDocument.findById(props.documentId).then(document => {
      store.commit('documents/addDocumentToCache', document)
      store.commit('documents/setDocumentIdForAddin', document)
    })
    return {
      tab
    }
  }
})
