


















































import { defineComponent, ref, reactive } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { Media } from '@/domain/media'
import repository from '@/domain/mediaRepository'

export default defineComponent({
  components: {
  },
  setup (props, { root, emit }) {
    const form = reactive<Media>({
      file: undefined,
      title: undefined
    })
    const valid = ref<boolean>(false)
    const { state, send } = useMachine(promiseMachine, {
      services: {
        invoke: () => {
          return repository.create(form as Media)
        }
      },
      actions: {
        onDoneEnter: (context, event) => {
          emit('add', event.data)
        }
      }
    })
    const close = () => {
      emit('close')
    }
    const handleChange = (e) => {
      if (Array.isArray(e)) {
        form.file = e[0]
      } else {
        form.file = e
      }
    }
    return {
      handleChange,
      close,
      form,
      valid,
      state,
      send
    }
  }
})
