import { Machine, assign, spawn, send } from 'xstate'
import HelpResourceRepository from '@/domain/helpResourceRepository'

const questionMachine = Machine<any>({
  id: 'question',
  initial: 'query',
  context: {
    question: '',
    answer: '',
    error: ''
  },
  states: {
    query: {
      invoke: {
        src: 'query',
        onDone: {
          target: 'done'
        },
        onError: {
          target: 'error'
        }
      }
    },
    done: {
      entry: 'setAnswer',
      type: 'final'
    },
    error: {
      entry: 'setError',
      on: {
        RETRY: {
          target: 'query'
        }
      },
      exit: 'clearError'
    }
  }
}, {
  services: {
    query: (context) => {
      return HelpResourceRepository.findById(context.question)
    }
  },
  actions: {
    setAnswer: assign({
      answer: (context, event: any) => event.data
    }),
    setError: assign({
      error: (context, event: any) => event.data.message
    }),
    clearError: assign({
      error: undefined
    })
  }
})

export const helpMachine = Machine<any>({
  id: 'help',
  initial: 'closed',
  context: {
    questions: []
  },
  states: {
    closed: {
      on: {
        TOGGLE: {
          target: 'open'
        }
      }
    },
    open: {
      on: {
        TOGGLE: {
          target: 'closed'
        },
        RETRY: {
          actions: send((ctx, e) => ({
            type: 'RETRY',
            payload: e.payload
          }), {
            to: (_, event) => event.to
          })
        },
        ASK: {
          actions: assign({
            questions: (ctx: any, event: any) => [
              ...ctx.questions,
              {
                question: event.question,
                /**
                 * Spawn new machine instance and store in `questions: []`
                 * along with reference to machine. This machines states will
                 * be kept in sync via `sync: true`.  The template
                 * will track the state changes and react accordingly.
                 */
                ref: spawn(questionMachine.withContext({
                  question: event.question
                }), {
                  name: `question-${ctx.questions.length}`,
                  sync: true
                })
              }
            ]
          })
        }
      }
    }
  }
})
