















































































































import { defineComponent, computed, ref, inject, watch } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import DocumentSummaryCard from '@/components/DocumentSummaryCard.vue'
import DocumentExpandedContentTable from '@/components/DocumentExpandedContentTable.vue'
import { BaseIcon, BasePagination, BaseButton } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useHomeMachine } from '@/machines/homeMachine'
import { sidebarMachineSendKey } from '@/router/views/AppOutlet.vue'
import SvgGuyCard from '@/components/SvgGuyCard.vue'
import { useUserSettings } from '@/composables/useUserSettings'
import { UserSettings } from '@/domain/userSetting'

export default defineComponent({
  components: {
    LayoutContentSidebar,
    DocumentSummaryCard,
    DocumentExpandedContentTable,
    BaseIcon,
    BasePagination,
    BaseButton,
    SvgGuyCard
  },
  setup (props, { root }) {
    const { findForDetail, detail } = useUserSettings()
    findForDetail('me')
    // TODO: migrate to `const store = useStore()`
    const store = root.$store

    const filterOptions = ref([
      { text: 'Current Documents', value: 'current' },
      { text: 'Archived Documents', value: 'archived' },
      { text: 'Requires My Action', value: 'requiresMyInput' }
    ])

    const sortOptions = ref([
      { text: 'Title (A - Z)', value: { sortBy: 'title', sortDesc: false } },
      { text: 'Title (Z - A)', value: { sortBy: 'title', sortDesc: true } },
      { text: 'Date Created (Newest)', value: { sortBy: 'dateCreated', sortDesc: true } },
      { text: 'Date Created (Oldest)', value: { sortBy: 'dateCreated', sortDesc: false } },
      { text: 'Document Number (Ascending)', value: { sortBy: 'number', sortDesc: false } },
      { text: 'Document Number (Descending)', value: { sortBy: 'number', sortDesc: true } }
    ])

    const { state, send } = useHomeMachine()

    // Get documents from store to update with changes made in addin
    const documents = computed(() => store.getters['documents/getDocumentsForDashboard'])
    // INJECT
    const sendSidebar = inject(sidebarMachineSendKey)

    const getTemplateTypeLabel = (document) => {
      // Older documents won't have a documentTypeTitle saved. If it isn't available, show the document type.
      return document.documentTypeTitle !== undefined ? document.documentTypeTitle : document.documentType
    }

    const getFilterText = (value) => {
      let text = 'unknown'
      filterOptions.value.forEach(filter => {
        if (filter.value === value) {
          text = filter.text
        }
      })
      return text
    }

    const getSortText = (sortBy, sortDesc) => {
      let text = 'unknown'
      sortOptions.value.forEach(filter => {
        if (filter.value.sortBy === sortBy && filter.value.sortDesc === sortDesc) {
          text = filter.text
        }
      })
      return text
    }

    watch(detail, () => {
      if (detail.value) {
        const params: UserSettings = JSON.parse(JSON.stringify(detail.value))
        const preferredFilter = params.preferredDashboardFilter
        const filter = preferredFilter || 'current'

        // Trigger a status change if documents haven't previously been loaded
        if (state.value.context.resultsTotal === 0) {
          send({ type: 'CHANGE_STATUS', payload: filter })
        }
      }
    })

    return {
      sendSidebar,
      state,
      send,
      documents,
      filterOptions,
      sortOptions,
      getTemplateTypeLabel,
      getFilterText,
      getSortText
    }
  }
})
