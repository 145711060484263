import { TemplateFileApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { IFindData, ISearchData } from '@/domain/interfaces'
import { DocumentTemplate } from '@/domain/documentTemplate'
import { DocumentTemplateMapper } from '@/domain/documentTemplateMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new TemplateFileApi(undefined, BASE_PATH, axios)

class DocumentTemplateRepository implements IFindData<DocumentTemplate>, ISearchData<DocumentTemplate> {
  async search ({ itemsPerPage = 100 }) {
    try {
      const response = await api.getTemplateFileCollection()
      return <SearchResult<DocumentTemplate>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => DocumentTemplateMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getTemplateFileItem(id)
      return DocumentTemplateMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new DocumentTemplateRepository()
