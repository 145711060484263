













































import { computed, defineComponent, toRefs } from '@vue/composition-api'
import SvgGuyKpi from '@/assets/sidebarGuy.svg'
import SvgGuyKarate from '@/assets/sidebarKarate.svg'
import SvgGuyFootball from '@/assets/sidebarFootball.svg'
import SvgGuyHut from '@/assets/sidebarHut.svg'
import SvgGuyShovel from '@/assets/sidebarShovel.svg'
import SvgGuyShakespeare from '@/assets/sidebarShakespeare.svg'
import SvgGuyMountain from '@/assets/sidebarMountain.svg'
import SvgGuyWaiter from '@/assets/sidebarWaiter.svg'

const randomElement = xs => {
  return xs[Math.floor(Math.random() * xs.length)]
}

export default defineComponent({
  props: {
    group: String
  },
  components: {
    SvgGuyKpi,
    SvgGuyKarate,
    SvgGuyFootball,
    SvgGuyHut,
    SvgGuyShovel,
    SvgGuyShakespeare,
    SvgGuyMountain,
    SvgGuyWaiter
  },
  setup (props) {
    const { group } = toRefs(props)
    const typesKpi = ['kpi']
    const typesHomepage = ['karate', 'football', 'hut', 'shovel']
    const typesGeneric = ['shakespeare', 'mountain', 'waiter']
    const type = computed<string>(() => {
      if (group?.value === 'kpi') {
        return randomElement(typesKpi)
      }
      if (group?.value === 'homepage') {
        return randomElement(typesHomepage)
      }
      return randomElement(typesGeneric)
    })
    return {
      type
    }
  }
})
