


































































import { defineComponent, computed } from '@vue/composition-api'
import CardMini from '@/components/CardMini.vue'
import { BaseButton, BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useMachine } from 'xstate-vue2'
import { notificationMachine } from '@/machines/notificationMachine'

export default defineComponent({
  components: {
    CardMini,
    BaseButton,
    BaseIcon
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  setup (props, { root }) {
    const { state, send } = useMachine(notificationMachine)

    const notificationValues = computed(() => {
      const id = props.notification.actionMetaData ? props.notification.actionMetaData.documentId : null
      const textOptions = {
        document: {
          text: 'Go To Document',
          icon: 'mdiCheck',
          iconColor: 'secondaryGreen',
          to: { name: 'DocumentsDetail', params: { id: id } }
        },
        comment: {
          text: 'Go To Comment',
          icon: 'mdiMessageReply',
          iconColor: 'secondaryBlue',
          to: { name: 'DocumentsDetail', params: { id: id, tab: 'DOCUMENT_COMMENTS' } }
        },
        'action-required': {
          text: 'Go To Dashboard',
          icon: 'mdiAlert',
          iconColor: 'secondaryRed',
          to: { name: 'DocumentsDetail', params: { id: id } }
        },
        user: {
          icon: 'mdiAccount',
          iconColor: 'secondaryGrey'
        },
        general: {},
        error: {
          icon: 'mdiCancel',
          iconColor: 'secondaryRed'
        }
      }
      return textOptions[props.notification.category]
    })

    return {
      state,
      send,
      notificationValues
    }
  }
})

