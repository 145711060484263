import { WorkflowGroup } from '@/domain/workflowGroup'

interface ModuleState {
  detail?: WorkflowGroup;
}

const state = () => <ModuleState>({
  detail: undefined
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setForDetail (state, value) {
    state.detail = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
