import { Machine, assign } from 'xstate'
import emitter from '@/utils/emitter'
import * as msal from '@azure/msal-browser'
import { TokenApi } from '@zucommunications/gsk-docshare-web-api/index'
import axios from '@/utils/axios'

const msalInstance = new msal.PublicClientApplication({
  auth: {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    authority: process.env.VUE_APP_MSAL_AUTHORITY,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_MSAL_POST_LOGOUT_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  },
  system: {
    iframeHashTimeout: 10000,
    allowRedirectInIframe: true
  }
})

msalInstance.handleRedirectPromise().then(response => {
  if (navigator.onLine) {
    emitter.emit('ONLINE')
    if (response) {
      emitter.emit('SSO_LOGIN_REDIRECT', response)
      return
    }
    if (msalInstance.getAllAccounts().length) {
      emitter.emit('SSO_LOGIN_CACHED', msalInstance.getAllAccounts()[0].username)
      return
    }
    return msalInstance.loginRedirect({ scopes: ['openid', 'profile'] })
  } else {
    emitter.emit('OFFLINE')
  }
}).catch(err => {
  console.error(err)
})

export type MachineEvent = {
  type: string;
  data?: any;
}

interface MachineContext {
  ssoTokenLoginHint: string | undefined;
  ssoToken: string | undefined;
  ssoTokenExpiry: Date | undefined;
  ssoError: string | undefined;
  ssoUserImpersonation: string | undefined;
  apiToken: string | undefined;
  apiError: string | undefined;
  authAttemptCount: number;
  inactiveUser: boolean;
}

export const authMachine = Machine<MachineContext, any, MachineEvent>({
  id: 'auth',
  initial: 'offline',
  on: {
    SSO_LOGOUT_APP: {
      target: '#anonymous',
      actions: ['clearStorage', 'ssoLogout']
    },
    SSO_LOGIN_REDIRECT: {
      target: 'online.authenticated.exchanging',
      actions: 'setSsoInformation'
    },
    SSO_LOGIN_CACHED: {
      target: 'online.authenticated.refreshing',
      actions: 'setSsoTokenLoginHint'
    },
    SSO_IMPERSONATION_ENABLE: {
      actions: ['setUserImpersonation', 'reload']
    },
    SSO_IMPERSONATION_DISABLE: {
      actions: ['clearUserImpersonation', 'reload']
    },
    API_403: {
      target: '#anonymous.userRestricted',
      actions: 'clearStorage'
    }
  },
  context: {
    ssoError: undefined,
    apiError: undefined,
    authAttemptCount: 0,
    inactiveUser: false,
    ssoToken: window.localStorage.getItem('ssoToken') || undefined,
    ssoTokenExpiry: window.localStorage.getItem('ssoTokenExpiry') ? new Date(parseInt(String(window.localStorage.getItem('ssoTokenExpiry')))) : undefined,
    ssoTokenLoginHint: window.localStorage.getItem('ssoTokenLoginHint') || undefined,
    ssoUserImpersonation: window.localStorage.getItem('ssoUserImpersonation') || undefined,
    apiToken: window.localStorage.getItem('apiToken') || undefined
  },
  states: {
    offline: {
      on: {
        ONLINE: 'online.authenticated.refreshing'
      }
    },
    online: {
      initial: 'anonymous',
      on: {
        OFFLINE: 'offline'
      },
      states: {
        anonymous: {
          initial: 'idle',
          id: 'anonymous',
          states: {
            idle: {},
            userRestricted: {},
            backendUnavailable: {}
          }
        },
        authenticated: {
          states: {
            ssoLoginRedirect: {
              invoke: {
                src: 'ssoLoginRedirect',
                onDone: [{
                  actions: 'log'
                }],
                onError: [{
                  actions: 'log'
                }]
              }
            },
            refreshing: {
              entry: 'log',
              invoke: {
                src: 'ssoSilent',
                onDone: {
                  target: 'exchanging',
                  actions: ['setSsoInformation', 'resetAuthAttemptCount']
                },
                onError: [{
                  target: 'ssoLoginRedirect',
                  cond: 'isLoginRequired'
                }, {
                  target: '#anonymous.backendUnavailable',
                  cond: 'maxAuthAttemptCount'
                }, {
                  target: 'refreshingFailure',
                  actions: 'incrementAuthAttemptCount'
                }]
              }
            },
            refreshingFailure: {
              entry: 'log',
              after: {
                RETRY_DELAY: 'refreshing'
              }
            },
            exchanging: {
              entry: 'log',
              invoke: {
                // Exchange idToken for JWT (on behalf of flow)
                src: 'jwtTokenExchange',
                onDone: {
                  target: 'connected',
                  actions: ['setApiInformation', 'resetAuthAttemptCount']
                },
                onError: [{
                  target: '#anonymous.userRestricted',
                  cond: 'isInactiveUser'
                }, {
                  target: '#anonymous.backendUnavailable',
                  cond: 'maxAuthAttemptCount'
                }, {
                  target: 'exchangingFailure',
                  actions: 'incrementAuthAttemptCount'
                }]
              }
            },
            exchangingFailure: {
              entry: 'log',
              after: {
                RETRY_DELAY: 'exchanging'
              }
            },
            connected: {
              entry: 'log',
              after: {
                REFRESH_DELAY: 'refreshing'
              },
              on: {
                API_401: {
                  target: 'refreshing'
                }
              }
            }
          }
        }
      }
    }
  }
}, {
  delays: {
    REFRESH_DELAY: 10 * 60 * 1000,
    RETRY_DELAY: 2 * 1000
  },
  actions: {
    log: (context, event) => {
      console.log('authMachineEvent', event.type)
    },
    reload: () => {
      window.location.replace('/app/documents')
    },
    setSsoTokenLoginHint: assign((context, event) => {
      const ssoTokenLoginHint = event.data.account.username
      return {
        ssoTokenLoginHint
      }
    }),
    setSsoInformation: assign((context, event) => {
      const ssoToken = event.data.idToken
      const ssoTokenExpiry = event.data.expiresOn
      const ssoTokenLoginHint = event.data.account.username
      window.localStorage.setItem('ssoToken', ssoToken)
      window.localStorage.setItem('ssoTokenExpiry', ssoTokenExpiry.getTime().toString())
      window.localStorage.setItem('ssoTokenLoginHint', ssoTokenLoginHint)
      axios.defaults.headers.post['MS-Authorization'] = ssoToken
      return {
        ssoToken,
        ssoTokenExpiry,
        ssoTokenLoginHint
      }
    }),
    setUserImpersonation: assign((context, event) => {
      const ssoUserImpersonation = event.data.user
      window.localStorage.setItem('ssoUserImpersonation', ssoUserImpersonation)
      axios.defaults.headers.common['HTTP-X-SWITCH-USER'] = ssoUserImpersonation
      return {
        ssoUserImpersonation
      }
    }),
    clearUserImpersonation: assign((context, event) => {
      window.localStorage.removeItem('ssoUserImpersonation')
      delete axios.defaults.headers.common['HTTP-X-SWITCH-USER']
      return {
        ssoUserImpersonation: undefined
      }
    }),
    setApiInformation: assign((context, event) => {
      const apiToken = event.data.token
      window.localStorage.setItem('apiToken', apiToken)
      axios.defaults.headers.common.Authorization = `bearer ${apiToken}`
      return {
        apiToken
      }
    }),
    clearStorage: () => {
      window.localStorage.removeItem('ssoToken')
      window.localStorage.removeItem('ssoTokenExpiry')
      window.localStorage.removeItem('ssoTokenLoginHint')
      window.localStorage.removeItem('ssoUserImpersonation')
      window.localStorage.removeItem('apiToken')
      delete axios.defaults.headers.common.Authorization
      delete axios.defaults.headers.common['HTTP-X-SWITCH-USER']
      delete axios.defaults.headers.post['MS-Authorization']
    },
    ssoLogout: (context, event) => {
      msalInstance.logoutRedirect({
        idTokenHint: context.ssoTokenLoginHint
      })
    },
    ssoLogin: (context, event) => {
      msalInstance.loginRedirect({ scopes: ['openid', 'profile'] })
    },
    incrementAuthAttemptCount: (context, event) => {
      context.authAttemptCount += 1
    },
    resetAuthAttemptCount: (context, event) => {
      context.authAttemptCount = 0
    }
  },
  services: {
    ssoLoginRedirect: (context, event) => {
      return msalInstance.loginRedirect({ scopes: ['openid', 'profile'] })
    },
    ssoSilent: (context, event) => {
      return msalInstance.ssoSilent({
        loginHint: context.ssoTokenLoginHint || undefined
      })
    },
    jwtTokenExchange: async (context, event) => {
      const api = new TokenApi(undefined, process.env.VUE_APP_SYMFONY_API_URL, axios)
      try {
        const response = await api.postMSAuthentication()
        return Promise.resolve(response.data)
      } catch (err: any) {
        if (err.response && err.response.status === 403) {
          context.inactiveUser = true
        }
        return Promise.reject(err)
      }
    }
  },
  guards: {
    isLoginRequired: (context, event) => {
      return event.data.errorMessage.includes('AADSTS50058')
    },
    isInactiveUser: (context, event) => {
      return context.inactiveUser
    },
    maxAuthAttemptCount: (context, event) => {
      return context.authAttemptCount >= 7 // will trigger on 8th attempt
    }
  }
})
