

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    stat: {
      required: true
    }
  },
  components: {
  },
  setup () {
    return {
    }
  }
})
