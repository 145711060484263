























import { defineComponent } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    navigationItems: {
      type: Array
    }
  }
})
