

















import { defineComponent, toRefs, computed, inject } from '@vue/composition-api'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { authMachineSendKey } from './Auth.vue'

export default defineComponent({
  components: {
    BaseIcon
  },
  props: {
    user: {
      type: Object
    }
  },
  setup (props) {
    const { user } = toRefs(props)
    const showDelegation = computed<boolean>(() => user?.value?.usersForImpersonation?.length)
    const authMachineSend = inject(authMachineSendKey)
    return {
      showDelegation,
      authMachineSend
    }
  }
})
