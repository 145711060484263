import { BookmarkJsonldBookmarkWrite } from '@zucommunications/gsk-docshare-web-api'
import { Bookmark } from '@/domain/bookmark'

export const BookmarkMapper = ({
  deserialize: (json): Bookmark => {
    return <Bookmark>{
      id: Number(json['@id'].replace('/api/bookmarks/', '')),
      title: json.title,
      url: json.url
    }
  },
  serializeIri: (entity: Bookmark): string => {
    return `/api/bookmarks/${entity.id}`
  },
  serializePost: (entity: Bookmark) : BookmarkJsonldBookmarkWrite => {
    return {
      title: entity.title,
      url: entity.url
    }
  }
})
