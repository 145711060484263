var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.isReply ? 'mt-1' : ''},[_c('v-sheet',{staticClass:"d-flex text-body-2 ui-document-addin__comment-card",attrs:{"color":"tertiaryGrey","elevation":"2","aria-label":'Comment by ' + _vm.selectText(_vm.comment.user) + ': ' + _vm.comment.content + '. Comment date: ' + _vm.comment.dateCreated,"tabindex":"0"}},[_c('div',{staticClass:"pa-3 d-flex flex-column justify-space-between ui-document-addin__comment-content"},[_c('div',[_c('div',{staticClass:"mb-3 d-flex align-center ui-document-addin__comment-user-name"},[(!_vm.comment.seen)?_c('BaseIcon',{staticClass:"mr-3",attrs:{"color":"error","iconName":"mdiAlert"}}):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.selectText(_vm.comment.user)))])],1),_c('p',[_vm._v(_vm._s(_vm.comment.content))])]),_c('p',{staticClass:"mb-0 mt-3 ui-document-addin__comment-date"},[_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.comment.dateCreated)))])]),_c('div',{staticClass:"d-flex flex-column justify-space-between pa-2 ui-document-addin__comment-actions"},[_c('div',{staticClass:"mb-4 d-flex flex-column"},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","small":"","aria-label":"Comment Action Menu: Click enter to open menu and use up and down arrows to navigate the menu items."}},'v-btn',attrs,false),on),[_c('BaseIcon',{attrs:{"iconName":"mdiDotsVertical"}})],1)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"250","tile":""}},[_c('v-list',{attrs:{"dense":""}},[(_vm.canEdit)?_c('v-list-item',{attrs:{"aria-label":"Edit Comment Button: Click to edit comment."},on:{"click":function($event){return _vm.$emit('focusCommentInput', { commentId: _vm.comment.id, content: _vm.comment.content, editComment: true })}}},[_c('v-list-item-icon',[_c('BaseIcon',{attrs:{"iconName":"mdiLeadPencil"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit")])],1)],1):_vm._e(),(_vm.canEdit)?_c('v-list-item',{attrs:{"aria-label":"Delete Comment Button: Click to delete comment."},on:{"click":function($event){return _vm.$emit('modifyComment', { commentId: _vm.comment.id, commentHasReply: _vm.hasReplies, deleteComment: true })}}},[_c('v-list-item-icon',[_c('BaseIcon',{attrs:{"color":"error","iconName":"mdiClose"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)],1),(!_vm.isReply && _vm.canResolve)?_c('BaseButton',{staticClass:"mt-1",attrs:{"tile":false,"icon":"","small":"","aria-label":"Resolve Button: Click to resolve comment."},nativeOn:{"click":function($event){return _vm.$emit('modifyComment', {
            commentId: _vm.comment.id,
            resolveComment: _vm.comment.resolved ? undefined : true,
            unresolveComment: _vm.comment.resolved ? true : undefined,
          })}}},[_c('BaseIcon',{attrs:{"color":_vm.comment.resolved ? 'primary' : undefined,"iconName":_vm.comment.resolved ? 'mdiCheckboxMarked' : 'mdiCheck'}})],1):_vm._e()],1),(!_vm.isReply)?_c('BaseButton',{attrs:{"tile":false,"icon":"","small":"","aria-label":"Reply Button: Click to reply to comment."},nativeOn:{"click":function($event){_vm.$emit('focusCommentInput', { commentId: _vm.comment.id, replyTo: _vm.selectText(_vm.comment.user) })}}},[_c('BaseIcon',{attrs:{"iconName":"mdiReply"}})],1):_vm._e()],1)]),(_vm.comment.replies && _vm.comment.replies.length)?_c('div',_vm._l((_vm.comment.replies),function(reply){return _c('CommentCard',{key:reply.id,attrs:{"comment":reply,"document":_vm.document,"isReply":true,"aria-label":'Reply by ' + _vm.selectText(reply.user) + ': ' + reply.content + '. Reply date: ' + reply.dateCreated,"tabindex":"0"},on:{"focusCommentInput":function($event){return _vm.$emit('focusCommentInput', $event)},"modifyComment":function($event){return _vm.$emit('modifyComment', $event)}}})}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }