import { computed } from '@vue/composition-api'
import store from '@/store'
import { Agenda } from '@/domain/agenda'
import repository from '@/domain/agendaRepository'

export function useAgendas () {
  const findForDetail = id => (
    repository.findById(id).then(x => {
      store.commit('agendas/setForDetail', x)
    })
  )
  const findForSidebar = id => (
    repository.findById(id).then(x => {
      store.commit('agendas/setForSidebar', x)
    })
  )
  const setAgendaDeleted = x => {
    store.commit('agendas/setAgendaDeleted', x)
  }
  const agendaForDetail = computed<Agenda>(() => store.state.agendas.detail)
  const agendaForSidebar = computed<Agenda>(() => store.state.agendas.sidebar)
  const agendaDeleted = computed(() => store.state.agendas.agendaDeleted)
  return {
    findForDetail,
    findForSidebar,
    setAgendaDeleted,
    agendaForDetail,
    agendaForSidebar,
    agendaDeleted
  }
}
