import { NotificationApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { NotificationMapper } from '@/domain/notificationMapper'
import { Notification } from '@/domain/notification'
import RepositoryError from '@/domain/repositoryError'

export interface NotificationsState {
  notifications: [Notification] | [];
  notificationSeenStatus: boolean;
}

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL

const api = new NotificationApi(undefined, BASE_PATH, axios)

// state
const state = () => <NotificationsState>({
  notifications: [],
  notificationSeenStatus: false
})

// getters
const getters = {
}

// actions
const actions = {
  clearStore ({ commit }) {
    commit('setNotifications', [])
  },
  async notificationStatus ({ commit }) {
    try {
      const response = await api.getNotificationSeenStatus({})
      const status = (response as any).data.hasUnseen
      commit('setNotificationSeenStatus', status)
      return response
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async findAll ({ commit }, { page, itemsPerPage, orderDateCreated }) {
    try {
      const response = await api.getNotificationCollection(page, itemsPerPage, orderDateCreated)
      const notifications = response.data['hydra:member'].map(x => NotificationMapper.deserialize(x))
      commit('setNotifications', notifications)
      return response
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async archive ({ commit }, id) {
    try {
      const response = await api.postNotificationClearedNotificationItem(id, {})
      const notification = NotificationMapper.deserialize(response.data)
      commit('removeNotification', notification)
      return notification
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async archiveAll ({ commit }) {
    try {
      commit('setNotificationSeenStatus', false)
      const response = await api.postNotificationsClearedNotificationCollection({})
      return response
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async read ({ commit }, id) {
    try {
      commit('updateSeen', id)
      const response = await api.postNotificationSeenNotificationItem(id, {})
      const notification = NotificationMapper.deserialize(response.data)
      return notification
    } catch (err) {
      throw new RepositoryError(err)
    }
  },
  async readAll ({ commit }) {
    try {
      commit('updateSeenAll')
      commit('setNotificationSeenStatus', false)
      const response = await api.postNotificationsSeenNotificationCollection({})
      return response
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

// mutations
const mutations = {
  updateSeen (state, value) {
    state.notifications = state.notifications.map(x => {
      if (x.id === value) {
        x.seen = true
      }
      return x
    })

    state.notificationSeenStatus = !!state.notifications.some(x => x.seen !== true)
  },
  updateSeenAll (state, value) {
    state.notifications = state.notifications.map(x => {
      x.seen = true
      return x
    })
  },
  setNotifications (state, value) {
    state.notifications = value
  },
  setNotificationSeenStatus (state, value) {
    state.notificationSeenStatus = value
  },
  removeNotification (state, value) {
    state.notifications = state.notifications.filter(x => x.id !== value.id)

    state.notificationSeenStatus = !!state.notifications.length
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
