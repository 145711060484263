import { LogEventJsonldLogEventWrite } from '@zucommunications/gsk-docshare-web-api'
import { LogEvent } from '@/domain/logEvent'

export const LogEventMapper = ({
  deserialize: (json): LogEvent => {
    return <LogEvent>{
      id: Number(json['@id'].replace('/api/log_events/', '')),
      type: json.type,
      event: json.event,
      level: json.level,
      subject: json.subject,
      source: json.source,
      user: json.user,
      userId: Number(json.user?.split('/').pop()),
      isSystemEvent: json.source === 'SYSTEM'
    }
  },
  serializeIri: (logEvent: LogEvent): string => {
    return `/api/log_events/${logEvent.id}`
  },
  serializePost: (logEvent: LogEvent) : LogEventJsonldLogEventWrite => {
    return {
      event: logEvent.event,
      level: logEvent.level,
      subject: logEvent.subject
    }
  }
})
