


































import { VTimePicker, VMenu, VTextField } from 'vuetify/lib'
import { mdiClock } from '@mdi/js'
import { defineComponent, computed, ref } from '@vue/composition-api'
import { format, set } from 'date-fns'

export default defineComponent({
  inheritAttrs: false,
  name: 'base-input-time-picker',
  components: {
    VTimePicker,
    VMenu,
    VTextField
  },
  props: {
    value: Date,
    label: String,
    rules: Array,
    required: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const menu = ref(false)
    const valueTextField = computed(() => {
      return props.value ? format(props.value, 'h:mm aaa') : ''
    })
    const valuePicker = computed(() => {
      return props.value ? format(props.value, 'HH:mm') : ''
    })
    const handleChange = time => {
      const [hours, minutes] = time.split(':')
      const date = set((new Date()), {
        hours,
        minutes
      })
      emit('input', date)
      menu.value = false
    }
    return {
      valueTextField,
      valuePicker,
      menu,
      mdiClock,
      handleChange
    }
  }
})
