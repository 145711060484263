import { MediaApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData } from '@/domain/interfaces'
import { Media } from '@/domain/media'
import { MediaMapper } from '@/domain/mediaMapper'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new MediaApi(undefined, BASE_PATH, axios)

class MediaRepository implements ICrudData<Media> {
  async create (obj: Media) {
    try {
      const response = await api.postMediaCollection(obj.file, obj.title)
      return MediaMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findMediaBlobById (id) {
    try {
      const response = await api.getInCollaborationMediaItem(id, {
        responseType: 'blob'
      })
      return { blob: response.data, type: response.headers['content-type'] }
    } catch (err) {
      throw Error(err.response.data['hyda.description'])
    }
  }
}

export default new MediaRepository()
