import { Agenda } from '@/domain/agenda'

interface ModuleState {
  detail?: Agenda;
  sidebar?: Agenda;
  listing: Agenda[];
  agendaDeleted: Boolean;
}

// state
const state = () => <ModuleState>({
  detail: undefined,
  sidebar: undefined,
  listing: [],
  agendaDeleted: false
})

// getters
const getters = {}

const actions = {}

// mutations
const mutations = {
  setForDetail (state, value) {
    state.detail = value
  },
  setForSidebar (state, value) {
    state.sidebar = value
  },
  setAgendaDeleted (state, value) {
    state.agendaDeleted = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
