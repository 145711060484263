




































import { VDatePicker, VMenu, VTextField } from 'vuetify/lib'
import { format, parseISO } from 'date-fns'
import { mdiCalendar } from '@mdi/js'
import { defineComponent, computed, ref } from '@vue/composition-api'

export default defineComponent({
  inheritAttrs: false,
  components: {
    VDatePicker,
    VMenu,
    VTextField
  },
  props: {
    value: Date,
    allowedDates: Function,
    label: String,
    rules: Array,
    required: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const menu = ref(false)
    const valueTextField = computed(() => {
      return props.value ? format(props.value, 'MMMM d, yyyy') : ''
    })
    const valuePicker = computed(() => {
      return props.value ? format(props.value, 'yyyy-MM-dd') : ''
    })
    const handleChange = e => {
      emit('input', parseISO(e))
      menu.value = false
    }
    return {
      handleChange,
      menu,
      mdiCalendar,
      valueTextField,
      valuePicker
    }
  }
})
