import { Bookmark } from '@/domain/bookmark'

export interface ModuleState {
  appBar?: Bookmark[];
}

// state
const state = () => <ModuleState>({
  appBar: undefined
})

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setForAppBar (state, value) {
    state.appBar = value
  },
  addToAppBar (state, value) {
    state.appBar = [...state.appBar, value]
  },
  removeFromAppBar (state, value) {
    state.appBar = state.appBar.filter(x => x.id !== value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
