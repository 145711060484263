import { DocumentApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { ICrudData, IFindData, ISearchData } from '@/domain/interfaces'
import { DocumentMapper } from '@/domain/documentMapper'
import { Document } from '@/domain/document'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new DocumentApi(undefined, BASE_PATH, axios)

// TODO: remove commit lines
class DocumentRepository implements IFindData<Document>, ICrudData<Document>, ISearchData<Document> {
  async search ({
    page = 1,
    itemsPerPage = 20,
    requiresMyInput = undefined,
    orderStatus = undefined,
    orderTitle = undefined,
    orderDocumentType = undefined,
    orderNumber = undefined,
    orderDateCreated = undefined,
    status = undefined,
    status2 = undefined,
    documentType = undefined,
    documentType2 = undefined,
    q = undefined,
    ministry = undefined
  } : {
    page: number,
    itemsPerPage: number,
    requiresMyInput?: boolean,
    orderStatus?: 'asc' | 'desc',
    orderTitle?: 'asc' | 'desc',
    orderDocumentType?: 'asc' | 'desc',
    orderNumber?: 'asc' | 'desc',
    orderDateCreated?: 'asc' | 'desc',
    status?: string,
    status2?: string[],
    documentType?: string,
    documentType2?: string[],
    q?: string
    ministry?: number
  }) {
    try {
      const response = await api.getDocumentCollection(
        page,
        itemsPerPage,
        requiresMyInput,
        orderStatus,
        orderTitle,
        orderDocumentType,
        orderNumber,
        orderDateCreated,
        status,
        status2,
        documentType,
        documentType2,
        undefined,
        undefined,
        q,
        ministry
      )
      return <SearchResult<Document>> {
        pageCount: Math.ceil(+response.data['hydra:totalItems'] / itemsPerPage) || 1,
        resultsTotal: +response.data['hydra:totalItems'] || 0,
        results: response.data['hydra:member'].map(x => DocumentMapper.deserialize(x))
      }
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findByCriteria (params) {
    try {
      const response = await this.search(params)
      return response.results
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findById (id) {
    try {
      const response = await api.getDocumentItem(id)
      return DocumentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async findByUrl (url) {
    try {
      const response = await api.getDocumentFromShareUrlDocumentItem(encodeURIComponent(url))
      return DocumentMapper.deserializeFromUrl(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async create (obj: Document) {
    try {
      const params = DocumentMapper.serializePost(obj)
      const response = await api.postDocumentCollection(params)
      return DocumentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async update (obj: Document) {
    try {
      const params = DocumentMapper.serializePatch(obj)
      const response = await api.patchDocumentItem(String(obj.id), params)
      return DocumentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async delete (id) {
    try {
      const response = await api.deleteDocumentItem(String(id))
      return response.data
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async progress (obj: Document) {
    try {
      let response
      switch (obj.status) {
        case 'draft' :
          response = await api.postMovingToCollaborationDocumentItem(String(obj.id), {})
          break
        case 'collaboration-moving':
          response = await api.postInCollaborationDocumentItem(String(obj.id), {})
          break
        case 'collaboration-in-progress':
          response = await api.postMovingToApprovalDocumentItem(String(obj.id), {})
          break
        case 'approval-moving':
          response = await api.postInApprovalDocumentItem(String(obj.id), {})
          break
        default:
          throw Error('Document status not provided')
      }
      return DocumentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async approve (obj: Document) {
    try {
      const response = await api.postDocumentApproveDocumentItem(String(obj.id), {})
      return DocumentMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async decisionCreate (obj: Document, title: string) {
    try {
      await api.postAgendaDecisionDocumentItem(String(obj.id), {
        title
      })
      return this.findById(obj.id)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async decisionGenerate (obj: Document) {
    try {
      await api.postGenerateAgendaDecisionDocumentItem(String(obj.id), {})
      return this.findById(obj.id)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async minuteComplete (obj: Document) {
    try {
      await api.postCompleteMinutesDocumentItem(String(obj.id), {})
      return this.findById(obj.id)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }

  async requestAccess (id) {
    try {
      const response = await api.postRequestAccessDocumentItem(String(id), {})
      return response
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new DocumentRepository()
