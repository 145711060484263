

























import { computed, defineComponent, onMounted } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import DocumentTemplateGroup from '@/components/DocumentTemplateGroup.vue'
import documentTemplateRepository from '@/domain/documentTemplateRepository'

export default defineComponent({
  components: {
    LayoutContentSidebar,
    DocumentTemplateGroup
  },
  setup (_, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const templatesGrouped = computed(() => store.getters['documentTemplates/getTemplatesByTypeOrdered'])
    onMounted(() => {
      documentTemplateRepository.findByCriteria({}).then(x => {
        store.commit('documentTemplates/setTemplates', x)
      })
    })
    return {
      templatesGrouped
    }
  }
})
