





































































import { defineComponent, ref } from '@vue/composition-api'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import DialogConfirm from '@/components/DialogConfirm.vue'
import { BaseIcon } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import repositoryDocument from '@/domain/documentRepository'

export default defineComponent({
  props: {
    id: Number,
    canDelete: Boolean
  },
  components: {
    DialogConfirm,
    BaseIcon
  },
  setup (_, { root, emit }) {
    const store = root.$store
    const showSnackbar = ref(false)
    const snackbarStatus = ref<{ text: string, error?: boolean } | null>(null)

    const deleteAction = async (id) => {
      await repositoryDocument.delete(id)
      store.commit('documents/removeDocumentFromCache', id)
    }
    const requestAccessAction = async (id) => {
      showSnackbar.value = true
      const response = await repositoryDocument.requestAccess(id)
      if (response.status === 200) {
        snackbarStatus.value = { text: 'Access Granted' }
      } else {
        snackbarStatus.value = { text: 'Access Denied', error: true }
      }
      setTimeout(() => {
        snackbarStatus.value = null
      }, 5000)
    }

    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: (context, event: any) => deleteAction(event.value)
      },
      actions: {
        onDoneEnter: () => {
          root.$router.push({ name: 'DocumentsList' })
        }
      }
    })
    const { state: requestAccessState, send: requestAccessSend } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: (context, event: any) => requestAccessAction(event.value)
      },
      actions: {
        onDoneEnter: () => {
          if (snackbarStatus.value && !snackbarStatus.value.error) {
            emit('incrementIframeKey')
          }
        }
      }
    })

    return {
      showSnackbar,
      snackbarStatus,
      state,
      send,
      requestAccessState,
      requestAccessSend
    }
  }
})
