import { Machine, assign, interpret, Interpreter, State } from 'xstate'
import store from '@/store'
import repositoryDocument from '@/domain/documentRepository'
import { useService } from 'xstate-vue2'

export const homeMachine = Machine<any>({
  id: 'search',
  initial: 'idle',
  context: {
    resultsTotal: 0,
    pageCount: 1,
    page: 1,
    itemsPerPage: 5,
    requiresMyInput: undefined,
    status: 'current',
    sort: 'dateCreated',
    order: true
  },
  states: {
    idle: {
      on: {
        CHANGE_PAGE: {
          target: 'loading',
          actions: ['setPage']
        },
        CHANGE_STATUS: {
          target: 'loading',
          actions: ['setStatus', 'resetPage']
        },
        CHANGE_OPTIONS: {
          target: 'loading',
          actions: ['setOptions']
        }
      }
    },
    loading: {
      invoke: {
        src: 'load',
        onDone: {
          target: 'idle',
          actions: ['setResults']
        },
        onError: {
          target: 'idle',
          actions: ['setErrors']
        }
      }
    }
  }
}, {
  services: {
    load: async (context: any) => {
      const orderTypes = { status: undefined, title: undefined, documentType: undefined, number: undefined, dateCreated: undefined }

      if (context.sort && context.order !== undefined) {
        orderTypes[context.sort] = context.order ? 'desc' : 'asc'
      }

      let status2: (string)[]|undefined
      if (context.status === 'archived') {
        status2 = ['archived', 'legacy-completed']
      } else if (context.status === 'current') {
        status2 = ['draft', 'collaboration-moving', 'collaboration-in-progress', 'approval-moving', 'approval-in-progress', 'approved']
      }

      const response = await repositoryDocument.search({
        page: context.page,
        itemsPerPage: context.itemsPerPage,
        requiresMyInput: context.requiresMyInput,
        orderStatus: orderTypes.status,
        orderTitle: orderTypes.title,
        orderDocumentType: orderTypes.documentType,
        orderNumber: orderTypes.number,
        orderDateCreated: orderTypes.dateCreated,
        status2: status2
      })

      store.commit('documents/addDocumentsToCache', response.results)
      store.commit('documents/setDocumentIdsForDashboard', response.results)

      return response
    }
  },
  actions: {
    log: (context, event) => {
      console.log(context, event)
    },
    setResults: assign({
      resultsTotal: (context, event: any) => event.data.resultsTotal,
      pageCount: (context, event: any) => event.data.pageCount
    }),
    setPage: assign({
      page: (context, event: any) => event.payload
    }),
    setStatus: assign({
      requiresMyInput: (context, event: any) => event.payload === 'requiresMyInput' ? true : undefined,
      status: (context, event: any) => event.payload
    }),
    setOptions: assign({
      sort: (context, event: any) => event.payload.sortBy,
      order: (context, event: any) => event.payload.sortDesc
    }),
    resetPage: assign({
      page: (context, event: any) => 1
    })
  }
})

// Create a service using the machine.
const service = interpret(homeMachine)
// Create a custom service hook for this machine, that takes the service previously created.
// This way you don't have to import on every component the useService hook and the service created.
// Need to use useService instead of useActor.
export const useHomeMachine = () => {
  return useService(service.start())
}
