














import { defineComponent } from '@vue/composition-api'
import { BasePagination } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'

export default defineComponent({
  props: {
    state: Object,
    send: Function,
    hidePageJump: {
      type: Boolean,
      default: false
    }
  },
  components: {
    BasePagination
  }
})
