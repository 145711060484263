




























































import { defineComponent, inject, ref, computed } from '@vue/composition-api'
import LayoutContentSidebar from '@/router/layouts/LayoutContentSidebar.vue'
import DocumentActionMenu from '@/components/DocumentActionMenu.vue'
import ButtonExternal from '@/components/ButtonExternal.vue'
import { useUser } from '@/composables/useUser'
import repositoryDocument from '@/domain/documentRepository'
import { sidebarMachineSendKey, sidebarMachineStateKey } from '@/router/views/AppOutlet.vue'

export default defineComponent({
  props: ['id', 'tab'],
  components: {
    LayoutContentSidebar,
    DocumentActionMenu,
    ButtonExternal
  },
  setup (props, { root }) {
    // TODO: migrate to `const store = useStore()`
    const store = root.$store
    const sendSidebar = inject(sidebarMachineSendKey)
    const state = inject(sidebarMachineStateKey)

    const iframeKey = ref(0)
    const sharepointId = ref<string>()
    const document = computed(() => store.getters['documents/getById'](props.id))
    const { me } = useUser()
    const error = ref<string>()
    const sharepointUrl = process.env.VUE_APP_SHAREPOINT_URL
    repositoryDocument.findById(props.id).then(document => {
      store.commit('documents/addDocumentToCache', document)
      store.commit('documents/setDocumentIdForAddin', document)
      if (sendSidebar) {
        const tab = props.tab ? props.tab : 'DOCUMENT_VIEW'
        sendSidebar({ type: tab, payload: { document } })
      }
    }).catch(e => {
      error.value = e
    })

    const canEdit = computed(() => {
      let result = false
      let sharedUserIds: any[] = []
      if (document.value.documentSharedUsers && document.value.documentSharedUsers.length) {
        sharedUserIds = document.value.documentSharedUsers.reduce((acc, val) => {
          if (val.accessLevel === 'write') {
            acc.push(val.user.id)
          }
          return acc
        }, sharedUserIds)
      }
      if (me.value && document.value && ((document.value.documentOwner && me.value.id === document.value.documentOwner.id) || (document.value.documentManager && me.value.id === document.value.documentManager.id) || sharedUserIds.includes(me.value.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    const canDelete = computed(() => {
      let result = false
      const isDocOwner = document.value.documentOwner && me.value.id === document.value.documentOwner.id
      const isDocManager = document.value.documentManager && me.value.id === document.value.documentManager.id
      const isMovingToCollaboration = document.value.status === 'collaboration-moving'
      const isDraft = document.value.status === 'draft'
      const isInDeleteStatus = isDraft || isMovingToCollaboration

      if (me.value && document.value && (me.value.isAdmin || (isDocOwner && isInDeleteStatus) || (isDocManager && isMovingToCollaboration))) {
        result = true
      }
      return result
    })

    const incrementIframeKey = () => {
      iframeKey.value += 1
    }

    const documentHasAction = computed(() => {
      const userOnDocument = userIsCollaborator.value || userIsApprover.value
      const validDocumentStatus = document.value.status !== 'approved' && document.value.status !== 'archived' && document.value.status !== 'legacy-completed'
      return (userOnDocument || (allowedUser && document.value.status !== 'approval-in-progress')) && validDocumentStatus
    })

    const documentSidebarOpen = computed(() => {
      // Check that the current sidebar state is document
      return state?.value.matches('document')
    })

    // Check if current user is a collaborator
    const userIsCollaborator = computed(() => {
      let result = false
      const required = document.value.collaboration ? document.value.collaboration.workflowStepsRequired.find(c => c.user.id === me.value.id) : false
      const additional = document.value.collaboration ? document.value.collaboration.workflowStepsAdditional.find(c => c.user.id === me.value.id) : false
      if (required || additional) {
        result = true
      }
      return result
    })

    // Check if current user is an approver
    const userIsApprover = computed(() => {
      let result = false
      const required = document.value.approval ? document.value.approval.workflowStepsRequired.find(c => c.user.id === me.value.id) : false
      const additional = document.value.approval ? document.value.approval.workflowStepsAdditional.find(c => c.user.id === me.value.id) : false
      if (required || additional) {
        result = true
      }
      return result
    })

    const allowedUser = computed(() => {
      let result = false
      if (me.value && document.value && ((document.value.documentManager && me.value.id === document.value.documentManager.id) || (document.value.documentOwner && me.value.id === document.value.documentOwner.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    return {
      error,
      me,
      document,
      iframeKey,
      sharepointId,
      sharepointUrl,
      canEdit,
      canDelete,
      incrementIframeKey,
      documentSidebarOpen,
      documentHasAction
    }
  }
})
