import { KnowledgeBaseApi } from '@zucommunications/gsk-docshare-web-api'
import axios from '@/utils/axios'
import { IFindData } from '@/domain/interfaces'
import { HelpResourceMapper } from '@/domain/helpResourceMapper'
import { HelpResource } from '@/domain/helpResource'
import RepositoryError from '@/domain/repositoryError'

const BASE_PATH = window.Cypress ? '' : process.env.VUE_APP_SYMFONY_API_URL
const api = new KnowledgeBaseApi(undefined, BASE_PATH, axios)

class HelpResourceRepository implements IFindData<HelpResource> {
  async findById (question: string) {
    try {
      const response = await api.getKnowledgeBaseItem(question)
      return HelpResourceMapper.deserialize(response.data)
    } catch (err) {
      throw new RepositoryError(err)
    }
  }
}

export default new HelpResourceRepository()
