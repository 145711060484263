




























































import { defineComponent, reactive } from '@vue/composition-api'
import { BaseIcon, BaseInputSelect } from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import { useAgendas } from '@/composables/useAgendas'
import { useUser } from '@/composables/useUser'
import { useMachine } from 'xstate-vue2'
import { promiseMachine } from '@/machines/promiseMachine'
import { Agenda } from '@/domain/agenda'
import DialogConfirm from '@/components/DialogConfirm.vue'
import repository from '@/domain/agendaRepository'

export default defineComponent({
  props: ['id'],
  components: {
    BaseIcon,
    BaseInputSelect,
    DialogConfirm
  },
  setup (props, { root }) {
    const { findForDetail, agendaForDetail } = useAgendas()
    findForDetail(props.id)
    const agenda = reactive<Agenda>({
      id: props.id,
      usersForPreview: []
    })
    const { me, selectText: itemText } = useUser()
    const { state, send } = useMachine(promiseMachine, {
      context: {
        needsConfirmation: true
      },
      services: {
        invoke: () => repository.preview({
          obj: JSON.parse(JSON.stringify(agenda)),
          forceRebuild: false
        })
      },
      actions: {
        onDoneEnter: () => {
          findForDetail(props.id)
        }
      }
    })
    return {
      state,
      send,
      agendaForDetail,
      itemText,
      agenda,
      me
    }
  }
})
