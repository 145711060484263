








import { defineComponent, computed } from '@vue/composition-api'
import { useUser } from '@/composables/useUser'

export default defineComponent({
  name: 'chip-user',
  props: {
    user: {
      type: Object,
      required: true
    },
    approvalDate: {
      type: String,
      required: false
    }
  },
  setup (props) {
    const primaryGroup = computed(() => {
      if (props.user.groupMembership && props.user.groupMembership.length) {
        return props.user.groupMembership[0].group.formattedTitle
      }
      return 'Unknown'
    })

    // As this content is in the sidebar, there is the potential for it to be too long with the date.
    // Adding the title allows the full content to be viewed on hover.
    const title = computed(() => {
      let formattedTitle = selectText(props.user)
      if (props.user.groupMembership && props.user.groupMembership.length) {
        formattedTitle = formattedTitle + ', ' + primaryGroup.value
      }

      if (props.approvalDate) {
        formattedTitle = formattedTitle + ' - ' + props.approvalDate
      }

      return formattedTitle
    })

    const { selectText } = useUser()

    return { primaryGroup, selectText, title }
  }
})
