






































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import GroupHeading from '@/components/GroupHeading.vue'
import ChipBlock from '@/components/ChipBlock.vue'
import ChipUser from '@/components/ChipUser.vue'
import DocumentStatus from '@/components/DocumentStatus.vue'
import DocumentDeadline from '@/components/DocumentDeadline.vue'
import DocumentOwner from '@/components/DocumentOwner.vue'
import DocumentCommentBlock from '@/components/DocumentCommentBlock.vue'
import DocumentStatusChip from '@/components/DocumentStatusChip.vue'
import DocumentLeadGroup from '@/components/DocumentLeadGroup.vue'
import DocumentJointGroupsView from '@/components/DocumentJointGroupsView.vue'
import { minLength } from '@/validations'
import {
  BaseInputText,
  BaseIcon,
  BaseIconText,
  BaseAlert,
  BaseButton
} from '@zucommunications/gsk-docshare-web-design-system/src/index.vue'
import ChipBoolean from '@/components/ChipBoolean.vue'
import { useUser } from '@/composables/useUser'
import DocumentTitle from '@/components/DocumentTitle.vue'
import BaseCombobox from '@/components/forms/BaseCombobox.vue'
import repository from '@/domain/userRepository'

export default defineComponent({
  props: {
    document: Object,
    state: Object,
    send: Function,
    tab: {
      type: Number,
      required: true
    }
  },
  components: {
    GroupHeading,
    DocumentCommentBlock,
    DocumentStatusChip,
    BaseIcon,
    DocumentOwner,
    DocumentStatus,
    DocumentDeadline,
    BaseIconText,
    DocumentLeadGroup,
    DocumentJointGroupsView,
    ChipBlock,
    ChipUser,
    BaseAlert,
    ChipBoolean,
    BaseInputText,
    DocumentTitle,
    BaseCombobox,
    BaseButton
  },
  setup (props, { emit }) {
    // Check if user can progress document
    const { me } = useUser()

    const allowedUser = computed(() => {
      let result = false
      if (me.value && props.document && ((props.document.documentManager && me.value.id === props.document.documentManager.id) || (props.document.documentOwner && me.value.id === props.document.documentOwner.id) || me.value.isAdmin)) {
        result = true
      }
      return result
    })

    const collaboratorAdditionalSelected = ref(null)
    const collaboratorRequiredIds = computed(() => props?.state?.context?.workflowStepsRequired.map(x => x.user.id))
    const collaboratorAdditionalIds = computed(() => props?.state?.context?.workflowStepsAdditional.map(x => x.user.id))
    const searchPromise = async c => {
      if (c !== null) {
        let results = await repository.findByCriteria({ c })
        // Filter required collaborators
        results = results.filter(x => !collaboratorRequiredIds.value.includes(x.id))
        // Filter additional collaborators
        results = results.filter(x => !collaboratorAdditionalIds.value.includes(x.id))

        return results
      }

      return null
    }

    const { selectText } = useUser()
    const itemText = x => selectText(x)

    const collaboratorAdditionalAppendWorkflowStep = () => {
      const workflowStep = {
        user: collaboratorAdditionalSelected.value,
        approved: false
      }
      emit('workflowStepAdditionalAdd', workflowStep)
      collaboratorAdditionalSelected.value = null
    }

    const tabContent = ref<any>(null)

    const focusOnContent = () => {
      if (tabContent.value) {
        setTimeout(() => tabContent.value.focus(), 100)
      }
    }

    // Watch for the details tab being selected
    watch(() => props.tab, () => {
      if (props.tab === 0) {
        focusOnContent()
      }
    })

    return {
      minLength,
      allowedUser,
      searchPromise,
      itemText,
      collaboratorAdditionalSelected,
      collaboratorAdditionalAppendWorkflowStep,
      tabContent
    }
  }
})
