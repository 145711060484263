






import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    show: Boolean,
    msg: String
  }
})
